import React, { useEffect, useState } from "react";
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { ServiceCodeService } from "../../_service";
import { useAppSelector } from "../../_app";
import { Utils } from "../../utils";
import { Heading } from "../appSettings/AppSettingsStyles";
import { CustomSelectField, Loader } from "../../components";
import moment from "moment";
import { LoaderContainer } from "../../CommonStyles";

const companies = [
  {
    name: "Company 1",
    id: 1,
  },
  {
    name: "Company 2",
    id: 2,
  },
  {
    name: "Company 3",
    id: 3,
  },
];

const GenerateGlobalInvoice = () => {
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const [isLoading, setIsLoading] = useState(false);

  const [eligibleCompanies, setEligibleCompanies] = useState<any[]>([]);

  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);

  const handleSelection = (e: any, companyId: any) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedCompanies([...selectedCompanies, companyId]);
    } else {
      setSelectedCompanies(selectedCompanies.filter((id) => id !== companyId));
    }
  };

  const date = new Date();

  const currentPayrunDate = useAppSelector(
    (state) => state?.user?.current_payrun_date
  );
  const [month, setMonth] = useState<string>(`${date.getMonth() + 1}`);
  const [year, setYear] = useState<string>(
    `${currentPayrunDate?.split("-")[0]}`
  );

  const yearOptions = Utils.getDefaultYearRange(
    parseInt(currentPayrunDate?.split("-")[0])
  );

  const monthsOptions = Utils.MONTH_OPTIONS;
  const fromDate = moment([year, parseInt(month) - 1])
    .startOf("month")
    .format("YYYY-MM-DD");

  console.log("selected", selectedCompanies, fromDate);

  const getInvoiceListing = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._getInvoicingCompanies(
        fromDate
      );
      if (status >= 200 && status < 300) {
        setEligibleCompanies(data?.data?.eligible);
      }
    } catch (error: any) {
      Utils.showErrorMessage(error);
    }
    setIsLoading(() => false);
  };

  console.log("eligibal", eligibleCompanies);

  useEffect(() => {
    getInvoiceListing();
  }, [fromDate]);

  console.log("months and year", month, year);

  return (
    <div style={{ width: "100%" }}>
      <Heading style={{ paddingLeft: "1rem" }}>
        {"Generate Global Invoice"}
      </Heading>
      <hr
        style={{
          margin: "20px 0",
          border: "none",
          borderTop: "1px solid #000",
        }}
      />

      {/* Year and Month selection */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "1rem",
          paddingLeft: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "1rem",
          }}
        >
          <p
            style={{ color: "#333", marginRight: "1rem", whiteSpace: "nowrap" }}
          >
            {"Select Year: "}
          </p>
          <CustomSelectField
            value={year}
            onChangeOption={(value) => setYear(value)}
            option={yearOptions.map((year) => ({
              key: year,
              value: year,
            }))}
            disabled={isLoading}
            isDate={true}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "1rem",
          }}
        >
          <p style={{ color: "#333", marginRight: "1rem" }}>{"From: "}</p>
          <CustomSelectField
            value={month}
            onChangeOption={(value) => setMonth(value)}
            option={monthsOptions.map((month) => ({
              key: month.key,
              value: month.value,
            }))}
            disabled={isLoading}
            isDate={true}
          />
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p style={{ color: "#333", marginRight: "1rem" }}>{"To: "}</p>
          <CustomSelectField
            value={toMonth}
            onChangeOption={(value) => setToMonth(value)}
            option={monthsOptions.map((month) => ({
              key: month.key,
              value: month.value,
            }))}
            disabled={isLoading}
            isDate={true}
          />
        </div> */}
      </div>

      <Box sx={{ width: "50%", marginTop: 1, padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: "1rem",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "rgb(71, 101, 117)", fontWeight: 600 }}
          >
            Eligible Companies
          </Typography>
          <Typography
            sx={{
              color: "#0000EE",
              cursor: "pointer",
              borderBottom: "1px solid transparent",
              "&:hover": {
                borderBottom: "1px solid #0000EE",
              },
              fontWeight: 400,
              marginLeft: "3rem",
              padding: "0 0.25rem",
              fontSize: "1rem",
            }}
            onClick={() =>
              setSelectedCompanies(
                eligibleCompanies.map((company) => company.id)
              )
            }
          >
            All
          </Typography>
          <Typography
            sx={{
              color: "#0000EE",
              cursor: "pointer",
              borderBottom: "1px solid transparent",
              "&:hover": {
                borderBottom: "1px solid #0000EE",
              },
              fontWeight: 400,
              padding: "0 0.25rem",
              fontSize: "1rem",
            }}
            onClick={() => setSelectedCompanies([])}
          >
            None
          </Typography>
        </div>

        {isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <FormGroup sx={{ mt: 2 }}>
            {eligibleCompanies?.length > 0 ? (
              eligibleCompanies.map((company) => (
                <FormControlLabel
                  key={company.id}
                  control={
                    <Checkbox
                      onChange={(e: any) => handleSelection(e, company.id)}
                      sx={{
                        "&.Mui-checked": {
                          color: "#1976d2",
                        },
                        "&:hover": {
                          backgroundColor: "rgba(25, 118, 210, 0.04)",
                        },
                      }}
                      checked={selectedCompanies.includes(company.id)}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: "0.875rem",
                        color: "#333",
                        //   "&:hover": { color: "#1976d2" },
                      }}
                    >
                      {company.name}
                    </Typography>
                  }
                  sx={{
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                />
              ))
            ) : (
              <Typography sx={{ ml: 2, color: "#666" }}>
                No eligible companies found
              </Typography>
            )}
          </FormGroup>
        )}
      </Box>
    </div>
  );
};

export default GenerateGlobalInvoice;
