import React from "react";
import styled from "styled-components";
import Require from "./Require";
import { Container, Error, Label } from "./Styles";
import CustomTooltip from "../CustomTooltip";

interface SelectFieldProps {
  title?: string;
  defaultValue?: string;
  value?: string | number | undefined;
  empty?: boolean;
  require?: boolean;
  placeholder?: any;
  option: any;
  onChangeOption?: (e: string) => void;
  note?: string;
  error?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  width?: string;
  disabled?: boolean;
  margin?: any;
  isDate?: boolean;
  isColumn?: boolean;
  inputWidth?: string;
  hasTooltip?: boolean;
  tooltipText?: string | JSX.Element;
  placement?: any;
}
const SelectField = ({
  defaultValue = "",
  option = [],
  title,
  value,
  empty = false,
  require = false,
  disabled = false,
  placeholder,
  onChangeOption,
  note = "",
  width = "100%",
  error,
  onBlur,
  onFocus,
  margin = "0.8rem",
  isDate = false,
  isColumn = false,
  inputWidth = "",
  hasTooltip = false,
  tooltipText = "",
  placement = "",
}: SelectFieldProps) => {
  return (
    <Container width={width} margin={margin} isColumn={isColumn}>
      {title && (
        <Label isColumn={isColumn}>
          {title || ""}
          {require && <Require />}
        </Label>
      )}
      <div style={{ width: title ? inputWidth || "60%" : "100%", display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <Select
          disabled={disabled}
          defaultValue={defaultValue}
          value={value || defaultValue}
          onChange={(e) => onChangeOption && onChangeOption(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          style={{ width: "fit-content",  }}
        >
          {empty && <option key={""} value={""}></option>}
          {placeholder && (
            <option key={""} value={""} disabled>
              {placeholder}
            </option>
          )}
          {/* placeholder */}
          {option?.map((item: any) => (
            <option key={item.value} value={item.value}>
              {item.key}
            </option>
          ))}
        </Select>
        {hasTooltip && (
          <CustomTooltip title={tooltipText || ""} placement={placement}>
            {/* <img src="../../assets/info.svg" /> */}
            <img src="/info-outline.svg" />
          </CustomTooltip>
        )}
        {error ? (
          <Error>{error || ""}</Error>
        ) : (
          note && <Error color="grey">{note}</Error>
        )}
      </div>
    </Container>
  );
};

export default SelectField;

const Select = styled.select`
  width: 100%;
  border-radius: 0.2rem;
`;
