import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const TableColumnHeading = styled.th`
  text-align: left;
  width: 125px;
  padding: 10px 0;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
`;

const ActionColumn = styled.td`
  width: 150px;
`;

const TableColumn = styled.td`
  text-align: left;
  padding: 0.3rem;
  max-width: 400px;
`;

const ActionFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const CopyText = styled.span`
  cursor: pointer;
  color: #059bbf;
  &:hover {
    text-decoration: underline;
  }
`;

const ActionButton = styled.div<{
  backgroundColor?: string;
  textColor?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 6px 12px;
  border: 1px solid;
  background-color: ${(props) => props.disabled ? "#808080" : props.backgroundColor || "#059bbf"};
  border-color: ${(props) => props.disabled ? "#808080" : props.backgroundColor || "#059bbf"};
  color: ${(props) => props.textColor || "#fff"};
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.3s;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  &:hover {
    background-color: ${(props) => (props.disabled ? "#808080" : "#fff")};
    color: ${(props) => (props.disabled ? props.textColor || "#fff" : props.backgroundColor || "#059bbf")};
  }
  min-width: 150px;
  font-weight: 500;
`;


const PulsingIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
    }
    70% {
      box-shadow: 0 0 0 8px rgba(0, 123, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
    }
  }
  
  animation: pulse 2s infinite;

  img {
    width: 24px;
    height: 24px;
  }
`;

export {
  InputContainer,
  TableColumnHeading,
  TableColumn,
  ActionColumn,
  ActionFlex,
  CopyText,
  ActionButton,
  PulsingIcon
};
