import { useEffect, useState } from "react";
import { AuthHeader } from "../../components/header";
import { Button, Message, TextInput } from "../../components";
import { Link, PasswordContainer, PasswordHeader } from "./AuthStyles";
import { useAppDispatch, useAppSelector } from "../../_app";
import { updateMessage } from "../../_features";
import { AuthService } from "../../_service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Constant } from "../../config";
import { Utils } from "../../utils";

const EmailVerifyScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const uid = queryParams.get("uid");

  const [password, setpassword] = useState("");
  const [conformPassword, setconformPassword] = useState("");
  const messageData = useAppSelector((state) => state.message.verifyPassword);

  const resetMessages = () => {
    dispatch(
      updateMessage({
        message: "",
        page: "verifyPassword",
        type: "error",
      })
    );
  };

  const handleCreateNewPassword = async () => {
    try {
      if (password === "") {
        dispatch(
          updateMessage({
            message: "Password can not be empty",
            page: "verifyPassword",
            type: "error",
          })
        );
        return;
      }
      if (password !== conformPassword) {
        dispatch(
          updateMessage({
            message: "Confirm Password does not match",
            page: "verifyPassword",
            type: "error",
          })
        );
        return;
      }
      const dataToSend = {
        token,
        uid,
        password,
      };
      await AuthService._setPassword(dataToSend);
      navigate("/");
      dispatch(
        updateMessage({
          message: "Password update successfully",
          page: "login",
          type: "success",
        })
      );
    } catch (e: any) {
      dispatch(
        updateMessage({
          message:
            e?.response?.data?.password[0] ||
            Constant.common.something_went_wrong,
          page: "verifyPassword",
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    Utils.resetStoreData();
  }, []);

  return (
    <div>
      {/* {sending && <Loader />} */}
      <AuthHeader />
      <PasswordContainer
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
        }}
      >
        <div
          style={{
            width: "500px",
            borderRadius: ".3rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
          }}
        >
          <PasswordHeader>
            {Constant.createPassword.create_new_password}
          </PasswordHeader>
          <div
            style={{
              padding: "1rem",
              border: "1px solid #dee2e6",
              position: "relative",
            }}
          >
            <Message close={resetMessages} data={messageData} />
            <p>{Constant.createPassword.enter_your_password}</p>
            <TextInput
              labal={Constant.createPassword.new_password}
              type="password"
              placeholder={Constant.createPassword.enter_new_password}
              value={password}
              onChange={setpassword}
              style={{ textTransform: "none" }}
            />
            <TextInput
              labal={Constant.createPassword.conform_your_new_password}
              type="password"
              placeholder={
                Constant.createPassword.enter_new_password_again_to_confirm
              }
              value={conformPassword}
              onChange={setconformPassword}
              style={{ textTransform: "none" }}
            />

            <Button
              title={Constant.createPassword.get_access_now}
              onClick={handleCreateNewPassword}
            />
          </div>
          <div
            style={{
              padding: "2rem 0 1rem 0",
              border: "1px solid #dee2e6",
              position: "relative",
            }}
          >
            <Link
              to="https://tellboss.co/"
              style={{
                textAlign: "center",
                width: "100%",
                display: "block",
              }}
            >
              {Constant.common.back_to_home_page}
            </Link>
          </div>
        </div>
      </PasswordContainer>
    </div>
  );
};

export default EmailVerifyScreen;
