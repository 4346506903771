import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../_app";
import { CPFService } from "../../../_service/CPFService";
import {
  CommonModal,
  CustomButton,
  CustomSelectField,
  Loader,
  Tooltip,
} from "../../../components";
import { Constant } from "../../../config";
import { Notify, Utils } from "../../../utils";
import { DisplayFlex, TableRow } from "../../payslip/PayslipStyles";
import { IRASPdfContainer, IRASTable } from "../CPFFilingStyles";
import { TableHeader } from "../../../components/table";
import { PulsingIcon, TableColumn } from "../../users/styles";
import ImportFileModal from "./ImportFileModal";
import { LoaderContainer } from "../../../CommonStyles";
import { Heading } from "../../appSettings/AppSettingsStyles";
import { FaCheck, FaFileImport, FaPlus } from "react-icons/fa";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { FaRegFilePdf } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PdfIcon from "../../../assets/pdf-icon.png";
import CustomTooltip from "../../../components/CustomTooltip";
import styled from "styled-components";

const ManualFiling = () => {
  const date = new Date();
  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );
  const [month, setMonth] = useState<string>("1");
  const [year, setYear] = useState<string>(`${currentPayrunDate.split('-')[0]}`);
  const [manualFilingData, setManualFilingData] = useState<any>([]);
  const [isShowImportModal, setIsShowImportModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteFiling, setIsDeleteFiling] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedManualArr, setSelectedManualArr] = useState<any>([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const fileInputRef = useRef();

  const handleFileChange = () => {
    const file = (fileInputRef.current as any).files[0];
    if (file) {
      setCsvFile(() => file);
    }
  };

  const handleSelectAll = () => {
    if (isSelectAll) {
      setIsSelectAll(() => false);
      setSelectedManualArr(() => []);
      setSelectedCount(() => 0);
    } else {
      setSelectedCount(() => manualFilingData?.length);
      setIsSelectAll(() => true);
      const updatedFilingArr = manualFilingData.map(
        (manualFiling: any) => manualFiling.id
      );
      setSelectedManualArr(() => updatedFilingArr);
    }
  };

  const [csvFile, setCsvFile] = useState<any>(null);
  const navigate = useNavigate();

  const monthsOptions = Utils.MONTH_OPTIONS;
  const tableHeader = [
    {
      width: "4%",
    },
    {
      name: "Name",
      width: "10%",
    },
    {
      name: "Month",
      width: "8%",
    },
    {
      name: "Salary (OW + AW)",
      width: "8%",
    },
    {
      name: "Transport",
      width: "5%",
    },
    {
      name: "Entertainment",
      width: "5%",
    },
    {
      name: "Others",
      width: "5%",
    },
    {
      name: "CPF",
      width: "5%",
    },
    {
      name: "Reimburse / Deduction",
      width: "5%",
    },
    {
      name: "Net Pay",
      width: "5%",
    },
    {
      name: "Status",
      width: "8%",
    },
    {
      name: "Actions",
      width: "10%",
    },
  ];

  const user = useAppSelector((state) => state.user);

  const exportFilingCsv = async () => {
    try {
      const { status, data } = await CPFService._exportManualFilingService(
        user.defaultCompnayId
      );

      if (status === 200) {
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        // Create a download link and trigger the download.
        const a = document.createElement("a");
        a.href = url;
        a.download = `${user?.defaultCompany.split(" ")[0] || ""
          }-IR8A Manual Filing.csv`;
        document.body.appendChild(a);
        a.click();

        // Clean up by revoking the object URL to release resources.
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const getManualFiling = async () => {
    setIsLoading(() => true);
    const paramsData = {
      companyId: user.defaultCompnayId,
      month: month?.length === 1 ? `0${month}` : month,
      year: year,
    };
    try {
      const { status, data } = await CPFService._getManualFilingData(
        paramsData
      );

      if (status === 200) {
        setManualFilingData(() => data.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const handleImportCsv = async () => {
    if (!csvFile) {
      Notify("Please Add a file", 0);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("payslip_csv", csvFile);

      const { status } = await CPFService._importManualFilingService(formData);

      if (status >= 200 && status < 300) {
        Notify("File Added Successfully", 1);
        getManualFiling();
      }
    } catch (error: any) {
      if (error.response?.data?.file?.length > 0) {
        Notify(error.response.data.file[0], 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setCsvFile(() => null);
    (fileInputRef.current as any).value = "";
    setIsShowImportModal(() => false);
  };

  const handleSelectChange = (manualId: number) => {
    const isCurrentItemSelected = selectedManualArr.includes(manualId);
    if (isCurrentItemSelected) {
      setSelectedCount(() => selectedCount - 1);
      const updatedFiling = selectedManualArr.filter(
        (filings: any) => filings !== manualId
      );
      setSelectedManualArr(() => updatedFiling);
    } else {
      setSelectedCount(() => selectedCount + 1);
      setSelectedManualArr(() => [...selectedManualArr, manualId]);
    }
  };

  const handleDelteIR8A = async () => {
    try {
      const { status } = await CPFService._deleteFiling(deleteId);

      if (status === 204) {
        Notify("HR Template Deleted Successfully", 1);
        setManualFilingData((prevFilingData: any) =>
          prevFilingData.filter((filingData: any) => filingData.id !== deleteId)
        );
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsDeleteFiling(() => false);
    setDeleteId(() => "");
  };

  const bulkApprove = async () => {
    const dataToApprove = {
      is_approved: true,
      manual_payslip_ids: selectedManualArr,
    };
    try {
      const { status } = await CPFService._bulkApproveIras(dataToApprove);
      if (status >= 200 && status < 300) {
        Notify("Payslips approved successfully", 1);
        getManualFiling();
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  useEffect(() => {
    getManualFiling();
  }, [month, year]);



  const getYearRange = () => {
    const startYear = moment().year() - 2;
    const yearsRange = [startYear];

    for (let i = 1; i < 9; i++) {
      yearsRange.push(startYear + i);
    }
    console.log("yearRange", yearsRange);
    return yearsRange;
  };

  const handlePDFDownload = async (employeeId: any, name: string) => {
    try {
      const response = await CPFService._getPdfIR8A(year, employeeId, user.defaultCompnayId);
      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}_IR8A.pdf`; // Set the desired filename for the downloaded file
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      // Notify(Constant.common.something_went_wrong, 0);
      Notify("No PDF Available", 0);
    }
  };

  const getCPF = (item: any) => {
    let cpf = parseFloat(item?.cpf_employee) + parseFloat(item?.ecf) + parseFloat(item?.mbmf) + parseFloat(item?.sinda) + parseFloat(item?.cdac);
    return cpf;
  }

  const PayrollTooltipText = () => <div style={{ margin: '0.5rem 0.25rem', borderRadius: '8px', padding: "1rem", background: '#F4F6FF', fontWeight: 600 }}>
    <p>{`The payroll amounts for ${moment(`${year}-${month}-01`).format("MMMM YYYY")} were done OUTSIDE SalaryPay.`}</p>
    <br />
    <p>{`When a new customer starts in ${moment(`${year}-${month}-01`).add(1, 'months').format("MMMM YYYY")} using our Payroll Service, the earlier month, in this case ${moment(`${year}-${month}-01`).format("MMMM YYYY")}, before we start the monthly payroll, is considered "Manual".`}</p>
    <br />
    <p>{`Result of the revised IR8A amounts are sum of "Total amounts" in SalaryPay PLUS "Total amounts" in manural IR8A records.`}</p>
  </div>




  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <div>
      <Heading>Manual IR8A Filing</Heading>
      <DisplayFlex>
        <div
          style={{
            display: "flex",
            width: "70%",
            gap: 20,
            alignItems: "center",
          }}
        >
          <p style={{ color: "#333" }}>Select Month and Year</p>
          <div>
            <CustomSelectField
              value={month}
              onChangeOption={(value) => {
                sessionStorage.setItem("manualMonth", value);
                setMonth(value);
              }}
              option={monthsOptions.map((month) => ({
                key: month.key,
                value: month.value,
              }))}
              isDate={true}
              margin="0rem"
            />
          </div>
          <div>
            <CustomSelectField
              value={year}
              onChangeOption={(value) => {
                sessionStorage.setItem("manualYear", value);
                setYear(value);
              }}
              // option={Utils.generateYearArrayTillNow(2022).map((year) => ({
              //   key: year,
              //   value: year,
              // }))}
              option={Utils.getDefaultYearRange(parseInt(currentPayrunDate.split('-')[0])).map((year) => ({
                key: year,
                value: year,
              }))}
              isDate={true}
              margin="0rem"
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', gap: '0.25rem' }}>

          <CustomTooltip title={<PayrollTooltipText />} placement={'bottom'}>
            <PulsingIcon>
              <img src="/info-outline.svg" style={{ width: '24px', height: '24px' }} />
            </PulsingIcon>
          </CustomTooltip>


          <CustomButton
            title="Add Monthly IR8A"
            // onClick={() => setIsShowImportModal(() => true)}
            onClick={() =>
              navigate("add", {
                state: {
                  // filing: manualFiling,
                },
              })
            }
            type="secondary"
            icon={<FaPlus />}
            styles={{
              background: "rgb(3, 179, 129)",
              color: "#ffffff",
              fontWeight: 600,
              borderColor: "#04AA6D"
            }}
          />
          <CustomButton
            title="Import"
            onClick={() => setIsShowImportModal(() => true)}
            type="secondary"
            icon={<FaFileImport />}
          />
        </div>
      </DisplayFlex>
      {manualFilingData?.length > 0 ? (
        <div
          style={{
            display: "flex",
            gap: 20,
            marginTop: 20,
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            name="selectAll"
            id="selectAll"
            onChange={() => handleSelectAll()}
            checked={isSelectAll}
          />
          <label htmlFor="selectAll">Select all</label>
        </div>
      ) : null}
      {selectedCount ? (
        <div
          style={{
            display: "flex",
            gap: 20,
            marginTop: 20,
            alignItems: "center",
          }}
        >
          <p>{selectedCount} records selected</p>
          <Tooltip text="Approve">
            <CustomButton
              type="secondary"
              size="small"
              width="30px"
              success
              title={""}
              icon={<FaCheck />}
              onClick={() => bulkApprove()}
            />
          </Tooltip>
        </div>
      ) : null}



      {/* <div style={{margin: '0.4rem 0.25rem', borderRadius:'6px', padding: ".4rem", color: "rgb(255, 102, 101)", border : "solid .0125rem rgb(255, 102, 101)", fontSize : "13px"}}>
   {`The payroll amounts for ${moment(`${year}-${month}-01`).format("MMMM YYYY")} were done OUTSIDE SalaryPay.`}    
    {`When a new customer starts in ${moment(`${year}-${month}-01`).add(1, 'months').format("MMMM YYYY")} using our Payroll Service, the earlier month, in this case ${moment(`${year}-${month}-01`).format("MMMM YYYY")}, before we start the monthly payroll, is considered "Manual".`}  
    {` Result of the revised IR8A amounts are sum of "Total amounts" in SalaryPay PLUS "Total amounts" in manural IR8A records.`}

    
  </div> */}

      <div style={{ margin: '0.4rem 0.25rem', borderRadius: '6px', padding: ".5rem", color: 'white', backgroundColor: "rgb(255, 102, 101)", border: "solid .0125rem rgb(255, 102, 101)", fontSize: "14px", fontWeight: 500 ,  textShadow: "1px 1px 2px #000000"}}>
        {
          `      You are required to provide all payroll months which were completed outside our SalaryPay software.

      This procedure is to prepare the annual IR8A based on the full twelve months of payroll for employees.
      
      For example, If the first payroll month prepared by SalaryPay is. April ${currentPayrunDate.split('-')[0]}.
    `}

        <p style={{ marginTop: ".5rem" }}>
          {`
      Then the sum of salaries from January to March ${currentPayrunDate.split('-')[0]} are to be imported or manually entered here.
      
      These figures are auto added to all the other payroll months prepared in SalaryPay to derive the annual employee's income for the year ${currentPayrunDate.split('-')[0]}.`
          }
        </p>
      </div>



      <div style={{ marginTop: 8 }}>
        <IRASTable>
          <TableHeader headerColumns={tableHeader} variant="filled" align={"left"} />
          <tbody>
            {manualFilingData.sort((a: any, b: any) => a.employee_name.localeCompare(b.employee_name)).map((manualFiling: any) => (
              <TableRow>
                <TableColumn>
                  <input
                    type="checkbox"
                    name="selectbox"
                    id="selectbox"
                    style={{ height: 13 }}
                    checked={selectedManualArr.includes(manualFiling.id)}
                    onChange={() => handleSelectChange(manualFiling.id)}
                  />
                </TableColumn>
                <TableColumn style={{ width: "15%" }}>{manualFiling?.employee_name || ""}</TableColumn>
                <TableColumn style={{ textAlign: "center", width: 'full', }}>
                  {Utils?._dateToShow(manualFiling?.payslip_date) || ""}
                </TableColumn>
                <TableColumn style={{ textAlign: "right", width: '5%', paddingRight: '0.5rem' }}>
                  {Utils?.getCommanSepartedNumericStringWithDollar(manualFiling?.ow + manualFiling?.bonus + manualFiling?.commission + manualFiling?.director_fees) || ""}
                </TableColumn>
                <TableColumn style={{ textAlign: "right", width: '5%', paddingRight: '0.5rem' }}>
                  {Utils?.getCommanSepartedNumericStringWithDollar(manualFiling?.transport) || ""}
                </TableColumn>
                <TableColumn style={{ textAlign: "right", width: '5%', paddingRight: '0.5rem' }}>
                  {Utils?.getCommanSepartedNumericStringWithDollar(manualFiling?.entertainment) || ""}
                </TableColumn>
                <TableColumn style={{ textAlign: "right", width: '5%', paddingRight: '0.5rem' }}>
                  {Utils?.getCommanSepartedNumericStringWithDollar(manualFiling?.other_allowance) || ""}
                </TableColumn>
                <TableColumn style={{ textAlign: "right", width: '5%', paddingRight: '0.5rem' }}>
                  {Utils?.getCommanSepartedNumericStringWithDollar(getCPF(manualFiling)) || ""}
                </TableColumn>
                <TableColumn style={{ textAlign: "right", width: '5%', paddingRight: '0.5rem' }}>
                  {Utils.getCommanSepartedNumericStringWithDollar(manualFiling?.total_reimburesement - manualFiling?.oth_deduction) || ""}
                </TableColumn>
                <TableColumn style={{ textAlign: "right", width: 'full', paddingRight: '0.5rem' }}>
                  {Utils?.getCommanSepartedNumericStringWithDollar(manualFiling?.net_pay) || ""}
                </TableColumn>
                <TableColumn
                  style={{
                    color: manualFiling?.is_approved ? "#28a745" : "#dc3545", textAlign: 'center',
                    width: 'full'
                  }}
                >
                  {manualFiling.is_approved ? "Approved" : "Not approved"}
                </TableColumn>
                <td style={{ padding: 10 }}>
                  <div style={{ display: "flex", gap: 15, justifyContent: 'center', width: 'full', }}>
                    {!manualFiling?.is_approved && <Tooltip text="Edit">
                      <CustomButton
                        type={"secondary"}
                        size="small"
                        width="30px"
                        title={""}
                        onClick={() =>
                          navigate("edit", {
                            state: {
                              filing: manualFiling,
                            },
                          })
                        }
                        icon={<AiFillEdit />}
                      />
                    </Tooltip>}
                    <Tooltip text="Delete" del>
                      <CustomButton
                        type={"secondary"}
                        size="small"
                        del
                        width="30px"
                        title={""}
                        onClick={() => {
                          setDeleteId(() => manualFiling.id);
                          setIsDeleteFiling(() => true);
                        }}
                        icon={<AiFillDelete />}
                      />
                    </Tooltip>
                    {/* PDF Download */}
                    {manualFiling?.is_approved && <Tooltip text="IR8A">
                      <CustomButton
                        type={"secondary"}
                        size="small"
                        del
                        width="30px"
                        title={""}
                        onClick={() => {
                          // setDeleteId(() => manualFiling.id);
                          // setIsDeleteFiling(() => true);
                          console.log("Download PDF")
                          handlePDFDownload(manualFiling?.employee, manualFiling?.employee_name)
                        }}
                        icon={<FaRegFilePdf />}
                      />
                    </Tooltip>}

                  </div>
                </td>
              </TableRow>
            ))}
          </tbody>
        </IRASTable>
        {manualFilingData?.length === 0 && <p style={{ marginTop: '2rem', textAlign: 'center', }}>No records found</p>}
      </div>
      <CommonModal
        handleClose={() => setIsShowImportModal(() => false)}
        open={isShowImportModal}
        updateData={handleImportCsv}
        body={
          <ImportFileModal
            file={csvFile}
            handleChange={handleFileChange}
            fileInputRef={fileInputRef}
            deleteFile={() => {
              setCsvFile(null);
              (fileInputRef.current as any).value = "";
            }}
            exportFile={exportFilingCsv}
          />
        }
        heading="Import IR8A"
        conformButtonText="Submit"
        cancelButtonText="Cancel"
      />
      <CommonModal
        handleClose={() => setIsDeleteFiling(() => false)}
        open={isDeleteFiling}
        updateData={handleDelteIR8A}
        body={"Are you sure you want to delete this entry?"}
        heading="Delete IR8A"
        conformButtonText="Delete"
        cancelButtonText="Cancel"
      />
    </div>
  );
};

export default ManualFiling;
