import apiClient from "./BaseService";
import { URL } from "../config";

const _getRequestTypes = async () => apiClient.get(`${URL.GET_REQUEST_TYPES}/`);

const _getSelectedRequestTypes = async () =>
  apiClient.get(`${URL.GET_REQUEST_TYPES}?mode=0`);

const _submitRequest = async (data: any) =>
  apiClient.post(URL.SUBMIT_REQUEST, data);

const _completeRequest = async (id: any, status: string) =>
  apiClient.patch(`${URL.COMPLETE_REQUEST}${id}&status=${status}`);

const _getSubmittedRequests = async (id: any, stage: string) =>
  apiClient.get(`${URL.REQUEST_LIST}${stage}&company=${id}`);

const _getCompletedRequests = async (id: any, status: string) =>
  apiClient.get(`${URL.COMPLETED_LIST}${status}&company=${id}`);

const _startRequest = async (id: any, stage: string) =>
  apiClient.get(`${URL.START_REQUEST}${id}&stage=${stage}`);

const _moveRequest = async (id: any, stage: string) =>
  apiClient.get(`${URL.MOVE_REQUEST}${id}&stage=${stage}`);

const _deleteRequest = async (id: any) =>
  apiClient.delete(`${URL.DELETE_REQUEST}${id}/`);

export const DashboardService = {
  _getRequestTypes,
  _submitRequest,
  _getSubmittedRequests,
  _getCompletedRequests,
  _getSelectedRequestTypes,
  _startRequest,
  _moveRequest,
  _completeRequest,
  _deleteRequest,
};
