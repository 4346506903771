import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import {
  CustomButton,
  DeactivateModal,
  Loader,
  Tooltip,
} from "../../../components";
import { TableHeader } from "../../../components/table";
import { Heading } from "../AppSettingsStyles";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableRow } from "../../payslip/PayslipStyles";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { useEffect, useState } from "react";
import { LoaderContainer } from "../../../CommonStyles";
import { useNavigate } from "react-router-dom";
import { ServiceCodeService } from "../../../_service";
import { useAppSelector } from "../../../_app";

const ManageServiceCode = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceCodes, setServiceCodes] = useState<any>([]);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [serviceCodeToDelete, setServiceCodeToDelete] = useState<any>();
console.log('serviceCodes',serviceCodes);

  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  const serviceCodeTableHeaders = [
    {
      name: "Service code",
      width: "15%",
    },
    {
      name: "Description",
      width: "48%",
    },
    {
      name: "Fixed amount",
      width: "15%",
      align : "right",
    },
    {
      name: "Amount per employee",
      width: "15%",
      align : "right",
    },
    {
      name: "Action",
    },
  ];

  const getServiceCodes = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._getServiceCodeList();

      if (status === 200) {
        setServiceCodes(() =>
          data.data.map((dataObj: any) => ({
            id: dataObj.id,
            name: dataObj.name,
            fixed_amount: dataObj.fixed_amount,
            variable_amount: dataObj.variable_amount,
            description : dataObj.description
          }))
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const deleteServiceCode = async () => {
    setIsLoading(() => true);
    try {
      const { status } = await ServiceCodeService._deleteServiceCode(
        serviceCodeToDelete
      );

      if (status === 204) {
        Notify("Service Code Deleted Successfully", 1);
        setServiceCodes((prevServiceCodes: any) =>
          prevServiceCodes.filter(
            (serviceCode: any) => serviceCode.id !== serviceCodeToDelete
          )
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
    setIsDeleteModalActive(() => false);
  };

  const  handleDeleteServiceCode = (id: any) => {
    setServiceCodeToDelete(() => id);
    setIsDeleteModalActive(() => true);
  };
  useEffect(() => {
    getServiceCodes();
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <Heading>Manage Service Codes</Heading>
            {user.permission === "full" && (
              <CustomButton
                title="Add Service Code"
                type="secondary"
                onClick={() => navigate("add")}
              />
            )}
          </div>
          <IRASTable>
            <TableHeader
              headerColumns={serviceCodeTableHeaders}
              variant="filled"
              styles={{ padding: "5px" }}
            />
            <tbody>
              {serviceCodes
                .sort((a: any, b: any) => a.id - b.id)
                .map((service: any, index: any) => (
                  <TableRow key={service.id}>
                    <td style={{ padding: "8px" }}>{(service.name).toUpperCase()}</td>
                    <td style={{ padding: "8px" }}>{service.description}</td>
                    <td style={{ padding: "8px",textAlign : "right", paddingRight : "8rem"  }}>{Utils.getCommanSepartedNumericStringWithDollar(service.fixed_amount)}</td>
                    <td style={{ padding: "8px",textAlign : "right", paddingRight : "4rem" }}>{Utils.getCommanSepartedNumericStringWithDollar(service.variable_amount)}</td>

                    {user.permission === "full" && (
                      <td>
                        <div style={{ display: "flex", gap: 20 }}>
                          <Tooltip text="Edit">
                            <CustomButton
                              type={"secondary"}
                              size="small"
                              width="30px"
                              title={""}
                              onClick={() =>
                                navigate("edit", {
                                  state: {
                                    id: service.id,
                                    name: service.name,
                                    fixedAmount: service.fixed_amount,
                                    variableAmount: service.variable_amount,
                                    description: service.description,
                                  },
                                })
                              }
                              icon={<AiFillEdit />}
                            />
                          </Tooltip>

                          <Tooltip text="Delete" del>
                            <CustomButton
                              type={"secondary"}
                              size="small"
                              del
                              width="30px"
                              title={""}
                              onClick={() =>
                                handleDeleteServiceCode(service.id)
                              }
                              icon={<AiFillDelete />}
                            />
                          </Tooltip>
                        </div>
                      </td>
                    )}
                  </TableRow>
                ))}
            </tbody>
          </IRASTable>
        </>
      )}
      <DeactivateModal
        open={isDeleteModalActive}
        closeModal={() => setIsDeleteModalActive(() => false)}
        approveAction={deleteServiceCode}
        variant="deleteServiceCode"
      />
    </div>
  );
};

export default ManageServiceCode;
