import styled from "styled-components";

const MonthContainer = styled.div`
  margin-bottom: 10px;
`;

const MonthHeadingContainer = styled.div`
  background: #4a5e71;
  padding: 12px 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
`;

const MonthHeading = styled.div`
  color: white;
`;

const CPFReturnContainer = styled.div`
  background: white;
  padding: 12px 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid gray;
`;

const CPFReturnHeading = styled.div`
  background: #f8f9fa;
  font-size: 20px;
  display: flex;
  justify-content:space-between;
  gap: 20px;
  align-items: center;
`;
const CPFTable = styled.table`
  width: 100%;
`;
const CPFButtonHeaderColumn = styled.th`
  width: 10%;
`;
const CPFTextHeadingColumn = styled.td`
  text-align: left;
  padding: 10px;
  font-size: 18px;
`;

const FilingTabLinkContainer = styled.div`
  flex-wrap: wrap;
  border-bottom: 1px solid #6c757d;
  padding-bottom: 10px;
`;

const IRASTableCol = styled.td`
  padding: 0.3125rem;
  border-top: 1px solid ${({ theme }) => theme.WHITE_2};
  text-overflow: hidden;
  overflow: ellipsis;
  max-width: 300px;
  word-wrap: break-word;
  min-width: 100px;
`;

const ReconciliationHeading = styled.h2`
  font-weight: 500;
  font-size: 28px;
  color: ${({ theme }) => theme.BLUE_2};
  margin-top: 20px;
`;

const IRASTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const IRASTableHead = styled.th`
  text-align: left;
  padding: 10px 0;
  border-top: 1px solid ${({ theme }) => theme.WHITE_2};
  border-bottom: 1px solid ${({ theme }) => theme.WHITE_2};
`;
const IRASEditContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const IRASPdfContainer = styled.div`
  width: 16px;
  height: 16px;
  display: inline-block;
`;

const TaxCertificateContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 20px;
  align-items: center;
`;

const IRASLogo = styled.img`
  width: 20px;
  height: 20px;
`;

export {
  MonthHeadingContainer,
  MonthContainer,
  MonthHeading,
  CPFReturnContainer,
  CPFReturnHeading,
  CPFTable,
  CPFButtonHeaderColumn,
  CPFTextHeadingColumn,
  FilingTabLinkContainer,
  IRASTableCol,
  ReconciliationHeading,
  IRASTable,
  IRASTableHead,
  IRASEditContainer,
  IRASPdfContainer,
  TaxCertificateContainer,
  IRASLogo,
};
