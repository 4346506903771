import { useState } from "react";
import styled from "styled-components";
import { SearchField } from "../../dashboard/component";
import { MultiSelectButton } from "../ReportFilterPageStyles";

const Container = styled.div`
  width: 600px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

const ScrollableContainer = styled.div`
  height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const EmployeeRow = styled.div`
  display: flex;
  gap: 20px;
`;

const EmployeeInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`;

const EmployeeName = styled.p`
  font-size: 18px;
`;

const EmployeeListModal = (props: any) => {
  const {
    employeeList,
    handleSelectAll,
    handleSelectNone,
    selectedEmployeeList,
    handleSelectEmployee,
  } = props;
  const [searchText, setSearchText] = useState<string>("");

  return (
    <Container>
      <TopSection>
        <ButtonGroup>
          <MultiSelectButton onClick={() => handleSelectAll()}>
            All
          </MultiSelectButton>
          <MultiSelectButton onClick={() => handleSelectNone()}>
            None
          </MultiSelectButton>
        </ButtonGroup>
        <SearchField value={searchText} onChangeText={setSearchText} />
      </TopSection>
      <ScrollableContainer>
        {employeeList
          .sort((a: any, b: any) =>
            a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
          )
          .filter(
            (em: any) =>
              em?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
              em?.user?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
          )
          .map((employee: any) => (
            <EmployeeRow>
              <EmployeeInfo>
                <input
                  type="checkbox"
                  onChange={() =>
                    handleSelectEmployee(employee?.id || employee?.user.id)
                  }
                  checked={selectedEmployeeList?.includes(
                    employee?.id || employee?.user?.id
                  )}
                />
                <EmployeeName>
                  {employee?.name || employee?.user?.name}
                </EmployeeName>
              </EmployeeInfo>
            </EmployeeRow>
          ))}
      </ScrollableContainer>
    </Container>
  );
};

export default EmployeeListModal;
