// using material ui components, create a table to display the probation activity log
// the data will be an array of objects with the following keys:  probation_last_date, probation_months, probation_status

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material";

const ProbationActivityLog = ({ data }) => {
  // Style the table header cells
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#61677A" || "#0A5EB0",
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "bold",
  }));

  // Style the table rows
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  }));

  console.log("probation data", data);

  return (
    <div
      style={{
        marginTop: "2rem",
        width: "fit-content",
        maxWidth: "70%",
        margin: "2rem 0",
        paddingLeft: "0.35rem",
      }}
    >
      <h3 style={{ margin: "1rem 0" }}>Probation Activity Log</h3>
      {data?.length > 0 ? (
        <TableContainer component={Paper} elevation={3}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Probation Last Date</StyledTableCell>
                <StyledTableCell>Probation Months</StyledTableCell>
                <StyledTableCell>Probation Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice()
                .reverse()
                .map((row) => (
                  <StyledTableRow key={row.probation_last_date}>
                    <TableCell>{row.probation_last_date}</TableCell>
                    <TableCell>{row.probation_months}</TableCell>
                    <TableCell
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {row.probation_status.replace("_", " ")}
                    </TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProbationActivityLog;
