import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../_app";
import {  ServiceCodeService } from "../../_service";
import { Notify, Utils } from "../../utils";
import { LoaderContainer } from "../../CommonStyles";
import { CustomButton, CustomSelectField, Loader, Tooltip } from "../../components";
import { Heading } from "../appSettings/AppSettingsStyles";
import { IRASTable } from "../filing/CPFFilingStyles";
import { TableHeader } from "../../components/table";
import { TableRow } from "../payslip/PayslipStyles";
import moment from "moment";
import { MdOutlineFileDownload, MdPreview } from "react-icons/md";
import { FilterByChar, SearchField } from "./component";
import CustomBackendPagination from "../../components/CustomBackendPagination";

const GlobalInvoicesPastMonthYear = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [invoiceData, setInvoiceData] = useState<any>([]);
    const [apiData,setApiData] = useState<any>([])
    const [searchParams, setSearchParams] = useState('')
    const [toMonth, setToMonth] = useState('12');
    const [fromMonth, setFromMonth] = useState('01');
    const [year, setYear] = useState(
        `${moment().year() - 1}`
    );
    const [char, setChar] = useState("All");

    const navigate = useNavigate();
    const location = useLocation();

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);


    const user = useAppSelector((state) => state.user);


    const serviceCodeTableHeaders = [
        {
            name: "Invoice No.",
            width: "10%",
        },
        {
            name: "Inovice Date",
            width: "10%",
        },
        {
            name: "SPC Code",
            width: "8%",
        },

        {
            name: "Customer Name",
            width: "25%",
        },
        {
            name: "Total Amount",
            width: "10%",
        },

        {
            name: "Status",
            width: "10%",
        },
        {
            name: "Action",
            width: "10%",
        },
    ];
    let pagesize = 10;


    const getYearRange = (year: number = moment().year()) => {
        const startYear = year - 10;
        const yearsRange = [startYear];

        for (let i = 1; i < 12; i++) {
            yearsRange.push(startYear + i);
        }
        return yearsRange;
    };

    const yearOptions = getYearRange()

    const getInvoiceListing = async () => {
        setIsLoading(() => true);
        try {
            const { data, status } = await ServiceCodeService._getInvoicePastMonthList(
                Utils.getStartDateOfMonth(year, fromMonth),
                Utils.getStartDateOfMonth(year, toMonth),
                char==="All" ? "" : char,
                currentPage,
                pagesize,
            );
            console.log(status,"-","count", data.count);

            if (status === 200) {
                setInvoiceData(data?.data);
                setApiData(data)
            }
        } catch (error: any) {
            Utils.showErrorMessage(error);
        }
        setIsLoading(() => false);
    };


    const handlePDFDownload = async (invoiceId: any) => {
        try {
            let response = await ServiceCodeService?._downloadInvoice(invoiceId);
            if (response.status >= 200 && response.status < 300) {
                const blob = new Blob([response.data], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `invoice.pdf`; 
                document.body.appendChild(a);
                a.click();
            }
        } catch (error) {
            Notify("No PDF Available", 0);
        }
    };

    useEffect(() => {
        getInvoiceListing();
    }, []);

    useEffect(() => {
        if (searchParams) {
            getSearchInvoiceHandler();
        }
        else {
            getInvoiceListing();
        }
    }, [searchParams]);

    useEffect(() => {
        getInvoiceListing();
    }, [fromMonth, toMonth, year, char, currentPage]);



    const getSearchInvoiceHandler = async () => {
        const { data, status } = await ServiceCodeService?._getsearchinvoiceList(
            Utils.getStartDateOfMonth(year, fromMonth),
            Utils.getStartDateOfMonth(year, toMonth),
            searchParams,
            currentPage,
            pagesize,
        )
        try {
            if (status === 200) {
                setInvoiceData(data?.data)
            }
        } catch (error: any) {

        }
    }

    const totalItems = apiData?.count; // Total number of employees
    console.log("totalItems",totalItems);
    
    // const itemsPerPage = 10; // Employees per page
    const totalPages = Math.ceil(totalItems / pagesize);
console.log("totalPages",totalPages);

    const handlePageChange = (newPage: any) => {
        setCurrentPage(newPage);
    };
    console.log("currentPage", currentPage, "/ntotalPages", totalPages, "handlePageChange", handlePageChange, "itemsPerPage", pagesize, "totalItems", totalItems);


    return (
        <div>
            {isLoading ? (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            ) : (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "0.5rem",
                            width: "100%"
                        }}
                    >
                        <Heading>Global Invoicing Completed Past Month / Year  </Heading>
                        {user.permission === "full" &&
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',

                            }}>

                                {/* <CustomButton
                            title=" Print Global Report"
                            type="secondary"
                            onClick={() => navigate("generate")}
                        /> */}
                                <SearchField
                                    value={searchParams}
                                    onChangeText={setSearchParams}
                                />
                            </div>

                        }


                    </div>
                    <div
                        style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}
                    >

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{ margin: '1rem' }}>Year: </p>
                            <CustomSelectField
                                value={year}
                                onChangeOption={(value: any) => setYear(value)}
                                option={yearOptions?.reverse().map((month: any) => ({
                                    key: month,
                                    value: month,
                                }))}
                                disabled={isLoading}
                                isDate={true}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{ margin: '1rem' }}>From: </p>
                            <CustomSelectField
                                value={fromMonth}
                                onChangeOption={(value: any) => setFromMonth(value)}
                                option={Utils.MONTH_OPTIONS?.map((month) => ({
                                    key: month.key,
                                    value: month.value,
                                }))}
                                disabled={isLoading}
                                isDate={true}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{ margin: '1rem' }}>To: </p>

                            <CustomSelectField
                                value={toMonth}
                                onChangeOption={(value: any) => setToMonth(value)}
                                option={Utils.MONTH_OPTIONS?.map((month) => ({
                                    key: month.key,
                                    value: month.value,
                                }))}
                                disabled={false}
                                isDate={true}
                            />
                        </div>
                    </div>

                    <FilterByChar value={char} onChange={setChar} />

                    <CustomBackendPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        itemsPerPage={pagesize}
                        totalItems={totalItems}
                    />


                    <IRASTable style={{
                        width: "100%",
                    }}>
                        <TableHeader
                            headerColumns={serviceCodeTableHeaders}
                            variant="filled"
                            styles={{ padding: "5px", textAlign: "left" }}
                        />
                        <tbody>
                            {invoiceData?.length > 0 ?
                                invoiceData
                                    .sort((a: any, b: any) => b.number - a.number)
                                    .map((item: any, index: any) => (
                                        <TableRow style={{ cursor: 'pointer' }} key={item.id}>
                                            <td
                                                style={{
                                                    textAlign: 'left',
                                                    paddingLeft: '1.5rem'
                                                }}>{item?.number || ""}</td>

                                            <td onClick={() => navigate("generate", {
                                                state: { ...item, 'isViewOnly': true },
                                            })} style={{
                                                padding: '0.3125rem'
                                            }}>{item?.date && ` ${moment(item?.date, "YYYY-MM-DD").format("DD MM YYYY").replaceAll(' ', '-')}`}</td>

                                            {/* service code */}
                                            <td onClick={() => navigate("generate", {
                                                state: { ...item, 'isViewOnly': true },
                                            })} >{item?.company_data?.spc_code || ""}</td>

                                            <td onClick={() => navigate("generate", {
                                                state: { ...item, 'isViewOnly': true },
                                            })} style={{
                                                padding: '0.3125rem'
                                            }}>
                                                {item?.company_data.company_name}
                                            </td>


                                            <td onClick={() => navigate("generate", {
                                                state: { ...item, 'isViewOnly': true },
                                            })} style={{
                                                textAlign: "right",
                                                padding: '0.3125rem',
                                                paddingRight: '6%'
                                            }}>{Utils.getCommanSepartedNumericStringWithDollar(item?.json_data?.grand_total || "")}</td>
                                            <td onClick={() => navigate("generate", {
                                                state: { ...item, 'isViewOnly': true },
                                            })} style={{
                                                padding: '0.3125rem'
                                            }}>
                                                <p style={{
                                                    textTransform: 'capitalize'
                                                }}>{item?.status === 'payment_received' && "Payment Received" || item?.status}</p>
                                            </td>
                                            <td
                                                style={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    alignContent: 'center',
                                                    textAlign: "center",
                                                    padding: '0.3125rem'
                                                }}
                                            >

                                                <Tooltip text="Download">
                                                    <CustomButton
                                                        type={"secondary"}
                                                        size="small"
                                                        width="30px"
                                                        title={""}
                                                        onClick={() => handlePDFDownload(item?.id)}
                                                        icon={<MdOutlineFileDownload />}
                                                    />
                                                </Tooltip>
                                            </td>
                                        </TableRow>
                                    ))
                                :
                                (
                                    <tr>
                                        <td colSpan={serviceCodeTableHeaders.length} style={{ textAlign: "center", padding: "8px 12px" }}>
                                            No results found.
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </IRASTable>
                </>
            )}
        </div>
    )
}

export default GlobalInvoicesPastMonthYear