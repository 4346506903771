export const routeMap = {
  "/dashboard/manage-company": "manage_company",
  "/dashboard/payment_runs": "manage_company",
  "/dashboard/bulk_finalisation": "manage_company",
  "/dashboard/giro-export": "manage_company",
  "/dashboard/employee-list": "manage_employee",
  "/dashboard/employee-payroll": "manage_employee",
  "/dashboard/pay-items": "manage_employee",
  "/dashboard/payroll/activity": "manage_employee",
  "/dashboard/payroll/record-taken": "manage_employee",
  "/dashboard/payroll/entitlement-policies": "manage_employee",
  "/dashboard/payroll/entitlement-policies/list": "manage_employee",
  "/dashboard/payroll/entitlement-policies/edit": "manage_employee",
  "/dashboard/payroll/adjusments": "manage_employee",
  "/dashboard/payroll/take-ons": "manage_employee",
  "/dashboard/payroll/basic-info": "manage_employee",
  "/dashboard/payroll/regular-hours": "manage_employee",
  "/dashboard/payroll/regular-hour": "manage_employee",
  "/dashboard/leave-overview": "manage_employee",
  "/dashboard/self-service": "manage_employee",
  "/dashboard/payroll/emp": "manage_employee",
  "/dashboard/emp-activity": "manage_employee",
  "/dashboard/RegularInputItemsAddField/basic_salary": "manage_employee",
  "/dashboard/regular-input-item": "manage_employee",
  "/dashboard/payroll/end-service": "manage_employee",
  "/dashboard/payroll/probation": "manage_employee",
  "/dashboard/payroll/manage-end-service": "manage_employee",
  "/dashboard/payroll/reinstate-employee": "manage_employee",
  "/dashboard/filing": "filling",
  "/dashboard/filing/monthly": "filling",
  "/dashboard/filing/iras": "filling",
  "/dashboard/filing/ir21": "filling",
  "/dashboard/payslip/current": "report",
  "/dashboard/payslip/history": "report",
  "/dashboard/payslip/individual": "report",
  "/dashboard/tms": "manage_tms",
  "/dashboard/users": "manage_user",
  "/dashboard/cpf-cal/Code3": "cpf_calculator",
  "/dashboard/cpf-cal/Code4": "cpf_calculator",
  "/dashboard/cpf-cal/Code5": "cpf_calculator",
  "/dashbord/manage-template/hr": "manage_templates",
  "/dashboard/manage-users": "manage_user",
  "/dashboard/manage-users/groups": "manage_user",
  "/dashboard/app-settings/giro-settings": "app_setting",
  "/dashboard/app-settings/leave": "app_setting",
  "/dashboard/app-settings/custom-reports": "app_setting",
  "/dashboard/app-settings/custom-items": "app_setting",
  "/dashboard/app-settings": "app_setting",
  "/dashboard/app-settings/leave/view": "app_setting",
  "/dashboard/manage-deduction": "app_setting",
  "/dashboard/manage-deduction/add": "app_setting",
  "/dashboard/manage-sdf": "app_setting",
  "/dashboard/manage-cpf-table": "app_setting",
  "/dashboard/manage-cpf-limit": "app_setting",
  "/dashboard/manage-cpf-table/add": "app_setting",
  "/dashboard/manage-cpf-limit/add-ow": "app_setting",
  "/dashboard/manage-cpf-limit/add-aw": "app_setting",
  "/dashboard/manage-global-invoicing": "app_setting",
  "/dashboard/manage-global-invoicing/past-month": "app_setting",
  "/dashboard/manage-template/hr": "manage_templates",
  "/dashboard/approve": "manage_company",
  "/dashboard/approve/details": "manage_company",
  "/dashboard/payslips": "payslip",
  "/dashboard/requests": "request",
  "/dashboard/requests/leave": "request",
  "/dashboard/requests/info": "request",
  "/dashboard/annual-bonus": "annual_bonus",
  "/dashboard/annual-bonus/view": "annual_increment",
  "/dashboard/annual-increment": "annual_increment",
  "/dashboard/annual-increment/view": "annual_increment",
  "/dashboard/manage-report": "report",
  "/dashboard/manage-custom-report": "manage_report",
  "/dashboard/manage-service-code": "manage_service_code",
  "/dashboard/manage-spc": "manage_spc", // add spc
  "/dashboard/manage-spc/add": "manage_spc", // add add spc
  "/dashboard/manage-spc/edit": "manage_spc", // add add spc
  "/dashboard/manage-service-code/add": "manage_service_code",
  "/dashboard/manage-service-code/edit": "manage_service_code",
  // "/dashboard/customer-invoice": "customer_invoicing",
  // "/dashboard/customer-invoice/generate": "customer_invoicing",
  "/dashboard/manage-global-invoicing/generate": "customer_invoicing",
  "/dashboard/manage-global-invoicing/past-month/generate": "customer_invoicing",
  "/dashboard/reinstate-employee": "manage_employee",
  "/dashboard/manage-global-report": "manage_report",
  "/dashboard/manage-global-report/custom-items": "manage_report",
  "/dashboard/manage-global-report/built-in-items": "manage_report",
  "/dashboard/manage-global-report/manage-custom-report": "manage_report",

  "/dashboard/manage_invoicing/company_level_invoice": "company_level_invoices",
  "/dashboard/manage_invoicing/company_level_invoice/generate": "company_level_invoices",
  
  "/dashboard/manage_invoicing/global_level_invoice/current": "global_level_invoices",
  "/dashboard/manage_invoicing/global_level_invoice/current/generate": "global_level_invoices",
  "/dashboard/manage_invoicing/global_level_invoice/past": "global_level_invoices",
  "/dashboard/manage_invoicing/global_level_invoice/past/generate": "global_level_invoices",
  "/dashboard/manage_invoicing/generate_global_invoice": "generate_global_invoices",
} as { [key: string]: string };

export const routeMapWrite = {
  "/dashboard/app-settings/employer-details": "app_setting",
  "/dashboard/app-settings/employee-numbers": "app_setting",
  "/dashboard/app-settings/employer-filing-details": "app_setting",
  "/dashboard/app-settings/pay-frequencies": "app_setting",
  "/dashboard/app-settings/leave/new": "app_setting",
  "/dashboard/app-settings/leave/edit": "app_setting",
  "/dashboard/app-settings/leave/entitlement-policy/new": "app_setting",
  "/dashboard/app-settings/leave/entitlement-policy/edit": "app_setting",
  "/dashboard/manage-template/hr/add": "manage_templates",
  "/dashboard/users/new": "manage_user",
  "/dashboard/users/edit": "manage_user",
  "/dashboard/filing/monthly/new": "filling",
  "/dashboard/filing/iras/edit": "filling",
  "/dashboard/filing/manual": "filling",
  "/dashboard/filing/manual/edit": "filling",
  "/dashboard/filing/manual/add": "filling",
  "/dashboard/add-employee": "manage_employee",
  "/dashboard/RegularInputItemsAddField": "manage_employee",
  "/dashboard/EmployeePayslipAddField": "manage_employee",
  "/dashboard/payslip-calculation": "manage_employee",
  "/dashboard/notes": "manage_employee",
  "/dashboard/notes-edit/": "manage_employee",
  "/dashboard/self-service/employee-users": "manage_employee",
  "/dashboard/self-service/settings/general": "manage_employee",
  "/dashboard/self-service/settings/leave": "manage_employee",
  "/dashboard/manage-users/edit-groups": "manage_user",
  "/dashboard/manage-users/edit-groups/tabs": "manage_user",
  "/dashboard/manage-users/edit": "manage_user",
  "/dashboard/manage-users/groups/add": "manage_user",
  "/dashboard/manage-users/groups/edit": "manage_user",
  "/dashboard/manage-users/new": "manage_user",
  "/dashboard/app-settings/payroll-calculations/basic": "app_setting",
  "/dashboard/app-settings/payroll-calculations/cpf": "app_setting",
  "/dashboard/add-item": "app_setting",
  "/dashboard/add-item-fields/income": "app_setting",
  "/dashboard/add-item-fields/deduction": "app_setting",
  "/dashboard/add-item-fields/allowance": "app_setting",
  "/dashboard/add-item-fields/benefit": "app_setting",
  "/dashboard/add-item-fields/employer_contribution": "app_setting",
  "/dashboard/add-item-fields/reimbursement": "app_setting",
  "/dashboard/manage-template/hr/edit": "manage_templates",
  "/dashboard/manage-template/hr/view": "manage_templates",
  "/dashboard/reports": "report",
  "/dashboard/reports/leave_report": "report",
  "/dashboard/reports/leave_days_report": "report",
  "/dashboard/reports/leave_expiry": "report",
  "/dashboard/reports/payslip_export": "report",
  "/dashboard/reports/employee_basic_info": "report",
  "/dashboard/reports/transaction_history_report": "report",
  "/dashboard/reports/mpr_full": "report",
  "/dashboard/reports/mpr_gross": "report",
  "/dashboard/reports/mpr_drn": "report",
  "/dashboard/reports/variance_report": "report",
  "/dashboard/reports/balances_loans_and_savings": "report",
  "/dashboard/reports/leave_liabilities": "report",
  "/dashboard/reports/probation": "report",


  "/dashboard/reports/monthly_report": "report",
  "/dashboard/reports/ytd_payroll_report": "report",
  "/dashboard/reports/ytd_by_employees_report": "report",
  "/dashboard/reports/ytd_by_company_report": "report",

  "/dashboard/tellboss/payroll": "payroll",
  "/dashboard/tellboss/payroll/change": "payroll",
  "/dashboard/tellboss/payroll/preview": "payroll",
  "/dashboard/payslips": "payroll",
  
  "/dashboard/annual-increment/add": "annual_increment",
  "/dashboard/annual-increment/edit": "annual_increment",
  "/dashboard/annual-bonus/add": "annual_increment",
  "/dashboard/annual-bonus/edit": "annual_increment",
  "/dashboard/manage-company/deactivate": "manage_company",
} as { [key: string]: string };

export const openRoute: string[] = [
  "/dashboard/request/cso",
  "/dashboard/request/reviewer",
  "/dashboard/request/finalizer",
  "/dashboard/request/data-entry",
  "/dashboard/request/add",
  "/dashboard/request/view",
  "/dashboard/request/completed",
  "/dashboard/user-profile",
  "/dashboard/access-denied",
];
