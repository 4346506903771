import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

//Wrap your component within <CustomTolltip><CustomTolltip/>
// Pass the tooltip text in "title" prop

/* 
  placement props values

  "top",
  "top-start",
  "top-end",

  "left",
  "left-start",
  "left-end",

  "right",
  "right-start",
  "right-end",

  "bottom",
  "bottom-start",
  "bottom-end",

*/

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement={props.placement || "right-start"}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: "1rem",
    fontFamily: "Montserrat",
    maxWidth: 500,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontWeight: 500,
    animation: "pulse 2s infinite", // Increased duration to 2s
  },
  "@keyframes pulse": {
    "0%": {
      boxShadow: "0 0 0 0 rgba(33, 150, 243, 0.6)", // Increased opacity
    },
    "70%": {
      boxShadow: "0 0 0 15px rgba(33, 150, 243, 0)", // Increased spread radius to 15px
    },
    "100%": {
      boxShadow: "0 0 0 0 rgba(33, 150, 243, 0)",
    },
  },
}));

export default CustomTooltip;