import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import LogoutMessageContainer from "./LogoutModalStyles";
import { useAppSelector } from "../../_app";
import { useContext } from "react";
import useFirebaseAnalytics from "../../hooks/useFirebaseAnalytics";
import moment from "moment";
import { Notify } from "../../utils";
import { AuthService } from "../../_service";
import { microAgent } from "../../newRelicConfig";

interface LogoutModalProps {
  closeLogoutModal: () => void;
  onLogout: () => void;
  open: boolean;
  variant?: "logout" | "timeout";
}

const LogoutModal = (props: LogoutModalProps) => {
  const { closeLogoutModal, onLogout, open, variant = "logout" } = props;

  const user = useAppSelector((state) => state.user);
  const logFirebaseEvent = useFirebaseAnalytics();

  const callLogoutRegister = async () => {
    try {
      await AuthService._logoutRegister();
    } catch (error) {
      Notify("Some Error occured, logging out due to security reasons");
      onLogout();
    }
  };

  return (
    <Modal open={open} onClose={closeLogoutModal}>
      <Box>
        <LogoutMessageContainer>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {variant === "logout" ? "Logout" : "Session Time out"}
          </Typography>
          <Box sx={{ margin: "12px -16px" }}>
            <Divider light={true} />
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
            {variant === "logout"
              ? "Do you really want to Logout?"
              : "Your Session has expired, do you want to extend the session?"}
          </Typography>
          <Box sx={{ margin: "0 -16px 32px -16px" }}>
            <Divider light={true} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "16px",
            }}
          >
            <Box>
              <Button
                onClick={() => {
                  if (variant === "timeout") {
                    console.log("chosing to refresh, call firebse");
                    let key = "refresh_user";
                    let data = {
                      user_id: user.id,
                      name: user.name,
                      // device_fingerprint: "using_client_js",
                      event_date_time: moment()
                        .utc()
                        .format("DD/MM/YYYY hh:mm A"),
                      platform: "web",
                    };
                    // logFirebaseEvent(key, data);
                    microAgent.addPageAction(key,data)
                    
                  }
                  closeLogoutModal();
                }}
                variant="contained"
              >
                {variant === "logout"
                  ? "Stay Logged In"
                  : "Yes, extend the session"}
              </Button>
              <Button
                onClick={() => {
                  console.log("calling onLogout");
                  let key = "logout_user";
                  let data = {
                    user_id: user.id,
                    name: user.name,
                    // device_fingerprint: "using_client_js",
                    event_date_time: moment()
                      .utc()
                      .format("DD/MM/YYYY hh:mm A"),
                    platform: "web",
                  };
                  logFirebaseEvent(key, data);
                  microAgent.addPageAction(key,data);
                  localStorage.removeItem("payrollPreview");

                  callLogoutRegister()
                  onLogout();
                }}
                sx={{ ml: 0.8 }}
              >
                {variant === "logout" ? "Logout" : "No I am done, Log Out"}
              </Button>
            </Box>
          </Box>
        </LogoutMessageContainer>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
