import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../_app";
import { H3Heading, Heading } from "../appSettings/AppSettingsStyles";
import { CommonModal, CustomButton, CustomSelectField } from "../../components";
import { CompanyService } from "../../_service";
import { Notify } from "../../utils";

const DeactivateCompany = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationData = location.state?.data;

  console.log("theLocationData, ", locationData);

  const [reason, setReason] = useState<any>({ value: "" });
  const [loading, setLoading] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(false);

  //   const getEndServiceDetails = async () => {
  //     try {
  //       const { data, status } = await PayrollService._getEndService({
  //         employee: employee.id,
  //         companyId,
  //       });

  //       if (status >= 200 && status < 300) {
  //         if (data.data?.end_service_reason) {
  //           setLastDayOfService(() => data.data.last_date_of_service);
  //           setEosId(data?.data?.id);
  //         }
  //       }
  //     } catch (error) {
  //       Notify(Constant.common.something_went_wrong, 0);
  //     }
  //   };

  useEffect(() => {
    // get();
    // getEndServiceDetails();
    // getServicePeriodHistory();
  }, []);

  const handleDeactivate = async () => {
    const theReason: any = reasonList?.filter(
      (x: any) => x.id === reason?.value
    )?.[0]?.value;
    console.log("call deactivate", theReason);
    setLoading(true);

    try {
      const res = await CompanyService._deactivateCompany(locationData?.id, {
        deactivate_reason: theReason,
      });

      if (res?.status >= 200 && res?.status < 300) {
        Notify("Company Dectivated Successfully", 1);
        navigate("/dashboard/manage-company");
      }
      setLoading(false);
      setConfirmationModal(false);
    } catch (e: any) {
      console.error(e);
      Notify(e?.response?.data?.error[0], 0);
      setLoading(false);
      setConfirmationModal(false);
    }
  };

  const reasonList: any = [
    { id: "reason_00", value: "Missing Functionality" },
    { id: "reason_01", value: "Transferring to another outsourced payroll" },
    { id: "reason_02", value: "Transferring to another SalaryPay account" },
    { id: "reason_03", value: "Business closure" },
    { id: "reason_04", value: "Business structure change (e.g. acquisition)" },
    { id: "reason_05", value: "Other" },
  ];

  return (
    <div style={{ marginTop: "0rem" }}>
      <div style={{ marginBottom: "2rem" }}>
        <Heading>{`Deactivate Company`}</Heading>

        <CommonModal
          handleClose={() => setConfirmationModal(false)}
          open={confirmationModal}
          loading={loading}
          updateData={() => handleDeactivate()}
          body={`Are you sure want to deactivate ${locationData?.name}`}
          heading="Deactivate Company"
          conformButtonText="Confirm"
          cancelButtonText="Cancel"
        />

        <p
          style={{
            margin: "1rem 0",
          }}
        >
          Please select a reason for deactivating:
        </p>

        <CustomSelectField
          placeholder={"Select"}
          value={reason?.value}
          option={reasonList?.map((item: any) => ({
            key: item?.value,
            value: item?.id,
          }))}
          onChangeOption={(option) => setReason({ value: option })}
        />

        <p style={{ marginTop: "1rem" }}>
          We will store the company in an inactive state. <br /> You will be
          able to make it Live, If required in future. <br /> After 1 year of continuous
          inactivity, the company will be deleted permanently.
        </p>
      </div>

      <CustomButton
        title="Deactivate Company"
        onClick={() => setConfirmationModal(true)}
        // loading={loading}
        styles={{
          margin: 0,
        }}
        disable={loading}
      />
    </div>
  );
};

export default DeactivateCompany;
