import { useAppSelector } from "../../../_app";
import { PayrollService } from "../../../_service";
import { CommonModal, CustomTextField, Loader } from "../../../components";
import { CustomButton } from "../../../components/customButton";
import { useEffect, useState } from "react";
import moment from "moment";
import { Notify, Utils } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { LoaderContainer } from "../../../CommonStyles";
import styled from "styled-components";
import { MdRunningWithErrors } from "react-icons/md";
import { BiError } from "react-icons/bi";
import { ActionButton } from "../../users/styles";
import {
  BiCalendarPlus,
  BiCalendarMinus,
  BiCalendarCheck,
  BiCalendarExclamation,
} from "react-icons/bi";
import ProbationDateModal from "./ProbationDateModal";
import { set } from "lodash";
import ProbationActivityLog from "./ProbationActivityLog";

const Probation = () => {
  const navigate = useNavigate();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openProbationFailedModal, setOpenProbationFailedModal] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [servicePeriodInfo, setServicePeriodInfo] = useState<any>({
    company: "",
    employee: "",
    is_probation_confirmed: false,
    probation_confirmed_by: "",
    probation_confirmed_date: "",
    probation_last_date: ``,
    probation_months: "",
    service_end_date: "",
    service_start_date: "",
  });

  const [openProbationModal, setOpenProbationModal] = useState({
    open: false,
    type: "",
  });

  const employeeId = useAppSelector((state) => state.employee.employee_info.id);

  const getServicePeriodInfo = async () => {
    try {
      setLoading(true);
      let res: any = await PayrollService._getServicePeriod(employeeId);

      if (res.status >= 200 && res.status < 300) {
        setServicePeriodInfo({ ...res?.data?.data });
      }

      setLoading(false);
    } catch (error: any) {
      Utils.showErrorMessage(error);
      setLoading(false);
    }
  };

  // type = "add", "early", "extend", "fail", "confirm"
  const updateProbation = async (type: string, data: any) => {
    setShowMessage(false);
    try {
      setLoading(true);
      let payload = {};

      if (type === "confirm" || type === "fail") {
        payload = {
          service_period_id: servicePeriodInfo?.id,
        };
      }

      if (type === "extend") {
        payload = {
          service_period_id: servicePeriodInfo?.id,
          extended_date: data?.updatedDate,
          extended_month_count: data?.monthCount,
        };
      }

      if (type === "early") {
        payload = {
          service_period_id: servicePeriodInfo?.id,
          early_date: data?.updatedDate,
          early_month_count: data?.monthCount,
        };
      }

      if (type === "add") {
        payload = {
          service_period_id: servicePeriodInfo?.id,
          probation_last_date: data?.updatedDate,
          probation_months: data?.monthCount,
        };
      }

      console.log("final payload", payload);

      let res = await PayrollService._updateProbationInfo(type, payload);

      if (res.status >= 200 && res.status < 300) {
        setServicePeriodInfo({ ...res?.data?.data });
        Notify("Probation date updated succesfully!", 1);
      }
      
      setLoading(false);
      setOpenConfirmationModal(false);
      setOpenProbationModal({ open: false, type: "" });
      setOpenProbationFailedModal(false);
      
    } catch (error: any) {
      Utils.showErrorMessage(error);
      setLoading(false);
      setOpenConfirmationModal(false);
      setOpenProbationModal({ open: false, type: "" });
      setOpenProbationFailedModal(false);
    }
  };

  console.log("state after storing data", servicePeriodInfo);
  useEffect(() => {
    getServicePeriodInfo();
  }, [JSON.stringify(servicePeriodInfo)]);

  // const handleProbationConfirmation = async () => {
  //   setServicePeriodInfo((prevState: any) => ({
  //     ...prevState,
  //     is_probation_confirmed: !prevState.is_probation_confirmed,
  //   }));

  //   try {
  //     let res = await PayrollService._editServicePeriod(servicePeriodInfo?.id, {
  //       is_probation_confirmed: true,
  //     });

  //     if (res?.data?.data?.is_probation_confirmed) {
  //       Notify("Probation Confirmed Successfully!", 1);
  //       setServicePeriodInfo({
  //         ...servicePeriodInfo,
  //         is_probation_confirmed: true,
  //         probation_confirmed_date: res?.data?.data?.probation_confirmed_date,
  //       });
  //     }
  //     setOpenConfirmationModal(false);
  //     setShowMessage(false);
  //   } catch (e: any) {
  //     Utils.showErrorMessage(e);
  //     setOpenConfirmationModal(false);
  //   }
  // };

  const isDateSameOrAfter = () => {
    setOpenConfirmationModal(false);
    if (servicePeriodInfo?.probation_last_date) {
      const dateToCheck = moment(servicePeriodInfo?.probation_last_date);
      const currentDate = moment();
      const isEqualOrAfter = currentDate.isSameOrAfter(dateToCheck);

      console.log("isEqual", { dateToCheck, currentDate, isEqualOrAfter });
      if (isEqualOrAfter) {
        updateProbation("confirm", {});
      } else {
        setShowMessage(true);
      }
    }
  };

  const handleProbationDateChange = (type: string, date: string) => {
    let appointmentDate = moment(servicePeriodInfo?.service_start_date);
    let newProbationDate = moment(date, "YYYY-MM-DD");

    let monthDiff = newProbationDate.diff(appointmentDate, "months", true);

    // Calculate the difference in months
    let data = {
      updatedDate: date,
      monthCount: Math.ceil(monthDiff),
      type: type,
    };

    console.log("new probation data", data);

    setOpenProbationModal({ open: false, type: "" });
    updateProbation(type, data);
  };

  console.log("servicePeriodInfo", servicePeriodInfo);

  const handleAddProbation = () => {
    if(servicePeriodInfo?.probation_status === "not_applicable"){
      setOpenProbationModal({ open: true, type: "add" });
    }
  }

  if (loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <div style={{ marginTop: "2rem", width: "100%" }}>
      {/* Probation info */}
      <div
        style={{
          width: "fit-content",
          display: "grid",
          gridTemplateColumns: "2fr 1fr",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1rem",
          border: "1px solid #ddd",
          padding: "1rem",
          borderRadius: "10px",
          backgroundColor: "#F4F6FF",
        }}
      >
        <p style={{ color: "#121212", fontSize: "1.2rem", fontWeight: 600 }}>
          Probation Months:{" "}
        </p>
        <p
          style={{
            color: "#121212",
            textAlign: "right",
            fontSize: "1rem",
            fontWeight: 500,
          }}
        >
          {servicePeriodInfo?.probation_months}
        </p>
        <p style={{ color: "#121212", fontSize: "1.2rem", fontWeight: 600 }}>
          Probation Last Date:{" "}
        </p>
        <p
          style={{
            color: "#121212",
            textAlign: "right",
            fontSize: "1rem",
            fontWeight: 500,
          }}
        >
          {servicePeriodInfo?.probation_status === "not_applicable" ? "Not Applicable" : moment(servicePeriodInfo?.probation_last_date, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          )}
        </p>
      </div>

      {/* Button group for - extendDate, earlyDate, failedProbation */}

      {servicePeriodInfo?.probation_status != "not_applicable" && servicePeriodInfo?.probation_status != "confirmed" && <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          marginTop: "1.5rem",
          backgroundColor: "#F4F6FF",
          borderRadius: "10px",
          border: "1px solid #ddd",
          padding: "0.75rem 1rem",
          width: "fit-content",
        }}
      >
        {/* {servicePeriodInfo?.probation_status === "not_applicable" && <ActionButton
          backgroundColor="#03B381"
          textColor="#fff"
          onClick={() => setOpenProbationModal({ open: true, type: "add" })}
        >
          {<BiCalendarPlus style={{ width: 24, height: 24 }} />}
          <p>Add Probation</p>
        </ActionButton>} */}
        
        {servicePeriodInfo?.probation_status != "not_applicable" && <>
          <ActionButton
          backgroundColor="#03B381"
          textColor="#fff"
          onClick={() => setOpenProbationModal({ open: true, type: "early" })}
        >
          {<BiCalendarMinus style={{ width: 24, height: 24 }} />}
          <p>Early Probation</p>
        </ActionButton>
        <ActionButton
          backgroundColor="#608BC1"
          textColor="#fff"
          onClick={() => setOpenProbationModal({ open: true, type: "extend" })}
        >
          {<BiCalendarPlus style={{ width: 24, height: 24 }} />}
          <p>Extend Probation</p>
        </ActionButton>
        <ActionButton
          backgroundColor="#ff6665"
          textColor="#fff"
          onClick={() => setOpenProbationFailedModal(true)}
        >
          {<BiCalendarExclamation style={{ width: 24, height: 24 }} />}
          <p>Failed Probation</p>
        </ActionButton>
        
        </>}
        

        {servicePeriodInfo?.probation_last_date &&
          servicePeriodInfo?.probation_status != "confirmed" && servicePeriodInfo?.probation_status != "not_applicable" &&  servicePeriodInfo?.probation_status != "failed" && (
            <>
              <div
                style={{
                  borderLeft: "1px solid #2A3335",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
              ></div>
              <ActionButton
                backgroundColor="#059bbf"
                textColor="#fff"
                onClick={() => setOpenConfirmationModal(true)}
              >
                {<BiCalendarCheck style={{ width: 24, height: 24 }} />}
                <p>Confirm Probation</p>
              </ActionButton>{" "}
            </>
          )}
      </div>}

      {/* Confirm button and modal */}
      {/* {servicePeriodInfo?.probation_last_date &&
        !servicePeriodInfo?.is_probation_confirmed && (
          <div
            style={{
              marginTop: "8rem",
              marginLeft: "1rem",
            }}
          >
            <CustomButton
              title="Confirm"
              disable={
                servicePeriodInfo?.is_probation_confirmed == true ||
                !servicePeriodInfo?.probation_last_date
              }
              onClick={() => setOpenConfirmationModal(true)}
              styles={{ margin: 0 }}
            />
          </div>
        )} */}

      {servicePeriodInfo?.is_probation_confirmed &&
        servicePeriodInfo?.probation_confirmed_date && (
          <p
            style={{
              fontSize: "1.25rem",
              color: "#009990",
              fontWeight: 600,
              marginTop: "3rem",
              marginLeft: "1rem",
            }}
          >{`Probation confirmed on ${moment(
            servicePeriodInfo?.probation_confirmed_date?.split("T")?.[0]
          ).format("DD-MM-YYYY")}`}</p>
        )}

      { servicePeriodInfo?.probation_status === "failed" && (
          <p
          style={{
            color: "#ff6665",
            marginTop: "1rem",
            fontWeight: 600,
            fontSize: "1.25rem",
            border: "1px solid red",
            borderRadius: "4px",
            width: "fit-content",
            padding: "4px 16px",
          }}
          >{`Probation marked as failed`}</p>
        )}

      <CommonModal
        handleClose={() => setOpenConfirmationModal(false)}
        open={openConfirmationModal}
        // loading={loading}
        // updateData={()=>handleProbationConfirmation()}
        updateData={() => isDateSameOrAfter()}
        body={<div>
          <p>Are you sure you want to confirm the probation?</p>
          <p>Employee will be notified via email on confirmation.</p>
        </div>}
        heading="Confirm Probation"
        conformButtonText="Yes to confirm Probation"
        cancelButtonText="No-Exit"
        onCancel={() => setOpenConfirmationModal(false)} //called on confirm
      />

      <CommonModal
        handleClose={() => setOpenProbationFailedModal(false)}
        open={openProbationFailedModal}
        // loading={loading}
        // updateData={()=>handleProbationConfirmation()}
        updateData={() => updateProbation("fail", {})}
        body={<div>
          <p>Are you sure you want to set the probation as failed?</p>
          <p>Employee will be notified via email.</p>
        </div>}
        heading="Fail Probation"
        conformButtonText="Yes"
        cancelButtonText="No"
        onCancel={() => setOpenProbationFailedModal(false)}
      />

      {openProbationModal?.type && (
        <ProbationDateModal
          open={openProbationModal}
          onCancel={() => setOpenProbationModal({ open: false, type: "" })}
          handleClose={() => setOpenProbationModal({ open: false, type: "" })}
          updateData={(type: string, date: string) =>
            handleProbationDateChange(type, date)
          }
          loading={false}
          heading={
            openProbationModal?.type === "early"
              ? "Set Early Probation Date" : openProbationModal?.type === "extend"
              ? "Set Extended Probation Date" : "Set Probation Date"
          }
          conformButtonText="Update"
          cancelButtonText="Cancel"
          deleteButtonText=""
          isCancelButton={true}
          padding={40}
          deleteData={() => {}}
          type={openProbationModal?.type}
          dateLabel="New Probation Date:"
          oldProbationDate={servicePeriodInfo?.probation_last_date}
          appointmentDate={servicePeriodInfo?.service_start_date}
        />
      )}

      {showMessage && (
        <p
          style={{
            color: "#ff6665",
            marginTop: "1rem",
            fontWeight: 600,
            fontSize: "1.25rem",
            border: "1px solid red",
            borderRadius: "4px",
            width: "fit-content",
            padding: "4px 16px",
          }}
        >{`Probation can only be confirmed on or after ${
          servicePeriodInfo?.probation_last_date
            ? moment(servicePeriodInfo?.probation_last_date).format(
                "DD-MM-YYYY"
              )
            : ""
        }`}</p>
      )}

      <ProbationActivityLog data={servicePeriodInfo?.probation_history} />
    </div>
  );
};

export default Probation;

// ------------
