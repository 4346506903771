import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

// import "./add-company.css";
import { IconContext } from "react-icons";
import styled from "styled-components";
import {
  CustomButton,
  CustomDateField,
  CustomTextField,
} from "../../../components";
import { useEffect, useState } from "react";
import moment from "moment";

// interface ReconcileModalProps {
//   body?: string | JSX.Element;
//   open: boolean;
//   amount: string;
//   handleClose: () => void;
//   updateData: any;
//   onCancel?: () => void;
//   loading?: boolean;
//   heading?: string;
//   conformButtonText?: string;
//   cancelButtonText?: string;
//   deleteButtonText?: string;
//   isCancelButton?: boolean;
//   padding?: number;
//   deleteData?: () => void;
// }

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #eee",
    borderRadius: "10px",
    padding: 0,
    minHeight: "40vh",
    width: "25%",
  },
  overlay: {
    zIndex: 100,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
};

const ProbationDateModal = (props: any) => {
  const {
    handleClose,
    onCancel,
    open,
    appointmentDate,
    dateLabel,
    oldProbationDate,
    updateData,
    loading = false,
    heading = "Please confirm",
    conformButtonText = "Confirm",
    cancelButtonText = "Close",
    deleteButtonText = "",
    isCancelButton = true,
    padding = 40,
    deleteData = () => {},
    type,
  } = props;

  const [probationDate, setProbationDate] = useState<string>(
    oldProbationDate || ""
  );

  const [showError, setShowError] = useState({
    error:false,
    message: "",
  });

  const validateDate = () => {
    console.log("thedates",{probationDate, appointmentDate, oldProbationDate})
    if(moment(probationDate).isBefore(appointmentDate)) {
      setShowError({
        error: true,
        message: "Probation date cannot be before the appointment date.",
      });
      return;
    }

    if(type === "early" && moment(probationDate).isAfter(oldProbationDate)){
      setShowError({
        error: true,
        message: "For early probation, the new probation date must be before the current probation date",
      });
      return;
    }

    if(type === "extend" && moment(probationDate).isBefore(oldProbationDate) ) {
      setShowError({
        error: true,
        message: "For extended probation, the new probation date must be after the current probation date",
      });
      return;
    }

    setShowError({
      error: false,
      message: "",
    });
  }

  useEffect(() => {
    validateDate();
  }, [probationDate])

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      style={customStyles}
      // contentLabel= {type === "early" ? "Set Early Probation Date" : "Set Extended Probation Date"}
      ref={(e) => e}
      ariaHideApp={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Header>
          <h3>{heading}</h3>
          <span onClick={handleClose}>
            <IconContext.Provider value={{ color: "red", size: "20px" }}>
              <AiOutlineClose />
            </IconContext.Provider>
          </span>
        </Header>
        <p
          style={{
            fontSize: "1.1rem",
            fontWeight: 400,
            padding: "0 20px",
            color: "#495057",
            marginTop: "1.25rem",
            marginBottom: 0,
          }}
        >{`Please set ${
          type === "early" ? "an earlier" : type === "add" ? "a" : "a later"
        } probation date.`}</p>
        <p
          style={{
            fontSize: "1.1rem",
            fontWeight: 400,
            padding: "0 20px",
            color: "#495057",
            marginBottom: 0,
          }}
        >{`Employee will be notified via email on update.`}</p>
        <Body>
          <CustomDateField
            label="Appointment Date:"
            date={appointmentDate || null}
            disabled={true}
            setDate={() => null}
            labelWidth="50%"
          />
         {type != "add" && <CustomDateField
            label="Current Probation Date:"
            date={oldProbationDate || null}
            disabled={true}
            setDate={() => null}
            labelWidth="50%"
          />}
          <CustomDateField
            label={dateLabel}
            date={probationDate || null}
            setDate={setProbationDate}
            labelWidth="50%"
          />
        {showError?.error && <p style={{
          color: "red",
          fontSize: "0.9rem",
          marginTop: "0.5rem",
        }}>{showError?.message}</p>}
        </Body>


        <Footer>
          {conformButtonText && (
            <CustomButton
              title={conformButtonText}
              onClick={() => showError.error ? null  : updateData(type, probationDate)}
              // width="120px"
              loading={loading}
              disable={loading}
            />
          )}

          {isCancelButton && (
            <CustomButton
              title={cancelButtonText}
              type="secondary"
              onClick={onCancel || handleClose}
              // width="120px"
            />
          )}
          {deleteButtonText && (
            <CustomButton
              title={deleteButtonText}
              type="secondary"
              onClick={deleteData}
              // width="120px"
              del={true}
            />
          )}
        </Footer>
      </div>
    </Modal>
  );
};

export default ProbationDateModal;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #059bbf;
  padding: 10px 20px 20px 20px;
  h3 {
    color: #059bbf;
  }
  span {
    cursor: pointer;
  }
`;
const Body = styled.div`
  padding:0 1.25rem;
  margin: 1.25rem 0;
  p {
    color: grey;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  padding: 14px 15px 15px;
  border-top: 1px solid #059bbf;
  margin-top: 20px;
`;
