import { useEffect, useState } from "react";
import { TableHeader } from "../../../components/table";
import { Notify, Utils } from "../../../utils";
import { TablePaginationHeader } from "../../dashboard/component";
import { MdDelete } from "react-icons/md";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableRow } from "../../payslip/PayslipStyles";
import { TableColumn } from "../../users/styles";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { DashboardService } from "../../../_service/DashboardService";
import { CommonModal } from "../../../components";
import { set } from "date-fns";

const RequestTable = (props: any) => {
  const { data } = props;
  const navigate = useNavigate();

  const pathname = useLocation().pathname;
  const showCompletedOn = pathname.includes('completed');

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [deleteId, setDeleteId] = useState<any>(null);

  const [filteredData, setFilteredData] = useState<any>(data);

  const [pageInfo, setPageInfo] = useState<any>({
    currentPageNumber: 1,
    dataPerPage: 10,
    totalLength: data.length,
  });

  const handlePageChange = (pageNo: number) => {
    setPageInfo((info: any) => ({
      ...info,
      currentPageNumber: pageNo,
    }));
  };

  useEffect(() => {
    const startIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage - pageInfo.dataPerPage;
    const endIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage > data.length
        ? data.length
        : pageInfo.currentPageNumber * pageInfo.dataPerPage;
    const leavesObj = data;
    const applyFilter = leavesObj.filter((leaveData: any) => leaveData);
    setFilteredData(() => applyFilter.slice(startIndex, endIndex));
  }, [pageInfo]);



  const tableHeaders = showCompletedOn ? [
    {
      name: "Company Name",
      width: "17%",
    },
    {
      name: "Card ID",
      width: "8%",
    },
    {
      name: "Customer Name",
      width: "17%",
    },
    {
      name: "Created on",
      width: "15%",
    },
    {
      name: "Completed on",
      width: "15%",
    },
    {
      name: "Request Type",
      width: "15%",
    },
    {
      name: "Status",
      width: "13%",
    },
    {
      name: 'Action',
      width: '10%'
    }
  ] : [
    {
      name: "Company Name",
      width: "17%",
    },
    {
      name: "Card ID",
      width: "8%",
    },
    {
      name: "Customer Name",
      width: "17%",
    },
    {
      name: "Created on",
      width: "15%",
    },
    {
      name: "Request Type",
      width: "15%",
    },
    {
      name: "Status",
      width: "13%",
    },
    {
      name: 'Action',
      width: '10%'
    }
  ];

  const handleDelete = async () => {
    if(deleteId) {
      try {
        const { status } = await DashboardService._deleteRequest(deleteId);
        if (status >= 200 && status < 300) {
          Notify("Request Deleted Successfully", 1);
          setShowDeleteModal(() => false);
          setDeleteId(() => null);
          props.getSubmittedRequests();
        }
      } catch (error:any) {
        setDeleteId(() => null);
        setShowDeleteModal(() => false);
        Utils.showErrorMessage(error)
        props.getSubmittedRequests();
      }
    }
  };



  return (
    <div>
      <div style={{ width: "98%", marginTop: 20 }}>
        <TablePaginationHeader
          pageInfo={pageInfo}
          name="Leaves"
          handlePageValueChange={handlePageChange}
        />
      </div>
      <IRASTable>
        <TableHeader headerColumns={tableHeaders} variant="filled" />
        <tbody>
          {filteredData.map((requestData: any) => (
            <TableRow>
              <TableColumn
                style={{ cursor: "pointer", fontWeight: 700,  }}
                // onClick={() =>
                //   navigate("/dashboard/request/view", {
                //     state: { data: requestData },
                //   })
                // }
              >
                <span 
                style={{color:"#059bbf"}}
                onClick={() =>
                  navigate("/dashboard/manage-company")
                }
                onMouseEnter={(e) => {
                  (e.target as HTMLElement).style.color = "blue";
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLElement).style.color = "#059bbf";
                }}
                >{requestData?.company_name || ""}</span>
              </TableColumn>
              <TableColumn
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/dashboard/request/view", {
                    state: { data: requestData },
                  })
                }
              >
                {requestData?.card_id || `CARD00${requestData.id}`}
              </TableColumn>
              <TableColumn
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/dashboard/request/view", {
                    state: { data: requestData },
                  })
                }
              >
                {requestData?.name || ""}
              </TableColumn>
              <TableColumn
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/dashboard/request/view", {
                    state: { data: requestData },
                  })
                }
              >
                {/* {Utils._dateToShow(requestData.created_at)} */}
                {moment(requestData.created_at).format("DD/MM/YYYY hh:mm A")}
              </TableColumn>

              {showCompletedOn && <TableColumn
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/dashboard/request/view", {
                    state: { data: requestData },
                  })
                }
              >
                {moment(requestData.updated_at).format("DD/MM/YYYY hh:mm A")}
              </TableColumn>}
              
              <TableColumn
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/dashboard/request/view", {
                    state: { data: requestData },
                  })
                }
              >
                {Object.keys(requestData?.request_type).map(
                  (key) => requestData?.request_type[key] || ""
                )}
              </TableColumn>

              <TableColumn
                style={{
                  cursor: "pointer",
                  color: requestData?.status.toLowerCase() === "pending" ?  "#dc3545" : requestData?.status.toLowerCase() === "completed" ? "#28a745": "#059bbf",
                }}
                onClick={() =>
                  navigate("/dashboard/request/view", {
                    state: { data: requestData },
                  })
                }
              >
                {requestData?.status}
              </TableColumn>

             {requestData?.status === "Pending" && <TableColumn>
              <MdDelete 
              size={20} 
              style={{ cursor: 'pointer', marginLeft: '10px', color: '#dc3545' }}
              onClick={(e) => {
                setDeleteId(requestData?.id);
                setShowDeleteModal(() => true);
              }}
            />
              </TableColumn>}
            
            </TableRow>
          ))}
        </tbody>
      </IRASTable>
      {data.length === 0 && (
        <p style={{ textAlign: "center", marginTop: 20, fontSize: 20 }}>
          No records found
        </p>
      )}
      <CommonModal
        handleClose={() => setShowDeleteModal(() => false)}
        open={showDeleteModal}
        updateData={() => handleDelete()}
        body="Are you sure you want to delete this request?"
        heading="Delete Request"
        conformButtonText="Yes"
        cancelButtonText="No"
      />
    </div>
  );
};

export default RequestTable;
