import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { DEVICE } from "../../GlobalStyles";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
`;
const Heading = styled.p`
  min-height: 50px;
  padding: 0.75rem 1.25rem;
  border-radius: 0.2rem;
  font-size: 1.2rem;
  background-color: #4a5e71;
  color: #f8f9fa;
  text-transform: capitalize;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  width: 400px;
  flex-grow: 1;
  border: 1px solid gray;
  border-radius: 5px;
`;
const Navlink = styled(NavLink)`
  background-color: #f8f9fa;
  padding: 0.75rem 1.25rem;
  padding-right: 0.5rem;
  border-radius: 0.2rem;
  text-decoration: none;
  color: #059bbf;
  &:hover {
    text-decoration: underline;
    color: #333;
  }
`;

const DashboardContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 50px);
  justify-content: space-between;
  background-color: ${({ theme }) => theme.WHITE_1};
`;

const LeftSection = styled.div`
  padding-top: 1.25rem;
  width: 15.5rem;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  background-color: ${({ theme }) => theme.BLUE_5};

  @media ${DEVICE.laptopL} {
    width: 15rem;
    padding-top: 20px;
    padding-left: 10px;
  }
  @media ${DEVICE.laptop} {
    width: 12.5rem;
    padding-top: 20px;
    padding-left: 10px;
  }
`;
const LinkMenu = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;
const RightSection = styled.div`
  padding: .75rem;
  width: calc(100% - 15rem);
  display: flex;
  flex-direction: column;
  @media ${DEVICE.laptopL} {
    width: calc(100% - 15rem);
    padding: .75rem;
  }
  @media ${DEVICE.laptop} {
    width: calc(100% - 12.5rem);
  }
`;

const PageHeading = styled.h2`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.BLUE_2};
`;

const HeaderWrap = styled.div`
  display: flex;
  flex-wrap: "wrap";
  justify-content: space-between;
  align-items: center;
  width: "100%";
  padding-bottom: 1rem;
`;

const FilterSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export {
  Card,
  Container,
  Heading,
  Navlink,
  DashboardContainer,
  LeftSection,
  LinkMenu,
  RightSection,
  PageHeading,
  HeaderWrap,
  FilterSection,
};

export {};
