import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../_app";
import { EmployeeService } from "../../_service";
import { Notify, Utils } from "../../utils";
import { H3Heading, Heading } from "../appSettings/AppSettingsStyles";
import {
  CommonModal,
  CustomButton,
  CustomDateField,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
  Loader,
} from "../../components";
import { LoaderContainer } from "../../CommonStyles";
import { DisplayFlex, RecordSeletedText } from "../payslip/PayslipStyles";
import { TableFilterHeader, TablePaginationHeader } from "./component";
import { TableHeader } from "../../components/table";
import { TableRow } from "@mui/material";
import { IRASTable, IRASTableCol } from "../filing/CPFFilingStyles";
import { PulsingIcon, TableColumn } from "../users/styles";
import { Constant } from "../../config";
import CustomTooltip from "../../components/CustomTooltip";
import AnnualIncrementRow from "./AnnualIncrementRow";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";


const InfoTextContainer = () => {
  return (
    <div style={{
      padding: "1.5rem",
      // border: "1px solid #e0e0e0",
      borderRadius: "0.75rem",
      
      backgroundColor: "#f8f9fa",
      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
      width: "100%",
      border: '1px solid #333',
    }}>
      <h3 style={{
        marginBottom: "1rem",
        color: "#2c3e50",
        fontSize: "1.1rem",
        fontWeight: 600
      }}>Steps to process Annual Increment Exercise</h3>
      <ol style={{
        listStyle: "decimal",
        paddingLeft: "20px",
        fontFamily: "system-ui, -apple-system, sans-serif",
        lineHeight: "1.8",
        color: "#4a5568",
        fontSize: "0.95rem",
        margin: 0
      }}>
        <li>Wait from TellBoss request to prepare the annual increment exercise on<br></br> the Average figure given by the Boss</li>
        <li>
          Admin prepares the result -

          Calculate annual increment for all active employees


        </li>
        <li>When ready, admin clicks here to send the result to the TellBoss App</li>
        <li>Boss reviews and makes changes, then sends revised figures to Admin</li>
        <li>Admin revises the amounts accordingly and sends them to TellBoss for final approval</li>
        <li>Admin receives approval from Boss via the TellBoss App</li>
        <li>Admin prepares annual increments into the payroll month decided by the Boss</li>
      </ol>
    </div>
  )
}

const AnnualIncrement = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const date = new Date();

  const location = useLocation();
  const locationData = location.state;
  const isEdit = locationData?.isEdit;
  const isFinalized = locationData?.isFinalized;

  const user = useAppSelector((state) => state.user);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );
  const [year, setYear] = useState(
    currentPayrunDate?.split("-")[0] || date.getFullYear()
  );
  // const [month, setMonth] = useState<string>(
  //   `${parseInt(currentPayrunDate?.split("-")[1] || 0) || date.getMonth() + 1}`
  // );
  const [month, setMonth] = useState<string>(
    `${parseInt(currentPayrunDate?.split("-")[1] || 0) + 1 || date.getMonth() + 1}`
  );
  const [status, setStatus] = useState("1");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [incrementPercentage, setIncrementPercentage] = useState<any>(5.0);
  const [commonIncrementPercentage, setCommonIncrementPercentage] =
    useState<any>('5.00');
  const [employeeData, setEmployeeData] = useState<any[]>([]);
  const [filteredEmployeeData, setFilteredEmployeeData] = useState<any[]>([]);

  const [inputValueIncrementPercentage, setInputValueIncrementPercentage] =
    useState<any>({});

  const [workedFrom, setWorkedFrom] = useState<any>(
    `${date.getFullYear() - 1}-01-01`
  );
  const [workedTill, setWorkedTill] = useState<any>(
    `${date.getFullYear() - 1}-12-31`
  );


  // const monthsOptions = parseInt(year) > parseInt(currentPayrunDate?.split('-')[0]) ? Utils.MONTH_OPTIONS : Utils.MONTH_OPTIONS?.slice(parseInt(currentPayrunDate?.split('-')[1]));
  // const yearOptions = Utils.getDefaultYearRange();
  const yearOptions = getYearRange(currentPayrunDate?.split('-')[0]);

  const monthsOptions = Utils.MONTH_OPTIONS;

  const [pageInfo, setPageInfo] = useState<{
    pageNumber: number;
    totalLength: number;
    pagePerData: number;
  }>({
    pageNumber: 1,
    totalLength: employeeData?.length || 10,
    pagePerData: 10,
  });

  const [totalValues, setTotalValues] = useState<any>({
    totalSalary: 0,
    totalIncrementAmount: 0,
    averageIncrementPercentage: 0
  });


  // const getEmployeeList = async (type: string) => {
  //   const defaultCompanyId = user.defaultCompnayId;

  //   if (defaultCompanyId) {
  //     setIsLoading(() => true);
  //     try {
  //       const res = await EmployeeService._getEmployeePayrollListByCompany(
  //         defaultCompanyId,
  //         Utils.getLastDayOfMonth(year, month)
  //       );
  //       if (res.status === 200) {
  //         setEmployeeData(res?.data?.data?.emp_payslip);
  //       }
  //     } catch (e: any) {
  //       console.log("err", e);
  //     }
  //     setIsLoading(() => false);
  //   }
  // };

  const getEmployeeIncrement = async () => {
    const defaultCompanyId = user.defaultCompnayId;

    if (defaultCompanyId) {
      setIsLoading(() => true);
      try {
        const res = await EmployeeService._getInitialEmployeeAnnualIncrementData(
          defaultCompanyId,
          workedFrom,
          workedTill
        );
        if (res.status === 200) {
          let data = res?.data?.data;
          console.log("increment intial data", data);
          setEmployeeData(data);
        }
      } catch (e: any) {
        console.log("err", e);
      }
      setIsLoading(() => false);
    }
  };

  const getEmployeeIncrementEditData = async () => {
    const defaultCompanyId = user.defaultCompnayId;

    if (defaultCompanyId) {
      setIsLoading(() => true);
      try {
        const res = await EmployeeService._getEmployeeAnnualIncrementEditData(locationData?.id);

        if (res.status === 200) {
          let data = res?.data?.data;
          setEmployeeData(data?.increment_records);
          setYear(data?.increment?.year)
          setMonth(data?.increment?.effective_month)
          // setWorkedFrom(data?.increment?.period_from)
          setWorkedTill(data?.increment?.period_to)
          setCommonIncrementPercentage(data?.increment?.global_increment_percentage)
          setTotalValues({
            totalSalary: data?.increment?.total_salary,
            totalIncrementAmount: data?.increment?.total_increment,
            averageIncrementPercentage:
              data?.increment?.avg_increment_percentage,
          });
        }
      } catch (e: any) {
        console.log("err", e);
      }
      setIsLoading(() => false);
    }
  };

  useEffect(() => {
    if (isEdit || isFinalized) {
      getEmployeeIncrementEditData();
    } else {
      getEmployeeIncrement();
    }

  }, [workedFrom, workedTill]);

  // useEffect(()=> {
  //   setMonth(monthsOptions[0].value)
  // }, [year])


  // const handleIncrementPercentageChange = (id: any, text: any): any => {
  //   let index = employeeData?.findIndex((user) => user.employee === id);
  //   let data = [...employeeData];

  //   if (index >= 0) {
  //     data[index].incrementPercentage = parseFloat(text) || "";
  //     data[index].increment_percentage = parseFloat(text) || "";
  //     data[index].incrementAmount = Math.ceil(
  //       (parseFloat(text) / 100) *
  //         parseFloat(
  //           data[
  //             index
  //           ]?.salary?.replace(
  //             ",",
  //             ""
  //           )
  //         )
  //     ) || "";

  //     setEmployeeData(data);
  //   }
  //   console.log("updated", data);
  // };

  const updateTotal = (data: any) => {
    console.log("data inside updateTotal", data)
    let totalObj: any = {
      totalSalary: 0,
      totalIncrementAmount: 0,
      averageIncrementPercentage: 0
    };

    console.log('before cal1 total', totalObj)
    data.forEach((item: any) => {
      totalObj.totalSalary =
        totalObj.totalSalary +
        (parseFloat(`${item?.basic_salary}`?.replace(/,/g, "")) || 0);
      totalObj.totalIncrementAmount =
        totalObj.totalIncrementAmount + parseFloat(item?.increment_amount);
    });

    totalObj.averageIncrementPercentage = (
      ((totalObj?.totalIncrementAmount / totalObj?.totalSalary) * 100) || 0
    ).toFixed(2);

    console.log('total before update', totalObj)
    setTotalValues(totalObj);

  };

  const handleCommonIncrementPercentageChange = (): any => {

    if (parseFloat(commonIncrementPercentage) < 0) {
      Notify("Increment percentage cannot be negative!", 0, { autoClose: false });
      return null;
    }

    let data = cloneDeep(employeeData);

    // let totalObj: any = {
    //   totalSalary: 0,
    //   totalIncrementAmount: 0,
    // };

    data.forEach((item: any) => {
      // item.incrementPercentage = parseFloat(commonIncrementPercentage) || 0;
      // item.increment_percentage = parseFloat(commonIncrementPercentage) || 0;
      // item.incrementAmount =
      //   Math.ceil(
      //     (parseFloat(commonIncrementPercentage) / 100) *
      //       parseFloat(`${item.basic_salary}`?.replace(/,/g, ""))
      //   ) || 0;
      // item.increment_amount =
      //   Math.ceil(
      //     (parseFloat(commonIncrementPercentage) / 100) *
      //       parseFloat(`${item.basic_salary}`?.replace(/,/g, ""))
      //   ) || 0;

      // total calc
      // totalObj.totalSalary =
      //   totalObj.totalSalary +
      //   (parseFloat(item?.basic_salary?.replace(/,/g, "")) || 0);
      // totalObj.totalIncrementAmount =
      //   totalObj.totalIncrementAmount + Number(item?.incrementAmount);


      let joiningDateObj = moment(item?.date_joined);
      let daysWorked = moment(`${workedTill}`).diff(joiningDateObj, "days") + 1; // added one to inclue the joining date

      if (daysWorked < 365) {
        item.incrementPercentage =
          parseFloat(((daysWorked / 365) * (parseFloat(commonIncrementPercentage) || 0)).toFixed(2));
        item.increment_percentage = item.incrementPercentage;

        item.incrementAmount =
          Math.ceil(
            (parseFloat(item.incrementPercentage) / 100) *
            parseFloat(`${item.basic_salary}`?.replace(/,/g, ""))
          ) || 0;
        item.increment_amount = item.incrementAmount;
      } else {
        item.incrementPercentage = parseFloat(commonIncrementPercentage) || 0;
        item.increment_percentage = parseFloat(commonIncrementPercentage) || 0;
        item.incrementAmount =
          Math.ceil(
            (parseFloat(commonIncrementPercentage) / 100) *
            parseFloat(`${item.basic_salary}`?.replace(/,/g, ""))
          ) || 0;
        item.increment_amount =
          Math.ceil(
            (parseFloat(commonIncrementPercentage) / 100) *
            parseFloat(`${item.basic_salary}`?.replace(/,/g, ""))
          ) || 0;
      }

    });



    setEmployeeData(data);
    updateTotal(data);

    console.log("updated", data,);
  };

  // const handleIncrementAmountChange = (id: any, text: any): any => {
  //   let index = employeeData?.findIndex((user) => user.employee === id);
  //   let data = [...employeeData];

  //   if (index >= 0) {
  //     // data[index].incrementPercentage = parseFloat(text) || "";
  //     data[index].incrementAmount = parseFloat(text);
  //     data[index].increment_amount = parseFloat(text);

  //     data[index].incrementPercentage = (( parseFloat(text) / parseFloat(data[index].salary?.replace(/,/g, "")) ) || 0) * 100;
  //     data[index].incrementPercentage = data[index].incrementPercentage === Infinity ? 0 : data[index].incrementPercentage?.toFixed(2);

  //     data[index].increment_percentage = data[index].incrementPercentage;

  //     setEmployeeData(data);
  //   }
  //   console.log("updated", data);
  // };

  const handlePageValueChange = (
    type: "next" | "previous" | "first" | "last"
  ) => {
    if (type === "next") {
      const isLast =
        pageInfo.pageNumber * pageInfo.pagePerData < employeeData.length;
      if (isLast) {
        setPageInfo((prev) => ({
          ...prev,
          pageNumber: prev.pageNumber + 1,
        }));
      }
    }
    if (type === "previous") {
      if (pageInfo.pageNumber > 1) {
        setPageInfo((prev) => ({
          ...prev,
          pageNumber: prev.pageNumber - 1,
        }));
      }
    }
    if (type === "first") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: 1,
      }));
    }
    if (type === "last") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: Math.ceil(pageInfo.totalLength / pageInfo.pagePerData),
      }));
    }
  };

  const handleSave = async (isDraft: boolean, goBack: boolean = false) => {
    console.log('theData inside handleSave', employeeData, totalValues)
    setOpen(false);

    let payload: any = {
      increment: {},
      increment_records: [],
    }

    let data = cloneDeep(employeeData);

    payload.increment_records = data?.map((emp: any) => {
      let newEmp: any = {};
      Object.keys(emp).forEach((key: any) => {
        if (key === "incrementAmount") {
          newEmp.increment_amount = emp[key];
        } else if (key === "incrementPercentage") {
          newEmp.increment_percentage = emp[key];
        } else if (key === "basic_salary") {
          newEmp[key] = `${emp[key]}`?.replace(/,/g, "");
        }
        else {
          newEmp[key] = emp[key];
        }
      });
      return newEmp;
    });

    payload.increment = {
      "company": companyId,
      "year": year,
      "effective_month": month,
      // "period_from": workedFrom,
      "period_to": workedTill,
      "global_increment_percentage": commonIncrementPercentage,
      "status": isDraft ? "preview" : "finalized",
      "total_salary": totalValues?.totalSalary,
      "total_increment": totalValues?.totalIncrementAmount,
      "avg_increment_percentage": totalValues?.averageIncrementPercentage
    }

    if (isEdit) {
      payload.increment.id = locationData?.id
    }

    console.log("thePayload", payload)

    try {
      const { status } = isEdit ? await EmployeeService._updateAnnualIncrement(payload) : await EmployeeService._saveAnnualIncrement(payload);

      if (status >= 200 && status < 300) {
        Notify(isEdit ? "Annual Increment Updated" : "Annual Increment Saved", 1);

        if (goBack) {
          navigate(-1);
        }
      }
    } catch (error: any) {
      if (error?.response?.data?.paylsip) {
        Notify(error?.response?.data?.paylsip, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  };



  // Only when updating from row and isEdit=true
  const handleSaveRow = async (empData: any) => {
    console.log('handleSaveRow called')
    let totalObj: any = {
      totalSalary: 0,
      totalIncrementAmount: 0,
      averageIncrementPercentage: 0
    };

    empData.forEach((item: any) => {
      totalObj.totalSalary =
        totalObj.totalSalary +
        (parseFloat(`${item?.basic_salary}`?.replace(/,/g, "")) || 0);
      totalObj.totalIncrementAmount =
        totalObj.totalIncrementAmount + parseFloat(item?.increment_amount);
    });

    totalObj.averageIncrementPercentage = (
      ((totalObj?.totalIncrementAmount / totalObj?.totalSalary) * 100) || 0
    ).toFixed(2);

    // -----------

    let payload: any = {
      increment: {},
      increment_records: [],
    }

    payload.increment_records = empData?.map((emp: any) => {
      let newEmp: any = {};
      Object.keys(emp).forEach((key: any) => {
        if (key === "incrementAmount") {
          newEmp.increment_amount = emp[key];
        } else if (key === "incrementPercentage") {
          newEmp.increment_percentage = emp[key];
        } else if (key === "basic_salary") {
          newEmp[key] = `${emp[key]}`?.replace(/,/g, "");
        }
        else {
          newEmp[key] = emp[key];
        }
      });
      return newEmp;
    });

    payload.increment = {
      "company": companyId,
      "year": year,
      "effective_month": month,
      // "period_from": workedFrom,
      "period_to": workedTill,
      "global_increment_percentage": commonIncrementPercentage,
      "status": "preview",
      "total_salary": totalObj?.totalSalary,
      "total_increment": totalObj?.totalIncrementAmount,
      "avg_increment_percentage": totalObj?.averageIncrementPercentage
    }

    if (isEdit) {
      payload.increment.id = locationData?.id
    }

    console.log("thePayload-RowUpdate", payload)

    try {
      const { status } = await EmployeeService._updateAnnualIncrement(payload);
      if (status >= 200 && status < 300) {
        Notify("Annual Increment Updated", 1);
      }
    } catch (error: any) {
      if (error?.response?.data?.paylsip) {
        Notify(error?.response?.data?.paylsip, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }

  }

  const handleRowUpdate = (empId: any, amount: any) => {
    console.log('inside onUpdate', empId, amount)
    let index = employeeData?.findIndex((user) => user.employee === empId);
    let data = cloneDeep(employeeData)

    if (index >= 0) {
      data[index].incrementAmount = parseFloat(amount);
      data[index].increment_amount = parseFloat(amount);

      data[index].incrementPercentage = ((parseFloat(amount) / parseFloat(`${data[index]?.basic_salary}`?.replace(/,/g, ""))) || 0) * 100;
      data[index].incrementPercentage = data[index]?.incrementPercentage === Infinity ? 0 : parseFloat(data[index]?.incrementPercentage).toFixed(2);

      data[index].increment_percentage = data[index].incrementPercentage;

      setEmployeeData(data);
      updateTotal(data);
    }

    if (isEdit) {
      handleSaveRow(data);
    }

  }

  // page change
  useEffect(() => {
    const startIndex =
      pageInfo.pageNumber * pageInfo.pagePerData - pageInfo.pagePerData;
    const endIndex =
      pageInfo.pageNumber * pageInfo.pagePerData > employeeData.length
        ? employeeData.length
        : pageInfo.pageNumber * pageInfo.pagePerData;

    setPageInfo((prev) => ({
      ...prev,
      totalLength: employeeData?.length,
    }));


    setFilteredEmployeeData(() => employeeData.slice(startIndex, endIndex));
  }, [pageInfo.pageNumber, employeeData]);


  {
    // console.log("theData", employeeData, locationData, isEdit, totalValues);
    console.log("theData", employeeData, filteredEmployeeData);
    console.log("currentPayrunDate", { currentPayrunDate, month, year, monthsOptions })
  }

  return (
    <div style={{
      pointerEvents: isFinalized ? 'none' : 'auto'
    }}>
      <div style={{
        display:"flex",
        alignItems:"center",
        gap:".7rem"
      }}>
        <Heading>{"Annual Increment"}</Heading>
        <CustomTooltip title={<InfoTextContainer />} placement={'right'}>
          <PulsingIcon>
            <img src="/info-outline.svg" style={{ width: '25px', height: '25px' }} />
          </PulsingIcon>
        </CustomTooltip>
      </div>
      {/* {isLoading && <Loader />} */}
      {/* <hr/> */}
      <div style={{
        display: "flex",
        justifyContent: "space-between"
      }}>

        <H3Heading style={{
          width: "50%"
        }}>{user.defaultCompany}</H3Heading>



      </div>
      <div style={{
        display: 'flex',
        gap: "5px"
      }}>

        <div
          style={{
            // padding: "1.5rem",
            paddingLeft: "1rem",
            paddingTop: ".5rem",
            margin: "1rem 1.75rem 1rem auto",
            borderRadius: "0.75rem",
            backgroundColor: "#f8f9fa",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
            border: "1px solid #333",
            width: "50%",
            
          }}
        >
          <div style={{ width: "50%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1.5rem",
                justifyItems: "flex-start",
                width: "fit-content",
              }}
            >
              <span
                style={{
                  // margin: "1rem 0",
                  marginTop: "1rem",
                  color: "rgb(71, 101, 117)",
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                }}
              >
                {`For Employees Who Worked till `}
              </span>
              <CustomDateField
                date={workedTill}
                setDate={setWorkedTill}
                startYear={new Date().getFullYear() - 3}
                endYear={new Date().getFullYear() + 6}
                openAsModal={false}
                disabled={isEdit === true || isFinalized === true}
              />
            </div>

            <div
              style={{
                display: "flex",
                gap: 20,
                width: "100%",
                alignItems: "center",
                whiteSpace: "nowrap",
                marginTop : ".8rem"

              }}
            >
              <p style={{ color: "#333" }}>Annual Increment for the year:</p>
              <div>
                <CustomSelectField
                  value={year}
                  onChangeOption={(value) => setYear(value)}
                  option={yearOptions.map((year) => ({
                    key: year,
                    value: year,
                  }))}
                  disabled={isLoading}
                  isDate={true}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: 20,
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop : ".8rem"

              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 20, whiteSpace: "nowrap" }}>
                <p style={{ color: "#333" }}>
                  Salary Increment effective month from:
                </p>
                <div>
                  <CustomSelectField
                    value={month}
                    onChangeOption={(value) => setMonth(value)}
                    option={monthsOptions.map((month) => ({
                      key: month.key,
                      value: month.value,
                    }))}
                    disabled={isLoading}
                    isDate={true}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: 20,
                width: "70%",
                alignItems: "center",
                whiteSpace: "nowrap",
                marginTop : ".8rem"

              }}
            >
              <p style={{ color: "#333" }}>Enter Increment Percentage ( % ):</p>
              <div>
                <CustomNumberField
                  value={commonIncrementPercentage}
                  removeNumIcon={false}
                  onChangeText={(text) => {
                    setCommonIncrementPercentage(text);
                  }}
                  onBlur={() => {
                    if (parseFloat(commonIncrementPercentage) < 0) {
                      Notify("Increment percentage cannot be negative!", 0, {
                        autoClose: false,
                      });
                    } else {
                      setCommonIncrementPercentage(
                        `${(parseFloat(commonIncrementPercentage) || 0).toFixed(2)}`
                      );
                    }
                  }}
                  width="100px"
                  disabled={isFinalized}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 20,
                marginTop: "1rem",
                width: "100%",
                alignItems: "center",
                color: "rgb(51, 51, 51)",
              }}
            >
              <p style={{ textTransform: "capitalize", whiteSpace: "nowrap" }}>
                Calculate Annual Increment for all active employees:{" "}
              </p>
              <CustomTooltip title={"Click to Start Calculating"} placement={"right"}>
                <img
                  onClick={handleCommonIncrementPercentageChange}
                  style={{ width: "2rem", cursor: "pointer" }}
                  src="/refresh_blue.svg"
                />
              </CustomTooltip>
            </div>

            {!isFinalized && (
              <CustomButton
                title="Ready to send these final records to pay month?"
                onClick={() => setOpen(true)}
                styles={{
                  marginRight: "2rem",
                  width: "fit-content",
                  marginLeft: "0px",
                  marginTop: "1rem",
                  whiteSpace: "nowrap",

                }}
              />
            )}
          </div>
        </div>

        <div style={{
          padding: "1.5rem",
          // border: "1px solid #e0e0e0",
          borderRadius: "0.75rem",
          margin: '1rem 0',
          marginLeft: 'auto',
          marginRight: "1.75rem",
          backgroundColor: "#f8f9fa",
          boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
          width: "45%",
          border: '1px solid #333',
        }}>
          <h3 style={{
            marginBottom: "1rem",
            color: "#2c3e50",
            fontSize: "1.1rem",
            fontWeight: 600
          }}>Steps to process Annual Increment Exercise</h3>
          <ol style={{
            listStyle: "decimal",
            paddingLeft: "20px",
            fontFamily: "system-ui, -apple-system, sans-serif",
            lineHeight: "1.8",
            color: "#4a5568",
            fontSize: "1rem",
            margin: 0
          }}>
            <li>Wait from TellBoss request to prepare the annual increment exercise on ( normal about 4% - 8% ) the Average figure given by the Boss</li>
            <li>
              Admin prepares the result -
              {/* <p style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                margin: "4px 0",
                padding: "4px 8px",
                backgroundColor: "#edf2f7",
                borderRadius: "4px",
                fontSize: "1rem",
                color: "#718096",
                fontWeight: 500,
                fontStyle: "italic",
              }}> */}
                <>Calculate annual increment for all active employees</>
                {/* <CustomTooltip title={"Click to Start Calculating"} placement={"right"}>
                  <img
                    onClick={handleCommonIncrementPercentageChange}
                    style={{ width: "1.5rem", cursor: "pointer" }}
                    src="/refresh_blue.svg"
                  />
                </CustomTooltip>
              </p> */}
            </li>
            <li>When ready, admin clicks here to send the result to the TellBoss App</li>
            <li>Boss reviews and makes changes, then sends revised figures to Admin</li>
            <li>Admin changes the amounts accordingly and sends them to TellBoss for final approval</li>
            <li>Admin receives approval from Boss via the TellBoss App</li>
            <li>Admin prepares annual increments into the payroll month decided by the Boss</li>
          </ol>
        </div>

      </div>

      {/* <div
        style={{
          display: "flex",
          gap: 20,
          width: "70%",
          alignItems: "center",
        }}
      ></div> */}

      {/* For total table */}
      <table
        style={{ width: "100%", marginTop: "1rem" }}
        className="company-table"
      >
        <thead className="company-table-head" style={{ textAlign: "right" }}>
          <tr>
            <th style={{ width: "28%", textAlign: "left" }}>Total Employees</th>
            {/* <th  style={{ width: "25%", textAlign: "center",  }}>Total:</th> */}
            <th style={{ width: "36%", textAlign: "right" }}>Total Salary</th>
            <th style={{ width: "12%", textAlign: "right" }}>
              Total Increments
            </th>
            <th style={{ width: "12%", textAlign: "right" }}>
              Average Increment %
            </th>
            <th style={{ width: "12%", textAlign: "left" }}>
              {""}
            </th>
          </tr>
        </thead>
        <tr>
          {/* <td style={{textAlign: 'center'}}>{"   "}</td> */}
          <td style={{ width: "28%", textAlign: "left" }}>

            {employeeData?.length}

          </td>
          <td style={{ width: "36%", textAlign: "right" }}>
            {Utils.getCommanSepartedNumericStringWithDollar(totalValues?.totalSalary || 0)}
          </td>
          <td style={{ width: "12%", textAlign: "right" }}>
            {Utils.getCommanSepartedNumericStringWithDollar(totalValues?.totalIncrementAmount || 0)}
          </td>
          <td style={{ width: "12%", textAlign: "right" }}>
            {/* {(
              (totalValues?.totalIncrementAmount / totalValues?.totalSalary) *
                100 || 0
            ).toFixed(2)} */}
            {totalValues?.averageIncrementPercentage || 0.00.toFixed(2)}
            %
          </td>
          <td style={{ width: "12%", textAlign: "right" }}>{""}</td>
        </tr>
      </table>

      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <section id="report-table" style={{ marginTop: 20 }}>
            <>
              <div style={{ width: "100%" }}>
                <TableFilterHeader
                  name="Employees"
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                  handlePageValueChange={(val) => handlePageValueChange(val)}
                />

                <div className="table">
                  <table className="company-table">
                    <thead className="company-table-head">
                      <tr>
                        <th style={{ width: "28%", textAlign: "left" }}>Employee Name</th>
                        <th style={{ width: "12%", textAlign: "left" }}>Date Joined</th>
                        <th style={{ width: "12%", textAlign: "left" }}>Employee ID</th>
                        <th style={{ width: "12%", textAlign: "right" }}>Basic Salary</th>
                        <th style={{ width: "12%", textAlign: "right" }}>Increment Amount</th>
                        <th style={{ width: "12%", textAlign: "right" }}> Increment Percentage (%) </th>

                        {!isFinalized && <th style={{ textAlign: "center", width: "15%" }}>
                          Action
                        </th>}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredEmployeeData?.length > 0 ? (
                        filteredEmployeeData?.map((item, index) => (
                          <AnnualIncrementRow
                            key={item?.employee}
                            data={item}
                            onUpdate={handleRowUpdate}
                            isEdit={isEdit}
                            isFinalized={isFinalized}
                          />
                        ))
                      ) : (
                        <p style={{ textAlign: "center", margin: "2rem" }}>
                          No records found
                        </p>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          </section>
        </>
      )}

      <CommonModal
        handleClose={() => setOpen(false)}
        open={open}
        // loading={loading}
        updateData={() => handleSave(true, true)}
        body={`When you click “Save Draft”, the system will save Annual Increment as draft for later confirmations. The system will also create a new "pay item - increment” record for every employee in the list. 
Be careful before you click “CONFIRM” button, as you won't be able to edit Annual Increment after confirmation.
`}
        heading="Ready to send Payroll Master Records?"
        conformButtonText="Save Draft"
        cancelButtonText="Confirm"
        onCancel={() => handleSave(false, true)}

      />
    </div>
  );
};

export default AnnualIncrement;

const getYearRange = (year: string) => {
  const startYear = parseInt(year);
  const yearsRange = [startYear];

  for (let i = 1; i < 10; i++) {
    yearsRange.push(startYear + i);
  }
  console.log("inside yearRange", { startYear, yearsRange });
  return yearsRange;
};
