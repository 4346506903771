import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Notify, Utils } from "../../utils";
import { CompanyService, UserService } from "../../_service";
import {
  AddCompanyModal,
  CommonModal,
  CompanyDeleteConformModal,
  CompanyInfoModal,
  EditCompanyModal,
  Loader,
  Tooltip,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../_app";
import { updateUserIntialState, updateAppSettingId } from "../../_features";
import { HeaderWrap, PageHeading } from "./Styles";
import { Constant } from "../../config";
import {
  FilterByChar,
  FilterBySelect,
  SearchField,
  TableFilterHeader,
} from "./component";
import styled from "styled-components";
import { CustomButton } from "../../components/customButton";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { GrUpdate } from "react-icons/gr";
import moment from "moment";

interface CompanyDataProps {
  id: string;
  name: string;
  reg_id: string;
  address: string;
  archived: boolean;
  permission: string;
  current_payrun_date: any;
  payrun_start_date: any;
}

const ManageCompany = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const hasAddEditAccess = useMemo(() => 
    Utils.checkAccess("manage_company", "default-manage_company", "add/edit"), 
    [] // Empty dependency array since these values never change
  );

  const user = useAppSelector((state) => state.user);
  const [comanies, setComanies] = useState<CompanyDataProps[]>([]);
  const [filterCompanyList, setfilterCompanyList] = useState<
    CompanyDataProps[]
  >([]);
  const [text, setText] = useState("");
  const [char, setChar] = useState("");
  const [status, setStatus] = useState("1");
  const [pageInfo, setPageInfo] = useState<{
    pageNumber: number;
    totalLength: number;
    pagePerData: number;
  }>({
    pageNumber: 1,
    totalLength: 10,
    pagePerData: 10,
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [openModla, setOpenModla] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [confirmActivationModal, setConfirmActivationModal] = useState(false);
  // const [companyId, setCompanyId] = useState("");
  const [companyData, setCompanyData] = useState<CompanyDataProps>({
    name: "",
    address: "",
    reg_id: "",
    id: "",
    archived: false,
    permission: "full",
    current_payrun_date: "",
    payrun_start_date: "",
  });
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (text === "") {
      setfilterCompanyList(comanies);
    } else {
      setfilterCompanyList(() => {
        return comanies.filter((item) => {
          if (
            JSON.stringify(item.id)
              .toLowerCase()
              .includes(text.toLowerCase()) ||
            item.name.toLowerCase().includes(text.toLowerCase()) ||
            item.name.toLowerCase().includes(text.toLowerCase())
          ) {
            return true;
          }
          return false;
        });
      });
    }
  }, [comanies, text]);

  // page change
  useEffect(() => {
    const startIndex =
      pageInfo.pageNumber * pageInfo.pagePerData - pageInfo.pagePerData;
    const endIndex =
      pageInfo.pageNumber * pageInfo.pagePerData > comanies.length
        ? comanies.length
        : pageInfo.pageNumber * pageInfo.pagePerData;
    setfilterCompanyList(() => comanies.slice(startIndex, endIndex));
  }, [pageInfo.pageNumber, comanies]);

  const handleCloseModal = () => {
    setOpenModla(false);
  };

  const get = async (type: any) => {
    if (type !== "0") {
      setIsFiltered(() => true);
    } else {
      setIsFiltered(() => false);
    }
    try {
      setLoading(() => true);
      const data = await CompanyService._getAllComanies(type);
      setComanies(() => data.data);
      setChar("All");
      setPageInfo((prev) => ({
        ...prev,
        totalLength: data?.data?.length,
      }));
      setLoading(() => false);
      if (!user.defaultCompany || user.defaultCompany === "undefined") {
        dispatch(
          updateUserIntialState({
            ...user,
            defaultCompany: data.data[0].name,
            defaultCompnayId: data.data[0].id,
            actualDefaultCompanyId: data.data[0].id,
            permission: data.data[0].permission,
            current_payrun_date:
              data.data[0].current_payrun_date ||
              data.data[0].payrun_start_date,
          })
        );
      } else if (user.defaultCompany && user.permission === "none") {
        const firstCompanyWithSomeAccess = data.data.find(
          (companyData: any) => companyData["permission"] !== "none"
        );
        dispatch(
          updateUserIntialState({
            ...user,
            defaultCompany: firstCompanyWithSomeAccess.name,
            defaultCompnayId: firstCompanyWithSomeAccess.id,
            actualDefaultCompanyId: firstCompanyWithSomeAccess.id,
            permission: firstCompanyWithSomeAccess.permission,
            current_payrun_date:
              firstCompanyWithSomeAccess.current_payrun_date ||
              firstCompanyWithSomeAccess.payrun_start_date,
          })
        );
      }
      if (
        user?.groups?.some(
          (userGroup: any) => userGroup.name.toLowerCase() === "employees"
        )
      ) {
        getEmployeeIdFromCompany(data.data[0].id);
      }
    } catch (e: any) {
      setLoading(() => false);
      console.error(e);
    }
  };

  const handlePageValueChange = (
    type: "next" | "previous" | "first" | "last"
  ) => {
    if (type === "next") {
      const isLast =
        pageInfo.pageNumber * pageInfo.pagePerData < comanies.length;
      if (isLast) {
        setPageInfo((prev) => ({
          ...prev,
          pageNumber: prev.pageNumber + 1,
        }));
      }
    }
    if (type === "previous") {
      if (pageInfo.pageNumber > 1) {
        setPageInfo((prev) => ({
          ...prev,
          pageNumber: prev.pageNumber - 1,
        }));
      }
    }
    if (type === "first") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: 1,
      }));
    }
    if (type === "last") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: Math.ceil(pageInfo.totalLength / pageInfo.pagePerData),
      }));
    }
  };
  const getCompanyByChar = async (val: string) => {
    setIsFiltered(true);
    try {
      setLoading(true);
      const data = await CompanyService._getAllCompanyByChar(val);
      setComanies(() => data.data);
      setPageInfo((prev) => ({
        ...prev,
        totalLength: data?.data?.length,
      }));

      setLoading(() => false);
    } catch (e: any) {
      setLoading(() => false);
      console.error(e);
    }
  };

  const handleDataUpdateByChar = async (props: string) => {
    setIsFiltered(true);
    setChar(props);
    if (props === "All") {
      get(status);
    } else {
      getCompanyByChar(props);
    }
  };

  const handleDeleteComapany = (data: CompanyDataProps) => {
    setCompanyData(() => data);
    setDeleteModal(true);
  };

  const activateCompany = async (companyId: any) => {
    setLoading(true);
    try {
      const res = await CompanyService._activateCompany(companyId);
      setLoading(false);
      if(res?.status >= 200 && res?.status < 300) {
        Notify("Company Activated Successfully", 1);
        setConfirmActivationModal(false);
        setStatus("1")
      }
      
    } catch (e: any) {
      console.error(e)
      Notify(e?.response?.data?.error[0], 0);
      setLoading(false);
    }
  };

  const handleActiveInactive = (data: CompanyDataProps)=> {
    setCompanyData(data);
    if (data?.archived === true ){
      console.log('activate this', data?.id)
      // activateCompany(data?.id);
      setConfirmActivationModal(true)
    } else {
      navigate("/dashboard/manage-company/deactivate", {
        state : {
          data: data,
        }
      })
    }
  }

  const getEmployeeIdFromCompany = async (companyId: string) => {
    const { status, data } = await UserService._getEmployeeIdFromCompany(
      companyId
    );
    if (status === 200) {
      dispatch(
        updateUserIntialState({
          employee_id: data.data.employee_id,
        })
      );
    }
  };

  const handleMakeDefaultCompany = async (id: string) => {
    try {
      setLoading((p) => !p);
      const res = await CompanyService._updateDefaultCompany({
        default_company_id: id,
      });
      setLoading((p) => !p);
      if (res.status === 201) {
        Notify("Set Default Company Successfully", 1);
        const defaultCompany = await CompanyService._getDefaultCompany();
        if (defaultCompany.status === 200) {
          const name = defaultCompany.data.default_company_info.name;
          const id = defaultCompany.data.default_company;
          const permission =
            defaultCompany.data.default_company?.access || "full";
          const currentPayrunDate =
            defaultCompany?.data?.default_company_info?.current_payrun_date ||
            defaultCompany?.data?.default_company_info?.payrun_start_date;
          dispatch(
            updateUserIntialState({
              ...user,
              defaultCompany: name,
              defaultCompnayId: id,
              actualDefaultCompanyId: id,
              permission: permission,
              current_payrun_date: currentPayrunDate,
            })
          );
        }
      } else {
        Notify("Set Default Company Failed", 0);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  useEffect(() => {
    get(status);
  }, [status]);

  const gotoAddCompany = (id = "") => {
    dispatch(updateAppSettingId(""));
    navigate("/dashboard/app-settings/employer-details");
  };

  /**
   * Function to Change the default company to the clicked company
   *
   * @param id Id of the Company
   *
   * @param name Name of the Company
   */
  const changeDefaultCompanyForSession = async (
    id: string,
    name: string,
    isArchived: boolean,
    permission: string = "full",
    currentPayrunDate: any
  ) => {
    localStorage.removeItem("payrollPreview");
    if (!isArchived) {
      dispatch(
        updateUserIntialState({
          defaultCompany: name,
          defaultCompnayId: id,
          permission: permission,
          current_payrun_date: currentPayrunDate,
        })
      );
    }
    if (
      !user?.groups?.some(
        (userGroup: any) => userGroup.name.toLowerCase() === "employees"
      )
    ) {
      navigate("/dashboard/employee-payroll");
    } else {
      Notify("Default Company Set Successfully", 1);
      getEmployeeIdFromCompany(id);
    }
  };

  const editCompany = (id: string, name: string): void => {
    dispatch(
      updateUserIntialState({
        defaultCompany: name,
        defaultCompnayId: id,
      })
    );
    dispatch(updateAppSettingId(id));
    navigate("/dashboard/app-settings/employer-details");
  };

  const getLatestPayrollDate = (
    payrunStartDate: any,
    currentPayrunDate: any
  ) => {
    if (!payrunStartDate || !currentPayrunDate) {
      return "";
    }

    let oneMonthLessDate = moment(currentPayrunDate)
      .subtract(1, "months")
      .format("YYYY-MM-DD");

    let diffInMonths = moment(oneMonthLessDate).diff(
      moment(payrunStartDate),
      "month"
    );

    // less than zero means the currentPayrun month and payrunStart month are the same
    if (diffInMonths >= 0) {
      return moment(currentPayrunDate)
        .subtract(1, "months")
        .format("MMMM YYYY");
    } else {
      return "";
    }
  };



  return (
    <div
      style={{
        maxHeight: "100vh",
        overflow: "scroll",
        position: "relative",
      }}
    >
      {loading && <Loader />}

      <HeaderWrap>
        <AddCompanyModal
          handleClose={handleCloseModal}
          open={openModla}
          updateData={() => get(status)}
        />
        <CompanyDeleteConformModal
          open={deleteModal}
          handleClose={() => setDeleteModal(false)}
          data={companyData}
          updateData={() => get(status)}
        />
          <CommonModal
            handleClose={()=>setConfirmActivationModal(false)}
            open={confirmActivationModal}
            loading={loading}
            updateData={()=>activateCompany(companyData?.id)}
            body={`Do you want to make this company Live ${companyData.name}`}
            heading="To make Company Live"
            conformButtonText="Yes, Make it Live"
            cancelButtonText="Stay Inactive"
           />
        <EditCompanyModal
          open={editModal}
          handleClose={() => setEditModal(false)}
          data={companyData}
          updateData={() => get(status)}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {!loading &&
          comanies &&
          comanies?.length < 1 &&
          (char === "" || char === "All") &&
          !isFiltered ? (
            <CompanyInfoModal />
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <PageHeading>{Constant.company.companies}</PageHeading>
              <p
                style={{
                  marginTop: "8px",
                  marginRight: "16px",
                }}
              >
                {Constant.company.sub_title}
              </p>
            </div>
            {/* {(user.is_superuser || (user.permission === "full" &&
              user?.groups?.some(
                (userGroup: any) => !['employee', 'cso', 'data entry'].includes(userGroup.name.toLowerCase())
              ))) && 
              (
                <CustomButton
                  title={Constant.company.add_company}
                  type="secondary"
                  onClick={gotoAddCompany}
                />
              )} */}

            {(user.is_superuser || hasAddEditAccess) && 
              (
                <CustomButton
                  title={Constant.company.add_company}
                  type="secondary"
                  onClick={gotoAddCompany}
                />
              )}
          </div>
        </div>
      </HeaderWrap>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <FilterBySelect value={status} onChageOption={setStatus} />
        <SearchField value={text} onChangeText={setText} />
      </div>
      <FilterByChar value={char} onChange={handleDataUpdateByChar} />
      <div className="table">
        {filterCompanyList?.length > 0 && (
          <TableFilterHeader
            name="Companies"
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            handlePageValueChange={(val) => handlePageValueChange(val)}
          />
        )}
        <table className="company-table">
          <thead className="company-table-head">
            <tr>
              <th>{Constant.common.name}</th>
              <th>{'Latest Payroll Completed'}</th>
              <th>Status</th>
              <th>{Constant.common.address}</th>
              <Th>{Constant.common.action}</Th>
            </tr>
          </thead>
          <tbody>
            {filterCompanyList?.map((item) => {
              return (
                item?.permission !== "none" && (
                  <tr
                    key={item.reg_id + item.name}
                    style={{
                      backgroundColor:
                        item.id === user.actualDefaultCompanyId
                          ? "lightgreen"
                          : "",
                    }}
                  >
                    <Name
                      onClick={() =>
                        changeDefaultCompanyForSession(
                          item.id,
                          item.name,
                          item.archived,
                          item.permission,
                          item?.current_payrun_date || item?.payrun_start_date
                        )
                      }
                    >
                      {item.name}
                    </Name>
                    <LatestDate>
                      {getLatestPayrollDate(
                        item?.payrun_start_date,
                        item?.current_payrun_date
                      )}
                    </LatestDate>
                    <td
                      style={{ color: item?.archived ? "#dc3545" : "#28a745" }}
                    >
                      {item?.archived ? "Archived" : "Live"}
                    </td>
                    <Address
                      onClick={() =>
                        changeDefaultCompanyForSession(
                          item.id,
                          item.name,
                          item.archived,
                          item.permission,
                          item?.current_payrun_date || item?.payrun_start_date
                        )
                      }
                    >
                      {item.address}
                    </Address>
                    <Td>
                      <div
                        style={{
                          display: "flex",
                          // flexWrap: "wrap",
                          justifyContent: "start",
                          alignItems: "center",
                          gap:  "0.5rem",
                        }}
                      >
                        {user.permission === "full" &&
                          !user?.groups?.some(
                            (userGroup: any) =>
                              userGroup.name.toLowerCase() === "employees"
                          ) && (
                            // <Tooltip text="Edit">
                            //   <CustomButton
                            //     type={"secondary"}
                            //     size="small"
                            //     width="30px"
                            //     title={""}
                            //     onClick={() => {
                            //       editCompany(item.id, item.name);
                            //     }}
                            //     icon={<AiFillEdit />}
                            //   />
                            // </Tooltip>
                            <p
                              style={{
                                padding: "0.25rem 0.75rem",
                                background: "#059BBF",
                                cursor: "pointer",
                                color: "white",
                                fontWeight: 400,
                                borderRadius: "0.25rem",
                              }}
                              onClick={() => {
                                editCompany(item.id, item.name);
                              }}
                            >
                              {"Edit"}
                            </p>
                          )}

                        {item.id !== user.actualDefaultCompanyId &&
                          user.permission === "full" &&
                          !user?.groups?.some(
                            (userGroup: any) =>
                              userGroup.name.toLowerCase() === "employees"
                          ) && (
                            // <Tooltip text="Delete" del>
                            //   <CustomButton
                            //     type={"secondary"}
                            //     size="small"
                            //     del
                            //     width="30px"
                            //     title={""}
                            //     onClick={() => handleDeleteComapany(item)}
                            //     icon={<AiFillDelete />}
                            //   />
                            // </Tooltip>
                            <p
                              style={{
                                padding: "0.25rem 0.75rem",
                                background: item?.archived ? "#03B381" : "#ff6665",
                                cursor: "pointer",
                                color: "white",
                                fontWeight: 400,
                                borderRadius: "0.25rem",
                                whiteSpace : "nowrap"
                              }}
                              onClick={() => handleActiveInactive(item)}
                            >
                              {item?.archived ? "Make it Live" : "Deactivate"}
                            </p>
                          )}
                        {!item.archived && (
                          <Tooltip text="Make Default" big>
                            <CustomButton
                              type={
                                item.id === user.actualDefaultCompanyId
                                  ? "secondary"
                                  : "primary"
                              }
                              size="small"
                              width="30px"
                              title={""}
                              onClick={() => handleMakeDefaultCompany(item.id)}
                              icon={<GrUpdate />}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </Td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
        <div style={{ textAlign: "center", padding: ".5rem" }}>
          {filterCompanyList?.length < 1 && <p>No records found</p>}
        </div>
      </div>
    </div>
  );
};

export default ManageCompany;

const Name = styled.td`
  width: 30%;
  word-wrap: break-word;
  font-weight: bold;
  cursor: pointer;
`;

const LatestDate = styled.td`
  width: 13%;
  text-align: left;
`;

const Address = styled.td`
  width: 45%;
  word-wrap: break-word;
  cursor: pointer;
`;

const Td = styled.td`
  width: 8%;
`;

const Th = styled.th`
  width: 13%;
  text-align: center;
`;
