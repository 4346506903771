import { useEffect, useState } from "react";
import { AuthHeader } from "../../components/header";
import { Button, Message, TextInput } from "../../components";
import { Link, PasswordContainer, PasswordHeader } from "./AuthStyles";
import { useAppDispatch, useAppSelector } from "../../_app";
import { updateMessage } from "../../_features";
import { AuthService } from "../../_service";
import { useNavigate, useParams } from "react-router-dom";
import { Constant } from "../../config";
import { Utils } from "../../utils";
import { FaEye, FaEyeSlash } from "react-icons/fa"; 

const CreatePassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState(true);
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const messageData = useAppSelector((state) => state.message.createPassword);

  const resetMessages = () => {
    dispatch(
      updateMessage({
        message: "",
        page: "createPassword",
        type: "error",
      })
    );
  };

  const handleCreateNewPassword = async () => {
    try {
      if (password === "") {
        dispatch(
          updateMessage({
            message: "Password can not be empty",
            page: "createPassword",
            type: "error",
          })
        );
        return;
      }
      if (password !== confirmPassword) {
        dispatch(
          updateMessage({
            message: "Confirm Password does not match",
            page: "createPassword",
            type: "error",
          })
        );
        return;
      }
      resetMessages();
      await AuthService._changePassword(param["*"] || "", password);
      navigate("/");
      dispatch(
        updateMessage({
          message: "Password updated successfully",
          page: "login",
          type: "success",
        })
      );
    } catch (e: any) {
      dispatch(
        updateMessage({
          message:
            e?.response?.data?.password[0] ||
            Constant.common.something_went_wrong,
          page: "createPassword",
          type: "error",
        })
      );
    }
  };

  const checkToken = () => {
    try {
      AuthService._validateToken(param["*"] || "").then();
      setToken(true);
    } catch (e: any) {
      setToken(false);
    }
  };

  useEffect(() => {
    Utils.resetStoreData();
    checkToken();
  }, []);

  return token ? (
    <div>
      <AuthHeader />
      <PasswordContainer
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
        }}
      >
        <div
          style={{
            width: "500px",
            borderRadius: ".3rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
          }}
        >
          <PasswordHeader>
            {Constant.createPassword.create_new_password}
          </PasswordHeader>
          <div
            style={{
              padding: "1rem",
              border: "1px solid #dee2e6",
              position: "relative",
            }}
          >
            <Message close={resetMessages} data={messageData} />
            <p>{Constant.createPassword.enter_your_password}</p>

            <div style={{ position: "relative" }}>
              <TextInput
                labal={Constant.createPassword.new_password}
                type={showPassword ? "text" : "password"}
                placeholder={Constant.createPassword.enter_new_password}
                value={password}
                onChange={setPassword}
                style={{ textTransform: "none" }}
              />
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "70%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <div style={{ position: "relative", marginTop: "1rem" }}>
              <TextInput
                labal={Constant.createPassword.conform_your_new_password}
                type={showConfirmPassword ? "text" : "password"}
                placeholder={
                  Constant.createPassword.enter_new_password_again_to_confirm
                }
                value={confirmPassword}
                onChange={setConfirmPassword}
                style={{ textTransform: "none" }}
              />
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "70%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setShowConfirmPassword((prev) => !prev)}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <Button
              title={Constant.createPassword.update_new_password}
              onClick={handleCreateNewPassword}
            />
          </div>
          <div
            style={{
              padding: "2rem 0 1rem 0",
              border: "1px solid #dee2e6",
              position: "relative",
            }}
          >
            <Link
              to="https://tellboss.co/"
              style={{
                textAlign: "center",
                width: "100%",
                display: "block",
              }}
            >
              {Constant.common.back_to_home_page}
            </Link>
          </div>
        </div>
      </PasswordContainer>
    </div>
  ) : (
    <div>
      <AuthHeader />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
        }}
      >
        <div
          style={{
            width: "500px",
            padding: "1rem 1rem",
          }}
        >
          <PasswordHeader>
            {Constant.createPassword.create_new_password}
          </PasswordHeader>
          <div
            style={{
              padding: "1rem",
              border: "1px solid #dee2e6",
              position: "relative",
            }}
          >
            <p style={{ marginBottom: "5rem", textAlign: "center" }}>
              {
                Constant.createPassword
                  .token_is_not_valid_please_resend_the_email
              }
            </p>

            <Button
              title={Constant.createPassword.back_to_reset_password}
              onClick={() => {
                navigate("/password-resets");
              }}
            />
          </div>
          <div
            style={{
              padding: "2rem 0 1rem 0",
              border: "1px solid #dee2e6",
              position: "relative",
            }}
          >
            <Link
              to="https://tellboss.co/"
              style={{
                textAlign: "center",
                width: "100%",
                display: "block",
              }}
            >
              {Constant.common.back_to_home_page}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
