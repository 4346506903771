import { useAppSelector } from "../../../_app";
import { ReportService } from "../../../_service/ReportService";
import { Constant } from "../../../config";
import { Notify, Utils } from "../../../utils";
import GeneralReportFilterPage from "../GeneralReportFilterPage";
import XLSX from "xlsx";
import BasicInfoExtraComp from "./BasicInfoExtraComp";

const BasicInfo = () => {
  const user = useAppSelector((state) => state.user);
  const downloadCsv = (data: any, query: string) => {
    const pattern = /date_from=(\d{4}-\d{2}-\d{2})&date_to=(\d{4}-\d{2}-\d{2})/;
    const match = pattern.exec(query) as string[];
    const dateFrom = match[1];
    const dateTo = match[2];

    const csvTitle = [
      [`${user.defaultCompany}`],
      ["Employee Basic Info"],
      [
        "Period",
        `${Utils._dateToShow(dateFrom)} to ${Utils._dateToShow(dateTo)}`,
      ],
      ["Number of Employees:", data.length],
    ];

    var csvTableHeaders = [
      "Employee",
      "Number",
      "Email",
      "Job Title",
      "Date of Birth",
      "Legal Status",
      "NRIC",
      "FIN",
      "Nationality",
      "Malaysian I/C",
      "Passport Number",
      "Date of Appointment",
      "Last Day of Service",
      "Payment method",
      "Residential: Type",
      "Residential: Block no",
      "Residential: St name",
      "Residential: Level no",
      "Residential: Unit no",
      "Residential: Postal code",
      "Residential: Address line1",
      "Residential: Address line2",
      "Residential: Address line3",
      "Residential: Country code",
      "Bank",
      "Account number",
      "Branch code",
      "Job Title",
    ];

    const csvEmpData = data.map((empData: any) => {
      return [
        empData.name,
        empData.emp_number,
        empData?.email || "",
        empData?.job_title || "",
        Utils._dateToShow(empData?.date_of_birth) || "",
        empData.legal_status_details.legal_status?.name,
        empData.legal_status_details?.nric || "",
        empData.legal_status_details?.fin || "",
        empData.legal_status_details?.nationality?.nationality || "",
        empData.legal_status_details?.malasiyan_ic || "",
        empData.legal_status_details?.passport || "",
        Utils._dateToShow(empData.date_of_appointment) || "",
        `${empData.eos ? Utils._dateToShow(empData.eos) : ""}`,
        empData?.payment_method?.payment_method_name || "",
        empData?.address_details?.address_type_name || "",
        empData?.address_details?.block_no || "",
        empData?.address_details?.street_name || "",
        empData?.address_details?.level_no || "",
        empData?.address_details?.unit_no || "",
        empData?.address_details?.postal_code || "",
        empData?.address_details?.address_line_1 || "",
        empData?.address_details?.address_line_2 || "",
        empData?.address_details?.address_line_3 || "",
        empData?.address_details?.country || "",
        empData?.bank_details?.bank_name || "",
        empData?.bank_details?.bank_account_number || "",
        empData?.bank_details?.bank_branch_code || "",
        empData?.job_title || "",
      ];
    });

    const combinedData = [...csvTitle, [], csvTableHeaders, ...csvEmpData];

    const workbook = XLSX.utils.book_new();

    // Add a worksheet with combined data
    const combinedWorksheet = XLSX.utils.aoa_to_sheet(combinedData);
    XLSX.utils.book_append_sheet(workbook, combinedWorksheet, "CombinedData");

    const numCols = 28; // Get the number of columns
    combinedWorksheet["!cols"] = Array(numCols).fill({ width: 20 });

    XLSX.writeFile(
      workbook,
      `${user.defaultCompany.split(" ")[0]}_basic_info.xlsx`,
      {
        compression: true,
      }
    );
  };

  const getEmployeeDetails = async (searchQuery: string) => {
    try {
      const { data, status } = await ReportService._basicInfoReportData(
        searchQuery
      );

      if (status === 200) {
        downloadCsv(data.data, searchQuery);
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };
  return (
    <div>
      <BasicInfoExtraComp downloadFunc={getEmployeeDetails} />
    </div>
  );
};

export default BasicInfo;
