import React, { useState } from "react";

import "./style.css";
import { useAppSelector } from "../../_app";
import { CustomTextField } from "../../components/customFormField";
import { CustomButton } from "../../components/customButton";
import { AuthService } from "../../_service";
import { Notify } from "../../utils";
import { Constant } from "../../config";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

const UserProfile = () => {
  const user = useAppSelector((state) => state.user);
  console.log(user);

  const [change, setChange] = useState(false);

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState("");
  const groups = useAppSelector((state) => state.user.groups);

  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [passwordError, setPasswordError] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [showPassword, setShowPassword] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const handlePasswordChange = async () => {
    if (password.old_password === "") {
      setPasswordError((p) => ({ ...p, old_password: "can't be empty" }));
    } else if (password.new_password === "") {
      setPasswordError((p) => ({ ...p, new_password: "can't be empty" }));
    } else if (password.confirm_password === "") {
      setPasswordError((p) => ({ ...p, confirm_password: "can't be empty" }));
    } else if (password.confirm_password !== password.new_password) {
      setPasswordError((p) => ({
        ...p,
        confirm_password: "Confirm password do not match",
      }));
    } else {
      try {
        await AuthService._changePasswordUser(password);
        Notify("Password changed successfully");
        setPassword({
          old_password: "",
          new_password: "",
          confirm_password: "",
        });
        setChange(false);
      } catch (error) {
        Notify(Constant.common.something_went_wrong, 0);
        setPasswordError((p) => ({
          ...p,
          old_password: "Old Password is Wrong",
        }));
      }
    }
  };

  return (
    <div className="user-profile">
      <div className="title">
        <h2>Edit My User Info</h2>
      </div>
      {/* <CustomTextField title="Name" value={name} onChangeText={setName} /> */}
      <div style={{ display: "flex", padding: "8px 0px" }}>
        <p style={{
          width: "315px",
          color: "#495057",
          fontWeight : "600",
          
        }}>Name</p>
        <p style={{
          width: "100%",
        }}>{name ? name: "N/A"}</p>
      </div>
      {/* <CustomTextField
        type="email"
        title="Email"
        value={email}
        onChangeText={setEmail}
      /> */}
      <div style={{ display: "flex", padding: "8px 0px" }}>
      <p style={{
        width: "315px",
        color: "#495057",
        fontWeight : "600",
        
      }}>Email</p>
      <p style={{
        width: "100%",
      }}>{email?email:"N/A"}</p>
    </div>
      {/* <CustomTextField
        type="phone"
        placeholder="Enter phone number"
        title="Phone"
        value={phone}
        onChangeText={setPhone}
      /> */}
      <div style={{ display: "flex", padding: "8px 0px" }}>
        <p style={{
          width: "315px",
          color: "#495057",
          fontWeight : "600",
          
        }}>Phone</p>
        <p style={{
          width: "100%",
        }}>{phone?phone:"N/A"}</p>
      </div>
      {/* <CustomTextField
        type="text"
        title="Roles"
        value={
          groups?.map(
            (group: any, index: any) =>
              `${group.name} ${index + 1 !== groups.length ? ", " : ""}`
          ) || ""
        }
        disabled={true}
      /> */}
      <div style={{ display: "flex", padding: "8px 0px" }}>
        <p style={{
          width: "315px",
          color: "#495057",
          fontWeight : "600",
          
        }}>Roles</p>
        <p style={{
          width: "100%",
        }}>{user ? user.groups.map((group: any) => group.name).join(", ") : "N/A"}</p>
      </div>


      {change ? (
        <div style={{
          marginTop : "5px"
        }}>
          <h2>Change Password</h2>
          {/* Old Password Field */}
          <div style={{ position: "relative" }}>
            <CustomTextField
              title="Old password"
              placeholder="Old password"
              value={password.old_password}
              onChangeText={(text) =>
                setPassword((p) => ({ ...p, old_password: text }))
              }
              onFocus={() =>
                setPasswordError((p) => ({ ...p, old_password: "" }))
              }
              error={passwordError.old_password}
              type={showPassword.old_password ? "text" : "password"}
            />
            <span
              style={{
                position: "absolute",
                right: "20%",
                top: "60%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={() =>
                setShowPassword((prev) => ({
                  ...prev,
                  old_password: !prev.old_password,
                }))
              }
            >
              {showPassword.old_password ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          {/* New Password Field */}
          <div style={{ position: "relative", marginTop: "1rem" }}>
            <CustomTextField
              title="New password"
              placeholder="New password"
              value={password.new_password}
              onChangeText={(text) =>
                setPassword((p) => ({ ...p, new_password: text }))
              }
              onFocus={() =>
                setPasswordError((p) => ({ ...p, new_password: "" }))
              }
              error={passwordError.new_password}
              type={showPassword.new_password ? "text" : "password"}
            />
            <span
              style={{
                position: "absolute",
                right: "20%",
                top: "60%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={() =>
                setShowPassword((prev) => ({
                  ...prev,
                  new_password: !prev.new_password,
                }))
              }
            >
              {showPassword.new_password ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          {/* Confirm Password Field */}
          <div style={{ position: "relative", marginTop: "1rem" }}>
            <CustomTextField
              title="Confirm password"
              placeholder="Confirm password"
              value={password.confirm_password}
              onChangeText={(text) =>
                setPassword((p) => ({ ...p, confirm_password: text }))
              }
              onFocus={() =>
                setPasswordError((p) => ({ ...p, confirm_password: "" }))
              }
              error={passwordError.confirm_password}
              type={showPassword.confirm_password ? "text" : "password"}
            />
            <span
              style={{
                position: "absolute",
                right: "20%",
                top: "60%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={() =>
                setShowPassword((prev) => ({
                  ...prev,
                  confirm_password: !prev.confirm_password,
                }))
              }
            >
              {showPassword.confirm_password ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
      ) : null}
      <div style={{
        display: "flex",
        paddingTop: "20px",
      }}>
        <CustomButton
          styles={{
            paddingLeft: "0px",
            marginLeft: "0px"
          }}
          type={change ? "secondary" : "primary"}
          title={change ? "Cancel" : "Change Password"}
          onClick={() => setChange((p) => !p)}
        />
        {change && (
          <CustomButton title={"Submit"} onClick={handlePasswordChange} />
        )}
      </div>
    </div>
  );
};

export default UserProfile;
