import React from "react";
import { useNavigate } from "react-router-dom";
import { FiAlertOctagon } from "react-icons/fi";
import { Typography, Box, Button } from "@mui/material";

const AccessDenied: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          bgcolor: "#ffffff",
          padding: 3,
          background: "linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)",
        }}
      >
        <Typography
          variant="h1"
          sx={{
        color: "#ff4444",
        fontSize: "8rem",
        fontWeight: "bold",
        textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          403
        </Typography>
        {/* <FiAlertOctagon size={80} color="#ff4444" /> */}
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{
        mt: 2,
        color: "#333333",
        textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
        fontWeight: "bold",
        letterSpacing: "2px",
          }}
        >
          Access Denied
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          align="center"
          sx={{
        mb: 4,
        color: "#666666",
        maxWidth: "600px",
        textAlign: "center",
          }}
        >
          You don't have permission to access this page.
        </Typography>
        {/* <Button
          variant="contained"
          size="large"
          onClick={() => navigate("/")}
          sx={{
        background: "linear-gradient(45deg, #ff2222 30%, #ff6666 90%)",
        color: "white",
        padding: "12px 30px",
        
          }}
        >
          Return to Homepage
        </Button> */}
      </Box>
    </div>
  );
};

export default AccessDenied;

// 404 page
