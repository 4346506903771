import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { Utils } from "../../../utils";
import { EmployeeService, PayrollService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import { CustomSelectField, Loader } from "../../../components";
import { Typography } from "@mui/material";
import { LoaderContainer } from "../../../CommonStyles";

const ProbationReport = () => {
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const [reportData, setReportData] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const date = new Date();

  const currentPayrunDate = useAppSelector(
    (state) => state?.user?.current_payrun_date
  );
  const [month, setMonth] = useState<string>(`${date.getMonth() + 1}`);
  const [year, setYear] = useState<string>(
    `${currentPayrunDate?.split("-")[0]}`
  );

  const yearOptions = Utils.getDefaultYearRange(
    parseInt(currentPayrunDate?.split("-")[0])
  );

  const monthsOptions = Utils.MONTH_OPTIONS;
  const fromDate = moment([year, parseInt(month) - 1])
    .startOf("month")
    .format("YYYY-MM-DD");

  const toDate = moment([year, parseInt(month) - 1])
    .endOf("month")
    .format("YYYY-MM-DD");

  const getReportData = async () => {
    if (companyId) {
      try {
        setIsLoading(true);
        const response = await PayrollService._getProbationPendingReport(
          companyId,
          fromDate,
          toDate
        );
        console.log("theResponse", response);
        if (response.status >= 200 && response.status < 300) {
          setReportData(response?.data?.data);
          setIsLoading(false);
        }
      } catch (error: any) {
        Utils.showErrorMessage(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getReportData();
  }, [companyId, fromDate, toDate]);

  console.log("thedata", reportData);

  console.log("dates", fromDate, toDate);

  return (
    <>
      <h1
        style={{
          marginBottom: "20px",
          color: "#2c3e50",
          fontSize: "24px",
          fontWeight: 600,
          borderBottom: "2px solid #61677a",
          paddingBottom: "10px",
        }}
      >
        Probation Pending Report
      </h1>

      {/* Year and Month selection */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "1rem",
          paddingLeft: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "1rem",
          }}
        >
          <p
            style={{ color: "#333", marginRight: "1rem", whiteSpace: "nowrap" }}
          >
            {"Year: "}
          </p>
          <CustomSelectField
            value={year}
            onChangeOption={(value) => setYear(value)}
            option={yearOptions.map((year) => ({
              key: year,
              value: year,
            }))}
            disabled={isLoading}
            isDate={true}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "1rem",
          }}
        >
          <p style={{ color: "#333", marginRight: "1rem" }}>{"Month: "}</p>
          <CustomSelectField
            value={month}
            onChangeOption={(value) => setMonth(value)}
            option={monthsOptions.map((month) => ({
              key: month.key,
              value: month.value,
            }))}
            disabled={isLoading}
            isDate={true}
          />
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p style={{ color: "#333", marginRight: "1rem" }}>{"To: "}</p>
          <CustomSelectField
            value={toMonth}
            onChangeOption={(value) => setToMonth(value)}
            option={monthsOptions.map((month) => ({
              key: month.key,
              value: month.value,
            }))}
            disabled={isLoading}
            isDate={true}
          />
        </div> */}
      </div>

      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <TableContainer component={Paper} sx={{ boxShadow: 3, marginTop: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                  }}
                >
                  Employee Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                  }}
                >
                  Appointment Date
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                    textAlign: "center",
                  }}
                >
                  Probation Months Count
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                  }}
                >
                  Last Date of Probation
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#61677a",
                    color: "white",
                    padding: "0.75rem",
                  }}
                >
                  Probation Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData?.length > 0 ? (
                reportData?.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" },
                      "&:hover": { backgroundColor: "#eeeeee" },
                    }}
                  >
                    <TableCell sx={{ padding: "0.75rem" }}>
                      {row?.employee_name || ""}
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem" }}>
                      {moment(row?.service_start_date, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      ) || ""}
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem", textAlign: "center" }}>
                      {row?.probation_months || ""}
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem" }}>
                      {moment(row?.probation_last_date, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      ) || ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.75rem",
                        textTransform: "capitalize",
                        fontWeight: 600,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor:
                            row?.probation_status === "early"
                              ? "#03B381"
                              : row?.probation_status === "extended"
                              ? "#ff6665"
                              : "#3498db",
                          borderRadius: "4px",
                          padding: "0.35rem 0.75rem",
                          maxWidth: "fit-content",
                          minWidth: "40%",
                        }}
                      >
                        <p
                          style={{
                            color: "white",
                            margin: 0,
                            textAlign: "center",
                          }}
                        >
                          {row?.probation_status || ""}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{ textAlign: "center", padding: "2rem" }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#666",
                        fontStyle: "italic",
                        fontSize: "1rem",
                      }}
                    >
                      No pending probation records found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ProbationReport;
