import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../_app";
import { ServiceCodeService } from "../../_service";
import { Notify, Utils } from "../../utils";
import { Constant } from "../../config";
import { LoaderContainer } from "../../CommonStyles";
import { CustomButton, DeactivateModal, Loader, Tooltip } from "../../components";
import { IRASTable } from "../filing/CPFFilingStyles";
import { TableHeader } from "../../components/table";
import { Heading } from "../appSettings/AppSettingsStyles";
import { TableRow } from "../payslip/PayslipStyles";
import { FilterByChar } from "./component";
const ManageSPC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceCodes, setServiceCodes] = useState<any>([]);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [serviceCodeToDelete, setServiceCodeToDelete] = useState<any>();
  const [filterChar, setFilterChar] = useState<any>("All");
  const navigate = useNavigate();
  console.log("filterChar", filterChar);

  const user = useAppSelector((state) => state.user);

  const spcTableHeaders = [
    {
      name: "SPC Code",
      width: "20%",
    },
    {
      name: "Company Name",
      width: "25%",
    },
    {
      name: "Full Address",
      width: "30%",
    },

    {
      name: "Action",
    },
  ];

  const getServiceCodes = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._getSpcList();

      if (status === 200) {
        setServiceCodes(() =>
          data.data.map((dataObj: any) => ({
            id: dataObj.id,
            spc_code: dataObj.spc_code,
            company_name: dataObj.company_name,
            address_l1: dataObj.address_l1,
            address_l2: dataObj.address_l2,
            address_l3: dataObj.address_l3,
            address_code: dataObj.address_code
          }))
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const deleteServiceProviderCompany = async () => {
    setIsLoading(() => true);
    try {
      const { status } = await ServiceCodeService._deletespc(
        serviceCodeToDelete
      );

      if (status === 204) {
        Notify("Service Provider Company Deleted Successfully", 1);
        setServiceCodes((prevServiceCodes: any) =>
          prevServiceCodes.filter(
            (serviceCode: any) => serviceCode.id !== serviceCodeToDelete
          )
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
    setIsDeleteModalActive(() => false);
  };

  const handleDeletespc = (id: any) => {
    setServiceCodeToDelete(() => id);
    setIsDeleteModalActive(() => true);
  };
  useEffect(() => {
    getServiceCodes();
  }, []);

  console.log("serviceCodes", serviceCodes);

  return (


    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <Heading>Manage Service Provider Company</Heading>
            {user.permission === "full" && (
              <CustomButton
                title="Add Service Provider Company"
                width="300px"
                type="secondary"
                onClick={() => navigate("add")}
              />
            )}
          </div>
          <FilterByChar value={filterChar} onChange={setFilterChar} />
          <IRASTable>
            <TableHeader
              headerColumns={spcTableHeaders}
              variant="filled"
              styles={{ padding: "5px" }}
            />
            <tbody>
              {serviceCodes.filter((char: any) =>
                filterChar === "All" ? true : char.company_name.startsWith(filterChar)
              ).sort((a: any, b: any) => a.id - b.id)
                .map((dataObj: any, index: any) => (
                  <TableRow key={dataObj.id}>
                    <td style={{ padding: "8px" }}>{(dataObj.spc_code).toUpperCase()}</td>
                    <td style={{ padding: "8px" }}>{dataObj.company_name}</td>
                    <td style={{ padding: "8px" }}>
                      <p>{(dataObj.address_l1)}</p>
                      <p>{(dataObj.address_l2)}</p>
                      <p>{(dataObj.address_l3)}</p>
                      <p>{(dataObj.address_code)}</p>
                    </td>


                    {user.permission === "full" && (
                      <td>
                        <div style={{ display: "flex", gap: 20 }}>
                          <Tooltip text="Edit">
                            <CustomButton
                              type={"secondary"}
                              size="small"
                              width="30px"
                              title={""}
                              onClick={() =>
                                navigate("edit", {
                                  state: {
                                    id: dataObj.id,
                                    spc_code: dataObj.spc_code,
                                    company_name: dataObj.company_name,
                                    address_l1: dataObj.address_l1,
                                    address_l2: dataObj.address_l2,
                                    address_l3: dataObj.address_l3,
                                    address_code: dataObj.address_code
                                  },
                                })
                              }
                              icon={<AiFillEdit />}
                            />
                          </Tooltip>

                          <Tooltip text="Delete" del>
                            <CustomButton
                              type={"secondary"}
                              size="small"
                              del
                              width="30px"
                              title={""}
                              onClick={() =>
                                handleDeletespc(dataObj.id)
                              }
                              icon={<AiFillDelete />}
                            />
                          </Tooltip>
                        </div>
                      </td>
                    )}
                  </TableRow>
                ))}
            </tbody>
          </IRASTable>
        </>
      )}
      <DeactivateModal
        open={isDeleteModalActive}
        closeModal={() => setIsDeleteModalActive(() => false)}
        approveAction={deleteServiceProviderCompany}
        variant="deleteSpcCode"
      />
    </div>
  )
}

export default ManageSPC