import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  FormControlLabel,
  Typography,
  Tooltip,
  Box,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  AddCircle as AddIcon,
  AddCircle as AddCircleIcon,
  Visibility as VisibilityIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ArrowBack,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import cloneDeep from "lodash.clonedeep";
import { CommonModal, Loader } from "../../../components";
import { Notify, Utils } from "../../../utils";
import { RoleService } from "../../../_service";
import { LoaderContainer } from "../../../CommonStyles";
import { FaArrowLeftLong } from "react-icons/fa6";

const StyledTableContainer = styled(TableContainer)({
  margin: "1rem 0",
  minWidth: "100%",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  borderRadius: "12px",
  transition: "transform 0.2s ease-in-out",
  overflow: "scroll",
  //   "&:hover": {
  //     transform: "translateY(-4px)",
  //   },
}) as typeof TableContainer;

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
  color: "black",
  padding: "16px",
  whiteSpace: "nowrap",
});

const StyledTableRow = styled(TableRow)({
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: alpha("#2196F3", 0.05),
  //   },
  //   "&:hover": {
  //     backgroundColor: alpha("#2196F3", 0.1),
  //     transition: "background-color 0.2s ease",
  //   },
});

const CheckboxGroup = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  padding: "8px 0",
});

const StyledCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#16C47F",
  },
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const StyledFormControlLabel = styled(FormControlLabel)({
  "& .MuiTypography-root": {
    fontSize: "0.875rem",
    color: "#666",
    margin: 0,
  },
});

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#61677A",
  color: theme.palette.common.white,
  fontSize: 16,
  fontWeight: "semibold",

  paddingTop: "0.75rem",
  paddingBottom: "0.75rem",
}));

const TabPermission = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [userGroups, setUserGroups] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  // let userGroups = location.state?.data?.userGroups?.filter(
  //   (x: any) => x.id > 0
  // );
  let tabs = location.state?.data?.tabs?.filter(
    (x: any) => x.name !== "Default"
  );
  const actions = ["view", "add/edit", "delete"];

  const [permissionData, setPermissionData] = React.useState<any>([
    ...location.state?.data?.tabs?.filter((x: any) => x.name !== "Default"),
  ]);

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUserGroups = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await RoleService._getRoles(1);

      if (status === 200) {
        setUserGroups(() =>
          data.map((dataObj: any) => ({
            id: dataObj.id,
            name: dataObj.name,
            description: dataObj.description,
            type: dataObj.type,
          }))
        );
      }
    } catch (error: any) {
      Utils.showErrorMessage(error);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getUserGroups();
  }, []);

  const handleSelection = (
    tabId: number,
    groupId: number,
    permission: string
  ) => {
    console.log("selection", { tabId, groupId, permission });

    let data = cloneDeep(permissionData);

    let tabIndex = permissionData.findIndex((x: any) => x.id === tabId);

    if (data[tabIndex].group_permissions[groupId]?.includes(permission)) {
      data[tabIndex].group_permissions[groupId] = data[
        tabIndex
      ].group_permissions[groupId]?.filter((x: any) => x !== permission);
    } else {
      data[tabIndex].group_permissions[groupId]?.push(permission);
    }

    setPermissionData(data);
  };

  const handleSelectAll = (tabId: number, groupId: number) => {
    let data = cloneDeep(permissionData);

    let tabIndex = permissionData.findIndex((x: any) => x.id === tabId);

    data[tabIndex].group_permissions[groupId] = actions;

    setPermissionData(data);
  };

  const handleSelectNone = (tabId: number, groupId: number) => {
    let data = cloneDeep(permissionData);

    let tabIndex = permissionData.findIndex((x: any) => x.id === tabId);

    data[tabIndex].group_permissions[groupId] = [];

    setPermissionData(data);
  };

  const handleSave = async () => {
    setLoading(true);
    setOpenModal(false);

    try {
      const res = await RoleService._saveTabPermission(
        location.state?.data?.menuId,
        permissionData
      );

      if (res.status >= 200 && res.status < 300) {
        Notify("Permission Updated Successfully", 1);
        navigate(-1);
      }
    } catch (error: any) {
      Utils.showErrorMessage(error);
      setLoading(false);
    }
  };

  console.log("locationState", location.state);
  console.log("userGroups", userGroups);
  console.log("permissionData", permissionData);

  if (isLoading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return (
    <Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            // color: "#1a1a1a",
            fontSize: "1.5rem",
            color: "rgb(71, 101, 117)",
            m: 2,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <FaArrowLeftLong
            style={{ cursor: "pointer", width: 26, height: 28, marginRight: 8 }}
            onClick={() => window.history.back()}
          />
          {`${location.state?.data?.menuName} Tab Permissions`}
        </Typography>
        <Button
          variant="contained"
          onClick={() => setOpenModal(true)}
          sx={{
            bgcolor: "#16C47F",
            color: "white",
            m: 2,
            px: 4,
            marginLeft: "auto",
            "&:hover": {
              bgcolor: "#0FAE6C",
            },
            fontWeight: "bold",
            borderRadius: 2,
            textTransform: "none",
            boxShadow: "0 4px 6px rgba(22, 196, 127, 0.2)",
          }}
        >
          Save
        </Button>
      </div>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
            position: "absolute",
            width: "80%",
            zIndex: 1000,
          }}
        >
          <Loader />
        </div>
      )}
      <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {/* Tabs */}
                </Typography>
              </StyledTableHeaderCell>
              {userGroups?.map((role: any) => (
                <StyledTableHeaderCell key={role.id}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 600, textAlign: "center" }}
                  >
                    {role.name}
                  </Typography>
                </StyledTableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tabs.map((tab: any) => (
              <StyledTableRow key={tab?.id}>
                <TableCell>
                  <Typography
                    sx={{
                      color: "#3D3D3D",
                      textDecoration: "none",
                      cursor: "pointer",
                      fontWeight: 600,
                      width: "fit-content",
                      fontSize: "1rem",
                    }}
                  >
                    {tab?.name}
                  </Typography>
                </TableCell>
                {userGroups.map((role: any) => (
                  <TableCell key={role.id}>
                    <Box
                      sx={{
                        position: "relative",
                        "&:hover .hover-menu": {
                          display: "flex",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(1, 1fr)",
                          gap: 0,
                          columnGap: 1,
                          borderRadius: "4px",
                          border: "1px solid #BCCCDC",
                          width: "fit-content",
                          placeItems: "center",
                          padding: "4px 8px",
                          margin: "auto",
                          "& .MuiCheckbox-root": {
                            padding: "4px",
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "start",
                          },
                          "& .MuiFormControlLabel-root": {
                            margin: 0,
                            justifyContent: "start",
                          },
                          "& .MuiSvgIcon-root": {
                            transition: "all 0.2s ease",
                            "&:hover": {
                              color: "#3df321",
                            },
                          },
                        }}
                      >
                        {actions.map((action) => (
                          <Tooltip
                            key={action}
                            title={`${action.toUpperCase()} permission for ${
                              tab?.name
                            }`}
                            sx={{ fontSize: "0.875rem" }}
                            arrow
                          >
                            <StyledFormControlLabel
                              control={
                                <StyledCheckbox
                                  checked={permissionData[
                                    permissionData.findIndex(
                                      (x: any) => x.id === tab.id
                                    )
                                  ].group_permissions[role.id]?.includes(
                                    action
                                  )}
                                  size="medium"
                                  id={`${tab?.id}-${role?.id}-${action}`}
                                  onChange={() =>
                                    handleSelection(tab?.id, role?.id, action)
                                  }
                                  icon={
                                    action === "view" ? (
                                      <VisibilityIcon
                                        sx={{
                                          width: 24,
                                          height: 24,
                                          opacity: 0.5,
                                        }}
                                      />
                                    ) : action === "add/edit" ? (
                                      <EditIcon
                                        sx={{
                                          width: 24,
                                          height: 24,
                                          opacity: 0.5,
                                        }}
                                      />
                                    ) : (
                                      <DeleteIcon
                                        sx={{
                                          width: 24,
                                          height: 24,
                                          opacity: 0.5,
                                        }}
                                      />
                                    )
                                  }
                                  checkedIcon={
                                    action === "view" ? (
                                      <VisibilityIcon
                                        sx={{
                                          width: 24,
                                          height: 24,
                                          color: "#0096FF",
                                        }}
                                      />
                                    ) : action === "add/edit" ? (
                                      <EditIcon
                                        sx={{ width: 24, height: 24 }}
                                      />
                                    ) : (
                                      <DeleteIcon
                                        sx={{
                                          width: 24,
                                          height: 24,
                                          color: "#FF6464",
                                        }}
                                      />
                                    )
                                  }
                                />
                              }
                              label={""}
                            />
                          </Tooltip>
                        ))}
                      </Box>
                      <Box
                        className="hover-menu"
                        sx={{
                          display: "none",
                          position: "absolute",
                          top: "-30px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          backgroundColor: "white",
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                          borderRadius: "4px",
                          zIndex: 1000,
                          gap: 1,
                          padding: "4px",
                        }}
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          sx={{
                            minWidth: "3.75rem",
                            color: "#16C47F",
                            bgcolor: "#fff",
                            "&:hover": {
                              bgcolor: "#0FAE6C",
                              color: "white",
                            },
                            fontWeight: 700,
                            borderRadius: 1,
                            textTransform: "none",
                            boxShadow: "0 2px 4px rgba(22, 196, 127, 0.2)",
                            fontSize: "0.75rem",
                          }}
                          onClick={() => handleSelectAll(tab?.id, role?.id)}
                        >
                          All
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          sx={{
                            minWidth: "3.75rem",
                            borderColor: "#FF6464",
                            color: "#FF6464",
                            "&:hover": {
                              color: "white",
                              backgroundColor: "#FF4444",
                              borderColor: "#FF4444",
                            },
                            fontWeight: 700,
                            borderRadius: 1,
                            textTransform: "none",
                            fontSize: "0.75rem",
                          }}
                          onClick={() => handleSelectNone(tab?.id, role?.id)}
                        >
                          None
                        </Button>
                      </Box>
                    </Box>
                  </TableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <CommonModal
        handleClose={() => setOpenModal(false)}
        open={openModal}
        // loading={loading}
        // updateData={()=>handleProbationConfirmation()}
        updateData={loading ? () => null : () => handleSave()}
        body={`Are you sure you want to save the changes?`}
        heading="Save Permissions"
        conformButtonText="Confirm"
        cancelButtonText="Cancel"
        onCancel={() => setOpenModal(false)} //called on confirm
      />
    </Box>
  );
};

export default TabPermission;
