import { useState } from "react";
import { Colors } from "../../config/color";
import { AuthHeader } from "../../components/header";
import { Button, Message, TextInput } from "../../components";
import { Link } from "./AuthStyles";
import { useAppDispatch, useAppSelector } from "../../_app";
import { updateMessage } from "../../_features";
import { AuthService } from "../../_service";
import { Constant } from "../../config";

const ResetPassword = () => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const messageData = useAppSelector((state) => state.message.resetPassword);
  const resetMessages = () => {
    dispatch(
      updateMessage({
        message: "",
        page: "resetPassword",
        type: "success",
      }),
    );
  };
  const handleSendEmail = async () => {
    try {
      if (email === "") {
        dispatch(
          updateMessage({
            message: `This email can not be empty`,
            page: "resetPassword",
            type: "error",
          }),
        );
        return;
      }
      const res = await AuthService._forgotPassword(email);
      dispatch(
        updateMessage({
          message:
            res?.data?.data?.message ||
            `Instructions to reset your password have been emailed to ${email} if you have an account with us.`,
          page: "resetPassword",
          type: "success",
        }),
      );
    } catch (e: any) {
      dispatch(
        updateMessage({
          message:
            e?.response?.data?.email[0] ||
            `This email ${email} not have an account with us.`,
          page: "resetPassword",
          type: "error",
        }),
      );
    }
  };
  return (
    <div>
      <AuthHeader />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
        }}
      >
        <div
          style={{
            width: "500px",
            borderRadius: ".3rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
          }}
        >
          <h1
            style={{
              backgroundColor: "#066182",
              padding: "1rem",
              fontSize: "1.25rem",
              color: Colors.WHITE,
            }}
          >
            {Constant.resetPassword.reset_password}
          </h1>
          <div
            style={{
              padding: "1rem",
              border: "1px solid #dee2e6",
              position: "relative",
            }}
          >
            <Message close={resetMessages} data={messageData} />
            <p>
              {Constant.resetPassword.enter_the_mail_to_reset_your_password}
            </p>
            <div style={{ marginTop: "1rem" }}>
              <TextInput
                labal={""}
                type="email"
                placeholder={Constant.resetPassword.enter_your_email_address}
                value={email}
                onChange={setEmail}
                style={{ textTransform: "none" }}
              />
            </div>

            <Button
              title={Constant.common.continue}
              onClick={handleSendEmail}
            />
          </div>
          <div
            style={{
              padding: "2rem 0 1rem 0",
              border: "1px solid #dee2e6",
              position: "relative",
            }}
          >
            <Link
              to="https://tellboss.co/"
              style={{
                textAlign: "center",
                width: "100%",
                display: "block",
              }}
            >
              {Constant.common.back_to_home_page}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
