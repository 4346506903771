import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import {
  CustomButton,
  DeactivateModal,
  Loader,
  Tooltip,
} from "../../../components";
import { TableHeader } from "../../../components/table";
import { Heading } from "../../appSettings/AppSettingsStyles";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableRow } from "../../payslip/PayslipStyles";
import { Notify } from "../../../utils";
import { Constant } from "../../../config";
import { RoleService } from "../../../_service";
import { useEffect, useState } from "react";
import { LoaderContainer } from "../../../CommonStyles";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../_app";

const ManageGroups = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<any>([]);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState<any>();
console.log(groups);

  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  const groupTableHeaders = [
    {
      name: "Group No.",
      width: "15%",
    },
    {
      name: "Group Code",
      width: "20%",
    },
    {
      name: "Group Description",
      width: "20%",
    },
    {
      name: "Status",
      width: "15%",
    },
    {
      name: "Type",
      width: "15%",
    },
    {
      name: "Action",
    },
  ];

  const getUserGroups = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await RoleService._getRoles(0);

      if (status === 200) {
        setGroups(() =>
          data.map((dataObj: any) => ({
            id: dataObj.id,
            name: dataObj.name,
            description: dataObj.description,
            type: dataObj.type,
          })),
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const deleteGroup = async () => {
    setIsLoading(() => true);
    try {
      const { status } = await RoleService._deleteGroup(groupToDelete);

      if (status === 200) {
        Notify("Group Deleted Successfully", 1);
        setGroups((prevGroups: any) =>
          prevGroups.filter((group: any) => group.id !== groupToDelete),
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        Notify(error.response.data.message[0], 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
    setIsDeleteModalActive(() => false);
  };

  const handleDeleteUser = (id: any) => {
    setGroupToDelete(() => id);
    setIsDeleteModalActive(() => true);
  };
  useEffect(() => {
    getUserGroups();
  }, []);

  console.log("theGroups", groups)
  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom:'0.5rem'
            }}
          >
            <Heading>User Groups</Heading>
            {user.permission === "full" && (
              <CustomButton
                title="Add Group"
                type="secondary"
                onClick={() => navigate("add")}
              />
            )}
          </div>
          <IRASTable>
            <TableHeader headerColumns={groupTableHeaders} variant="filled" />
            <tbody>
              {groups
                .sort((a: any, b: any) => a.id - b.id)
                .map((group: any, index: any) => (
                  <TableRow>
                    <td style={{ padding: 10 }}>{index + 1}</td>
                    <td style={{
                      paddingLeft : "4px"
                    }}>{group.name}</td>
                    <td style={{
                      paddingLeft : "22px"
                    }}>{group.description}</td>
                    <td>ACTIVE</td>
                    <td style={{textTransform: 'capitalize'}}>{group?.type}</td>
                    {user.permission === "full" && (
                      <td>
                        <div style={{ display: "flex", gap: 20 }}>
                          {group?.type === "custom" && <Tooltip text="Edit">
                            <CustomButton
                              type={"secondary"}
                              size="small"
                              width="30px"
                              title={""}
                              onClick={() =>
                                navigate("edit", {
                                  state: {
                                    name: group.name,
                                    description:group.description,
                                    id: group.id,
                                  },
                                })
                              }
                              icon={<AiFillEdit />}
                            />
                          </Tooltip>}

                          {group?.type != "default" && <Tooltip text="Delete" del>
                            <CustomButton
                              type={"secondary"}
                              size="small"
                              del
                              width="30px"
                              title={""}
                              onClick={() => handleDeleteUser(group.id)}
                              icon={<AiFillDelete />}
                            />
                          </Tooltip>}
                        </div>
                      </td>
                    )}
                  </TableRow>
                ))}
            </tbody>
          </IRASTable>
        </>
      )}
      <DeactivateModal
        open={isDeleteModalActive}
        closeModal={() => setIsDeleteModalActive(() => false)}
        approveAction={deleteGroup}
        variant="deleteGroup"
      />
    </div>
  );
};

export default ManageGroups;
