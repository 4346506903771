import React from "react";


const CustomBackendPagination = ({
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  totalItems,
}:any) => {
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
console.log("");

  return (
    <div style={styles.container}>
      <span style={styles.text}>
        Employee: {firstItemIndex}-{lastItemIndex} / {totalItems}
      </span>

      <div style={styles.controls}>
        <button
          style={styles.button}
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
        >
          ⏮
        </button>
        <button
          style={styles.button}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          ◀
        </button>

        <span style={styles.pageInfo}>
          Page{" "}
          <select
            value={currentPage}
            onChange={(e) => onPageChange(Number(e.target.value))}
            style={styles.select}
          >
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <option key={page} value={page}>
                {page}
              </option>
            ))}
          </select>{" "}
          of {totalPages}
        </span>

        <button
          style={styles.button}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          ▶
        </button>
        <button
          style={styles.button}
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
        >
          ⏭
        </button>
      </div>

      <div></div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5rem 0.5rem",
    border: "1px solid #ddd",
    borderRadius: "5px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f9f9f9",
  },
  text: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#333",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  button: {
    padding: "0.25rem 0.5rem",
    fontSize: "16px",
    border: "none",
    borderRadius: "3px",
    // backgroundColor: "#007bff",
    color: "#000",
    cursor: "pointer",
    transition: "background-color 0.2s",
  },
  pageInfo: {
    fontSize: "14px",
    fontWeight: "500",
  },
  select: {
    fontSize: "14px",
    padding: "0.25rem 0.5rem",
    borderRadius: "3px",
    border: "1px solid #ccc",
    cursor: "pointer",
  },
};

export default CustomBackendPagination;
