import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../../dashboard/manage-employee/manageEmployee.css";
import { useEffect, useState } from "react";
import { TellBossService } from "../../../_service";
import { EmployeeListProps } from "../../../_types";
import { useAppSelector } from "../../../_app";

import {
  CommonModal,
  CompanyInfoModal,
  CustomButton,
  CustomSelectField,
  Loader,
} from "../../../components";
import { SearchField, TableFilterHeader } from "../../dashboard/component";
import styled from "styled-components";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { DashboardService } from "../../../_service/DashboardService";
import moment from "moment";

const PayrollList = () => {
  const date = new Date();
  const { state } = useLocation();
  const approvalOptions = ["Yes", "No"];

  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);
  const [text, setText] = useState("");
  const [char, setChar] = useState("");
  const [status, setStatus] = useState("1");
  const [employeeList, setEmployeeList] = useState<EmployeeListProps[]>([]);
  const [confirmPayslip, setConfirmPayslip] = useState<boolean>(false);
  const [approvalDate, setApprovalDate] = useState<string>('');
  const [filterEmployeeList, setfilterEmployeeList] = useState<
    EmployeeListProps[]
  >([]);
  const [sending, setSending] = useState(true);
  const [pageInfo, setPageInfo] = useState<{
    pageNumber: number;
    totalLength: number;
    pagePerData: number;
  }>({
    pageNumber: 1,
    totalLength: 10,
    pagePerData: 10,
  });
  const [approvalStatus, setApprovalStatus] = useState<any>(null);
  const [approvalHistory, setApprovalHistory] = useState<any>(null);
  const [cpfReturnStatus, setCpfReturnStatus] = useState<any>(null);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );

  const [isFiltered, setIsFiltered] = useState(false);
  const [month, setMonth] = useState<string>(
    `${parseInt(currentPayrunDate?.split("-")[1] || 0) || date.getMonth() + 1}`
  );
  const [employeeYear, setEmployeeYear] = useState(
    currentPayrunDate?.split("-")[0] || date.getFullYear()
  );
  const [changeStatus, setChangeStatus] = useState("");

  const [openReversalModal, setOpenReversalModal] = useState<boolean>(false);

  const [isAllPayrunCreated, setIsAllPayrunCreated] = useState<boolean>(false)

  const [summary, setSummary] = useState<any>({});

  // const yearsTillNow = [
  //   ...Utils.generateYearArrayTillNow(2022),
  //   date.getFullYear() + 1,
  // ];
  const monthsOptions = Utils.MONTH_OPTIONS;
  const yearsTillNow = Utils.getDefaultYearRange()

  const textColor = {
    new: "#155724",
    existing: "#059BBF",
    resigned: "#dc3545",
  } as any;

  const getEmployeeList = async (type: string) => {
    const defaultCompanyId = user.defaultCompnayId;
    if (type !== "0") {
      setIsFiltered(() => true);
    } else {
      setIsFiltered(() => false);
    }
    if (defaultCompanyId) {
      try {
        setSending(() => true);
        const res = await TellBossService._getPendingApprovalList(
          defaultCompanyId,
          Utils.getLastDayOfMonth(employeeYear, month)
        );
        if (res.status === 200) {
          setEmployeeList(() => res?.data?.data?.payslips);
          setfilterEmployeeList(() => res?.data?.data?.payslips);
          setPageInfo((prev) => ({
            ...prev,
            totalLength: res?.data?.data?.payslips?.length,
          }));
        }
        setApprovalStatus(
          () => res?.data?.data?.approval_history?.action_taken
        );
        setApprovalDate(
          () => res?.data?.data?.approval_history?.updated_at          
        );
        setApprovalHistory(() => res?.data?.data?.approval_history);
        setIsAllPayrunCreated(
          () => res?.data?.data?.summary?.is_all_pay_run
        );
        setCpfReturnStatus(
          () => res?.data?.data?.summary?.is_cpf_return_finalized
        );
        setSummary(() => res?.data?.data?.summary);
        // setChangeStatus(() =>
        //   res?.data?.data?.approval_history?.action_taken === null
        //     ? ""
        //     : res?.data?.data?.approval_history?.action_taken === true
        //     ? "Yes"
        //     : "No"
        // );
        setChar("All");
        setSending(() => false);
      } catch (e: any) {
        console.log(e);
        setSending(() => false);
        setEmployeeList(() => []);
        setfilterEmployeeList(() => []);
        setPageInfo((prev) => ({
          ...prev,
          totalLength: 0,
        }));
        if (e.response?.data?.payslip_date?.length) {
          Notify(e.response?.data?.payslip_date[0], 0);
          // disaptch(resetUser());
        }
      }
    }
  };

  const handlePageValueChange = (
    type: "next" | "previous" | "first" | "last"
  ) => {
    if (type === "next") {
      const isLast =
        pageInfo.pageNumber * pageInfo.pagePerData < employeeList?.length || 0;
      if (isLast) {
        setPageInfo((prev) => ({ ...prev, pageNumber: prev.pageNumber + 1 }));
      }
    }
    if (type === "previous") {
      if (pageInfo.pageNumber > 1) {
        setPageInfo((prev) => ({
          ...prev,
          pageNumber: prev.pageNumber - 1,
        }));
      }
    }
    if (type === "first") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: 1,
      }));
    }
    if (type === "last") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: Math.ceil(pageInfo.totalLength / pageInfo.pagePerData),
      }));
    }
  };

  const sendApprovalTicket = async (
    approvalMonth: string,
    approvalYear: string
  ) => {
    const formData = new FormData();

    formData.append("company", user.defaultCompnayId);
    formData.append("request_type", "AP");
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append(
      "message",
      `Boss has approved payroll for ${approvalMonth}, ${approvalYear}. Now, proceed to create PayRun for respective month.`
    );

    const { status } = await DashboardService._submitRequest(formData);
    return status;
  };

  const checkCPFReturnStatus = async () => {
    try {
     let res = await TellBossService._checkCPFReturnStatus(user.defaultCompnayId, Utils.getLastDayOfMonth(employeeYear, month)); 

     if(res.status >= 200 && res.status < 300){
      console.log("CPF Status", res?.data?.data)
      setCpfReturnStatus(res?.data?.data?.is_cpf_return_finalized)
     }
    } catch (e: any) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  }

  const reverseApproval = async () => {
    setSending(true);
    try {
     let res = await TellBossService._reverseApproval(user.defaultCompnayId, Utils.getLastDayOfMonth(employeeYear, month)); 

     if(res.status >= 200 && res.status < 300){
      getEmployeeList(status).then(()=>setChangeStatus(()=>"Yes"));
      Notify(res.data.data.message, 1);
      setSending(false);
      setOpenReversalModal(false);
      
     }
    } catch (e: any) {
      Notify(Constant.common.something_went_wrong, 0);
      setSending(false);
      setOpenReversalModal(false)
    }
  }

  const approvePayslip = async () => {
    setSending(true);
    setConfirmPayslip(() => false);

    const defaultCompanyId = user.defaultCompnayId;
    try {
      const data = {
        company: defaultCompanyId,
        payslip_date: Utils.getLastDayOfMonth(employeeYear, month),
        action_taken: changeStatus === "Yes" ? "1" : "0",
      };
      const res = await TellBossService._approvePayslip(data);
      if (res.status >= 200 && res.status < 300) {
        const index = monthsOptions.findIndex((data) => data.value === month);
        const requestStatus = await sendApprovalTicket(
          monthsOptions[index].key,
          employeeYear
        );

        if (requestStatus >= 201 && requestStatus < 300) {
          getEmployeeList(status);
          setApprovalStatus(() => (changeStatus === "Yes" ? true : false));
          Notify(res.data.data.message, 1);
          setSending(false);
        }
      }
    } catch (e: any) {
      setChangeStatus(() => "Yes");
      Notify(Constant.common.something_went_wrong, 0);
      setSending(false);
    }
    // setConfirmPayslip(() => false);
  };

  useEffect(() => {
    getEmployeeList(status);
    if (state?.change) {
      setChangeStatus(() => state.change);
    }
  }, [month, employeeYear, status]);

  useEffect(() => {
    if (text === "") {
      setfilterEmployeeList(employeeList);
    } else {
      setfilterEmployeeList(() => {
        return employeeList.filter((item) => {
          if (item?.user?.name?.toLowerCase().includes(text?.toLowerCase())) {
            return true;
          }
          return false;
        });
      });
    }
  }, [text]);
  // page change
  useEffect(() => {
    const startIndex =
      pageInfo.pageNumber * pageInfo.pagePerData - pageInfo.pagePerData;
    const endIndex =
      pageInfo.pageNumber * pageInfo.pagePerData > employeeList?.length
        ? employeeList.length
        : pageInfo.pageNumber * pageInfo.pagePerData;
    setfilterEmployeeList(() => employeeList?.slice(startIndex, endIndex));
  }, [pageInfo.pageNumber, employeeList]);

  // useEffect(() => {
  //   checkCPFReturnStatus();
  // }, [month, employeeYear])
  
console.log("theChangeStatus", changeStatus)

  const getCPFB = (item :any) => {
    // console.log('insideGETCPFB', item)
    
    let cpf_employee = Utils.getPriceFromString(item?.payslip_info?.deductions?.data?.filter((x:any) => x?.name === "CPF - Employee")[0]?.value || "0.00")
    let sdl = Utils.getPriceFromString(item?.payslip_info?.employer_contribution?.data?.filter((x:any) => x?.name === "SDL")[0]?.value || "0.00")
    let cpf_employer = Utils.getPriceFromString(item?.payslip_info?.employer_contribution?.data?.filter((x:any) => x?.name === "CPF - Employer")[0]?.value || "0.00")

    // SHG (Self Help Group: ECF, SINDA, MBMF, CDAC)
    let cdac = (item?.payslip_info?.deductions?.data?.filter((x:any) => x?.name === "CDAC")[0]?.value || "0.00");
    let sinda = (item?.payslip_info?.deductions?.data?.filter((x:any) => x?.name === "SINDA")[0]?.value || "0.00");
    let ecf = (item?.payslip_info?.deductions?.data?.filter((x:any) => x?.name === "ECF")[0]?.value || "0.00");
    let mbmf = (item?.payslip_info?.deductions?.data?.filter((x:any) => x?.name === "MBMF")[0]?.value || "0.00");
        
    let shg = parseFloat(cdac) || parseFloat(sinda) || parseFloat(ecf) || parseFloat (mbmf) || 0.00;

    // console.log("shg", shb, item?.employee?.name,  {cdac, sinda, ecf, mbmf})
    

    let cpfb =( (parseFloat(cpf_employee) || 0) + (parseFloat(cpf_employer) || 0) + (parseFloat(sdl) || 0) + (parseFloat(`${shg}`) || 0)).toFixed(2);
    
    return Utils.prependDollar(Utils.getNumberWithCommas(cpfb || 0));
  }

  console.log("TheHell", {approvalStatus, changeStatus, cpfReturnStatus, isAllPayrunCreated})
  console.log("isPayrunAll", isAllPayrunCreated)

  let employeesWithPendingChanges = Object.keys(JSON.parse(localStorage.getItem("payrollPreview") || "{}"));
  let showStatusColumn = employeesWithPendingChanges?.length === 0 ? false : true;

  console.log("employeesWithPendingChanges", employeesWithPendingChanges);

  useEffect(() => {
    if(showStatusColumn){
      setChangeStatus(() => "Yes");
    }
  }, [showStatusColumn])


  return (
    <div style={{ position: "relative" }}>
      {!sending &&
      employeeList &&
      employeeList?.length < 1 &&
      (char === "" || char === "All") &&
      !isFiltered ? (
        <CompanyInfoModal />
      ) : null}
      <div className="hero_header">
        <h2 className="employee-header-h2">Payroll-TellBoss</h2>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginTop: 20,
              width: "100%",
            }}
          >
            <SearchField value={text} onChangeText={setText} />
            <div style={{ marginBottom: 15 }}>
              <CustomSelectField
                placeholder="Select Month"
                width="100%"
                margin="0rem"
                value={month}
                onChangeOption={(value: any) => setMonth(() => value)}
                option={monthsOptions.map((month: any) => ({
                  key: month.key,
                  value: month.value,
                }))}
              />
            </div>
            <div style={{ marginBottom: 0 }}>
              <CustomSelectField
                placeholder="Select Year"
                width="100%"
                margin="0rem"
                value={employeeYear}
                onChangeOption={(value: any) => setEmployeeYear(value)}
                option={yearsTillNow.map((years) => ({
                  key: years,
                  value: years,
                }))}
              />
            </div>

            {approvalStatus === false &&
              cpfReturnStatus === false &&
              isAllPayrunCreated === false && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "start",
                    height: "fit-content",
                    marginLeft: "auto",
                  }}
                >
                  <div
                    style={{
                      padding: "0.5rem 0.75rem",
                      background: "#ff6665",
                      // background: "rgb(3, 179, 129)",
                      color: "#ffffff",
                      fontWeight: 600,
                      borderRadius: "0.3125rem",
                      width: "fit-content",
                      cursor: "pointer",
                      marginLeft: "auto",
                      marginBottom: "1rem",
                    }}
                    onClick={() => setOpenReversalModal(true)}
                  >
                    <p>Do you want to reverse approval?</p>
                  </div>
                </div>
              )}
          </div>
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              width: "fit-content",
            }}
          >
            {approvalStatus === null && (
              <>
                <p
                  style={{
                    marginTop: 15,
                    background: "#ff6665",
                    color: "#ffffff",
                    fontWeight: 600,
                    border: "1px solid ff6665",
                    borderRadius: "0.25rem",
                    padding: ".35rem 1.25rem",
                    fontSize: "1rem",
                    // marginBottom: '0.5rem',
                    whiteSpace: "nowrap",
                  }}
                >
                  Are there any changes in payslips?
                </p>
                <CustomSelectField
                  value={changeStatus}
                  onChangeOption={(value) => setChangeStatus(() => value)}
                  option={approvalOptions?.map((option) => ({
                    key: option,
                    value: option,
                  }))}
                  width="150px"
                  placeholder="Select option"
                  disabled={
                    approvalStatus !== null || !filterEmployeeList?.length
                  }
                />
                {changeStatus !== "" && approvalStatus === null && (
                  <PreviewButton
                    onClick={changeStatus === "Yes" ? () =>
                      navigate("/dashboard/tellboss/payroll/preview", {
                        state: {
                          payload: {
                            company: companyId,
                            payslip_date: Utils.getLastDayOfMonth(employeeYear, month),
                            action_taken: changeStatus === "Yes" ? "1" : "0",
                          }
                        }
                      }) : () => setConfirmPayslip(() => true)
                    }
                    // onClick={() => setConfirmPayslip(() => true)}
                    // style={{ marginTop: 10, whiteSpace: "nowrap", cursor: "pointer", border: '1px solid #059bbf', padding: '0.35rem 1rem', borderRadius: '0.25rem', color: '#059bbf', fontWeight: 600 }}
                  >
                    {changeStatus === "Yes" ? "Preview & Confirm" : "Confirm"}
                  </PreviewButton>
                )}
              </>
            )}
          </div>
        </div>

        {approvalStatus === false && (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              width: "fit-content",
              gap: "0.5rem",
              marginLeft: "auto",
              marginBottom: "0.5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignContent: "center",
                padding: "0.5rem 0.75rem",
                // background: "#ff6665",
                background: "#03b381",
                color: "#ffffff",
                fontWeight: 600,
                borderRadius: "0.3125rem",
              }}
            >
              Payroll Already Approved
            </div>

            {approvalDate && (
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  padding: "0.5rem 0.75rem",
                  // background: "#ff6665",
                  background: "#03b381",
                  color: "#ffffff",
                  fontWeight: 600,
                  borderRadius: "0.3125rem",
                  width: "fit-content",
                  marginLeft: "auto",
                }}
              >
                {`Received on ${moment(approvalDate).format(
                  "DD/MM/YYYY HH:mm A"
                )}, Payroll Approval by ${approvalHistory?.approver_name || ""}`}
              </div>
            )}
          </div>
        )}

        {approvalStatus === false &&
          (cpfReturnStatus || isAllPayrunCreated) && (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "start",
                height: "fit-content",
              }}
            >
              <div
                style={{
                  padding: "0.5rem 0.75rem",
                  background: "#ff6665",
                  // background: "rgb(3, 179, 129)",
                  color: "#ffffff",
                  fontWeight: 600,
                  borderRadius: "0.3125rem",
                  width: "fit-content",
                  marginLeft: "auto",
                  marginBottom: "1rem",
                }}
              >
                <p>e-CPF FTP file submitted. Payroll closed </p>
              </div>
            </div>
          )}

        {/* total table */}
        <table className="company-table" style={{ margin: "1rem 0" }}>
          <thead className="company-table-head">
            <tr>
              <th
                style={{
                  width: `${100/10}%`,
                  textAlign: "center",
                  backgroundColor: "#FF4500",
                }}
              >
                Total Employee
              </th>
              <th
                style={{
                  width: `${100/10}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                Gross Income
              </th>
              <th
                style={{
                  width: `${100/10}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                Deductions
              </th>
              <th
                style={{
                  width: `${100/10}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                Bank to Pay
              </th>
              <th
                style={{
                  width: `${100/10}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                To Pay CPFB
              </th>
              <th
                style={{
                  width: `${100/10}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                {" "}
              </th>
              <th
                style={{
                  width: `${100/10}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                {" "}
              </th>
              <th
                style={{
                  width: `${100/10}%`,
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                }}
              >
                {" "}
              </th>
              {/* {changeStatus === "Yes" && approvalStatus === null && (
                <th
                  style={{
                    width: "12%",
                    textAlign: "right",
                    backgroundColor: "#FF4500",
                  }}
                >
                  {" "}
                </th>
              )} */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  width: `${100/10}%`,
                  textAlign: "center",
                  fontSize: "1.25rem",
                  fontWeight: "bold",
                }}
              >
                {(summary?.total_employee || 0)}
              </td>
              <td
                style={{
                  width: `${100/10}%`,
                  textAlign: "right",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                  color: "#03B381",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(summary?.gross || 0)}
              </td>
              <td
                style={{
                  width: `${100/10}%`,
                  textAlign: "right",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                  color: "#ff6665",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(summary?.total_deduction || 0)}
              </td>
              <td
                style={{
                  width: `${100/10}%`,
                  textAlign: "right",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                  color: "#03B381",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(summary?.net_pay || 0)}
              </td>
              <td
                style={{
                  width: `${100/10}%`,
                  textAlign: "right",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                  color: "#ff6665",
                }}
              >
                {Utils.getCommanSepartedNumericStringWithDollar(summary?.cpfb || 0)}
              </td>
              <td style={{ width: `${100/10}%`, textAlign: "right" }}>{" "}</td>
              <td style={{ width: `${100/10}%`, textAlign: "right" }}>{" "}</td>
              <td style={{ width: `${100/10}%`, textAlign: "right" }}>{" "}</td>
              {/* {changeStatus === "Yes" && approvalStatus === null && (
                <td style={{ width: "12%", textAlign: "right" }}>{" "}</td>
              )} */}
            </tr>
          </tbody>
        </table>

        <div className="table">
          {sending && <Loader />}
          {filterEmployeeList?.length > 0 && (
            <TableFilterHeader
              name="Employee"
              pageInfo={pageInfo}
              setPageInfo={setPageInfo}
              handlePageValueChange={(val) => handlePageValueChange(val)}
            />
          )}
          <table className="company-table">
            <thead className="company-table-head">
              <tr>
                <th style={{ width: `${100/10}%`, textAlign: "left" }}>
                  Employee Name
                </th>
                <th style={{ width: `${100/10}%`, textAlign: "right" }}>
                  Gross Income
                </th>
                <th style={{ width: `${100/10}%`, textAlign: "right" }}>Deductions</th>
                <th style={{ width: `${100/10}%`, textAlign: "right" }}>
                  Bank to Pay
                </th>
                <th style={{ width: `${100/10}%`, textAlign: "right" }}>
                  To Pay CPFB
                </th>
                <th style={{ width: `${100/10}%`, textAlign: "right" }}>
                  Employee Status
                </th>
                <th style={{ width: `${100/10}%`, textAlign: "right" }}>
                  Resigned Date
                </th>
                <th
                  style={{
                    width: `${100/10}%`,
                    textAlign: "right",
                    whiteSpace: "nowrap",
                  }}
                >
                  Payslip Status
                </th>
                {changeStatus === "Yes" && approvalStatus === null && (
                  <th style={{ width: `${100/10}%`, textAlign: "right" }}>Action</th>
                )}

                {showStatusColumn && <th style={{ width: `${100/10}%`, textAlign: "right" }}>Pending Confirmation</th>}
              </tr>
            </thead>

            <tbody>
              {filterEmployeeList
                ?.sort((a, b) => a.employee.name.localeCompare(b.employee.name))
                ?.map((item: any) => {
                  return (
                    <tr key={item.id}>
                      <td
                        style={{
                          width: `${100/10}%`,
                          textAlign: "left",
                          fontWeight: "bold",
                        }}
                      >
                        <Span className="td-link">
                          {item?.employee?.name || ""}
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: `${100/10}%` }}>
                        <Span>
                          {/* {Utils.prependDollar(
                            item?.payslip_info?.income?.total || "0.00"
                          )} */}
                          {Utils.prependDollar(
                            Utils.getNumberWithCommas(
                              Number(
                                parseFloat(
                                  Utils.getPriceFromString(
                                    item?.payslip_info?.income?.total || "0.00"
                                  )
                                ) +
                                  parseFloat(
                                    Utils.getPriceFromString(
                                      item?.payslip_info?.allowance?.total ||
                                        "0.00"
                                    )
                                  )
                              ).toFixed(2)
                            )
                          )}
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: `${100/10}%` }}>
                        <Span>
                          {Utils.prependDollar(
                            item?.payslip_info?.deductions?.total || "0.00"
                          )}
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: `${100/10}%` }}>
                        <Span>
                          {Utils.prependDollar(
                            item?.payslip_info?.net_pay || "0.00"
                          )}
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: `${100/10}%` }}>
                        <Span>{getCPFB(item)}</Span>
                      </td>
                      <td style={{ textAlign: "right", width: `${100/10}%` }}>
                        <Span
                          style={{
                            color:
                              textColor[item?.employment_status?.toLowerCase()],
                          }}
                        >
                          {item?.employment_status || ""}
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: `${100/10}%` }}>
                        <Span>
                          {item?.eos !== null
                            ? Utils._dateToShow(item?.eos?.last_date_of_service)
                            : ""}
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: `${100/10}%` }}>
                        <Span
                          style={{
                            color: item?.is_finalized ? "#28a745" : "#dc3545",
                          }}
                        >
                          {item?.is_finalized ? "Finalised" : "Unfinalised"}
                        </Span>
                      </td>
                      {changeStatus === "Yes" && approvalStatus === null && (
                        <td style={{ textAlign: "right", width: `${100/10}%` }}>
                          <NavLink to="change" state={{ payrollItem: item, employeesWithPendingChanges: employeesWithPendingChanges, empId: item?.employee?.id, hasPendingChanges: employeesWithPendingChanges?.includes(`${item?.employee?.id}`) }}>
                            Change
                          </NavLink>
                        </td>
                      )}

                      {showStatusColumn && <td style={{ width: `${100/10}%`, fontWeight: 700, textAlign: "center", color:employeesWithPendingChanges?.includes(`${item?.employee?.id}`)?"#28a745":"#ff6665" }}>{employeesWithPendingChanges?.includes(`${item?.employee?.id}`)?" Yes ":"  "}</td>}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div style={{ textAlign: "center", padding: ".5rem" }}>
            {!filterEmployeeList?.length && (
              <>
                <p style={{ color: "#495057" }}>No records found.</p>
                <p style={{ color: "#495057", marginTop: 10, fontWeight: 600 }}>
                  Q1. Have you completed{" "}
                  {Utils.MONTH_FULL_NAMES[parseInt(month) - 1]} {employeeYear}{" "}
                  payroll?
                </p>
                <p style={{ color: "#495057" }}>
                  A1. If "NO", go to "Manage Employees" and finalize all
                  payslips.
                </p>
                <p style={{ color: "#495057" }}>
                  A2. If "YES", go to "Pay Runs" and "Send payroll to Tellboss
                  for approval"
                </p>
              </>
            )}
          </div>

          {/* ---------- Reversal ------------ */}

          {/* 
           approvalStatus === false -> "Approved"
           approvalStatus === true -> "Not Approved"
           approvalStatus === null -> "No action taken yet"

           cpfReturnStats -> is_cpf_return_finalized
           */}

          {/* {approvalStatus === false && 
           cpfReturnStatus === false && 
           <div style={{
            display: 'flex',
            justifyContent:'center',
            gap:'1rem',
            flexDirection: 'column',
            alignItems:'center',
            color: "#ff6665",
            fontWeight: 700,
            border: '1.5px solid #373A40',
            backgroundColor: '#DDDDDD',
            borderRadius: '0.25rem',
            padding: "1rem",
            maxWidth: '50%',
            textTransform: 'capitalize',
            marginTop: '1rem',
            marginBottom: '1rem',
            margin: 'auto',
            width: 'fit-content'
            
          }}>
              <p>
                DO YOU WANT TO REVERSE THE PAYSLIP APPROVAL?
              </p> 
              {approvalStatus === false && 
              cpfReturnStatus === false && 
              <div style={{
                display: 'flex',
                justifyContent:'center',
                alignItems:'center',
                marginTop:'1rem',
                padding: "0.5rem 0.75rem",
                background: "#ff6665", 
                // background: "rgb(3, 179, 129)", 
                color: "#ffffff", 
                fontWeight: 600,
                borderRadius: "0.3125rem",
                width:'fit-content',
                margin: 'auto',
                cursor: 'pointer'
              }} onClick={()=>setOpenReversalModal(true)}>
                <p>Reverse Approval</p>
              </div>}
           </div>
           } */}

          <CommonModal
            handleClose={() => setConfirmPayslip(() => false)}
            open={confirmPayslip}
            updateData={() => {
              approvePayslip();
            }}
            body={
              changeStatus === "Yes"
                ? `Are you sure you want to unapprove payroll data and send it for change?`
                : `Have Admin recieved Approval from TellBoss app?`
            }
            heading={
              changeStatus === "Yes"
                ? "Change Payroll"
                : "Payroll to be approved by Admin"
            }
            conformButtonText={
              changeStatus === "Yes" ? "Confirm" : "No-Admin Approves Now"
            }
            cancelButtonText={
              changeStatus === "Yes" ? "Cancel" : "Yes-Stop No action"
            }
          />

          <CommonModal
            handleClose={() => setOpenReversalModal(() => false)}
            open={openReversalModal}
            updateData={() => {
              reverseApproval();
            }}
            body={`Are you sure you want to reverse approval by Administrator?`}
            heading={`Reverse Approval by Administrator`}
            conformButtonText="Yes - proceed to reverse previous approval"
            cancelButtonText="No - Stop this action"
          />
        </div>
      </div>
    </div>
  );
};

export default PayrollList;

const Span = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
`;

const PreviewButton = styled.p`
  padding: 0.35rem 1rem;
  color: #059bbf;
  background: #fff;
  border: 1px solid #059bbf;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  transition: transform 0.2s ease;
  margin-top: 0.625rem;
  &:hover {
    background-color: #059bbf;
    color: #fff;
    /* transform: scale(1.05); */
    
  }
`