import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceCodeService } from "../../_service";
import { Notify } from "../../utils";
import { Constant } from "../../config";
import { CustomButton, CustomNumberField, CustomTextField, NavigateBack } from "../../components";
import { Heading } from "../appSettings/AppSettingsStyles";
import { Typography } from "@mui/material";

const AddSPC = () => {

    const [spcCode, setSpcCode] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [addressL1, setAddressL1] = useState<string>('');
    const [addressL2, setAddressL2] = useState<string>('');
    const [addressL3, setAddressL3] = useState<string>('');
    const [addressCode, setAddressCode] = useState<string>('');

    const [heading, setHeading] = useState<string>("");

    const navigate = useNavigate();
    const location = useLocation();

    const addServiceProviderCompany = async () => {
        try {
            const { status } = await ServiceCodeService._addspc({
                spc_code: spcCode,
                company_name: companyName,
                address_l1: addressL1,
                address_l2: addressL2,
                address_l3: addressL3,
                address_code: addressCode
            });
            if (status === 201) {
                Notify("Service Provider Company added successfully", 1);
                navigate("/dashboard/manage-spc");
            }
        } catch (error: any) {
            if (error?.response?.data?.message) {
                Notify(error.response.data.message, 0);
            } else {
                Notify(Constant.common.something_went_wrong, 0);
            }
        }
    };

    const editServiceProviderCompany = async () => {
        try {
            const { status } = await ServiceCodeService._editspc(
                {
                    spc_code: spcCode,
                    company_name: companyName,
                    address_l1: addressL1,
                    address_l2: addressL2,
                    address_l3: addressL3,
                    address_code: addressCode
                },
                location.state.id
            );


            if (status === 200) {
                Notify("Service Provider Company edited successfully", 1);
                navigate("/dashboard/manage-spc");
            }
        } catch (error: any) {
            if (error?.response?.status === 400) {
                if (error?.response?.data?.message) {
                    Notify(error.response.data.message, 0);
                } else {
                    Notify(Constant.common.something_went_wrong, 0);
                }
            } else {
                Notify(Constant.common.something_went_wrong, 0);
            }
        }
    };

    const saveButtonClick = () => {
        if (location.pathname.split("/").pop() === "edit") {
            editServiceProviderCompany();
        } else {
            addServiceProviderCompany();
        }
    };

    useEffect(() => {
        if (location.pathname.split("/").pop() === "edit") {
            setSpcCode(location.state.spc_code || "");
            setCompanyName(location.state.company_name || "");
            setAddressL1(location.state.address_l1 || "");
            setAddressL2(location.state.address_l2 || "");
            setAddressL3(location.state.address_l3 || "");
            setAddressCode(location.state.address_code || "");
            setHeading("Edit Service Provider Company");
        } else {
            setHeading("Add Service Provider Company");
        }
    }, [location]);

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <NavigateBack />
                <Heading>{heading}</Heading>
            </div>
            <div style={{ margin: "40px 16px" }}>
                <CustomTextField
                    title="SPC Code"
                    placeholder="Unique SPC Code"
                    value={spcCode.toLocaleUpperCase()}
                    onChangeText={(value) => {
                        // Allow only alphanumeric and no special characters, also limit to 6 characters
                        if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 6) {
                            setSpcCode(value); // Update only if valid
                        }
                    }}
                    error={spcCode.length !== 6 && spcCode.length > 0
                        ? "SPC Code must be exactly 6 characters"
                        : ""}
                />

                <CustomTextField
                    title="Company Name"
                    placeholder="Company Name"
                    value={companyName}
                    onChangeText={setCompanyName}
                />

                <div style={{ marginTop: "10px" }}>
                    <Typography color="#204ca0" sx={{ fontWeight: "bold", marginBottom: '10px' }}>
                        Physical Address
                    </Typography>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <CustomTextField
                            title="Line 1"
                            placeholder="Address Line 1"
                            value={addressL1}
                            onChangeText={setAddressL1}
                        />
                        <CustomTextField
                            title="Line 2"
                            placeholder="Address Line 2"
                            value={addressL2}
                            onChangeText={setAddressL2}
                        />
                        <CustomTextField
                            title="Line 3"
                            placeholder="Address Line 3"
                            value={addressL3}
                            onChangeText={setAddressL3}
                        />
                        <CustomTextField
                            title="Postal Code"
                            placeholder="Address Code"
                            value={addressCode}
                            onChangeText={(value) => {
                                // Allow only numeric input
                                if (/^\d*$/.test(value)) {
                                    setAddressCode(value); // Update if numeric
                                }
                            }}
                        />
                    </div>
                </div>

            </div>
            <CustomButton
                title="Save"
                onClick={saveButtonClick}
                disable={
                    
                    !spcCode || spcCode.length !== 6 || !companyName || !addressL1 || !addressL2 || !addressL3 || !addressCode
                }
            />
        </div>
    );
};

export default AddSPC;
