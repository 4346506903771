import { set } from "lodash";
import React, { useEffect, useState } from "react";
import { RequestDetail } from "../../requestdashboard/ViewRequest/ViewRequestStyles";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableHeader } from "../../../components/table";
import { TableRow } from "../../payslip/PayslipStyles";
import { TableColumn } from "../../users/styles";
import { Notify, Utils } from "../../../utils";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import {
  ReportHorizontalSeparator,
  ReportSectionHeading,
} from "../../reports/ReportFilterPageStyles";
import styled from "styled-components";
import { CommonModal, CustomButton } from "../../../components";
import { BiArrowBack } from "react-icons/bi";
import { IconContext } from "react-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardService } from "../../../_service/DashboardService";
import { TellBossService } from "../../../_service";
import { useAppSelector } from "../../../_app";

const PayrollPreview = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useAppSelector((state) => state.user);

  const [payrollPreviewData, setPayrollPreviewData] = useState<any>(
    JSON.parse(localStorage.getItem("payrollPreview") || "{}")
  );

  const [jsonData, setJsonData] = useState<any>([]);

  const [openModal, setOpenModal] = useState(false);

  const [loading, setLoading] = useState(false);

  let payload = location.state.payload;

  const tableHeaders = [
    {
      name: "Field Name",
      width: "30%",
    },
    {
      name: "Old Value",
      width: "30%",
    },
    {
      name: "New Value",
      width: "30%",
    },
  ];


  const monthsOptions = Utils.MONTH_OPTIONS;

  const sendApprovalTicket = async (
    approvalMonth: string,
    approvalYear: string
  ) => {
    const formData = new FormData();

    formData.append("company", user.defaultCompnayId);
    formData.append("request_type", "AP");
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append(
      "message",
      `Boss has approved payroll for ${approvalMonth}, ${approvalYear}. Now, proceed to create PayRun for respective month.`
    );

    const { status } = await DashboardService._submitRequest(formData);
    return status;
  };

  const sendBackForRevision = async () => {
    console.log("payload for revision", payload)
    try {
      const res = await TellBossService._approvePayslip(payload);
      if (res.status >= 200 && res.status < 300) {
        // const index = monthsOptions.findIndex((data) => data.value === payload.payslip_date.split("-")[1]);
        // const requestStatus = await sendApprovalTicket(
        //   monthsOptions[index].key,
        //   payload.payslip_date.split("-")[0]
        // );

        // if (requestStatus >= 201 && requestStatus < 300) {
        //   Notify("Payroll Changes Submitted Successfully", 1);
        //   navigate("/dashboard/payment_runs");
        // }


        Notify("Payroll Changes Submitted Successfully", 1);
          navigate("/dashboard/payment_runs");
      }
    } catch (e: any) {
      Utils.showErrorMessage(e);
      setLoading(false);
      setOpenModal(() => false);
    }

  }

  const handleSave = async () => {
    setLoading(true);
    console.log("save clicked");
    const payload = Object.values(payrollPreviewData);
    console.log("save payload", { payload });
    try {
      const { status } = await DashboardService._submitRequest(payload);

      if (status >= 200 && status < 300) {
        // Notify("Payroll Changes Submitted Successfully", 1);
        sendBackForRevision()
        localStorage.removeItem("payrollPreview");
        // navigate(-1);
      }
      // setLoading(false);
    } catch (error: any) {
      setLoading(false);
      Utils.showErrorMessage(error);
    }
    // setOpenModal(() => false);
  };

  useEffect(() => {
    let data = Object.values(payrollPreviewData)?.map((item: any) =>
      JSON.parse(item?.json_data)
    );
    setJsonData(data);
    console.log("extracted data", data);
  }, [payrollPreviewData]);

  console.log(payrollPreviewData, Object.values(payrollPreviewData));
  console.log("outside json data", jsonData);

  const handleBack = () => {
    navigate(-1);
    navigate("/dashboard/tellboss/payroll", { state: { change: "Yes" } });
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "start" }}>
        <IconContext.Provider value={{ color: "#2c3e50", size: "56px" }}>
          <BiArrowBack style={{ cursor: "pointer" }} onClick={handleBack} />
        </IconContext.Provider>

        <Heading>Payroll Changes - Preview</Heading>
      </div>
      {jsonData?.length > 0 ? (
        jsonData?.map((item: any, index: number) => (
          <div style={{ padding: 8, marginTop: "1rem" }} key={index}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "1rem",
                alignItems: "center",
                width: "60%",
              }}
            >
              <span>
                <strong>Employee Name: </strong>
                {item ? item[0]["employee_name"] : ""},{" "}
              </span>{" "}
              <span>
                <strong>Employee Number: </strong>
                {item ? item[0]["employee_number"] : ""}
              </span>
              <span>
                <strong>Payslip Date: </strong>
                {item ? item[0]["payslip_date"] : ""}
              </span>
            </div>
            <Table
              sx={{
                width: "60%",
                borderCollapse: "collapse",
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                overflow: "hidden",
                margin: "1rem 0",
              }}
            >
              <TableHead>
                <MuiTableRow>
                  {tableHeaders.map((header) => (
                    <TableCell
                      key={header.name}
                      sx={{
                        width: header.width,
                        backgroundColor: "#61677a",
                        color: "white",
                        fontWeight: "bold",
                        padding: "0.5rem 0.75rem",
                        fontSize: "14px",
                      }}
                    >
                      {header.name}
                    </TableCell>
                  ))}
                </MuiTableRow>
              </TableHead>
              <TableBody>
                {item.map(
                  (data: any, index: any) =>
                    index !== 0 && (
                      <MuiTableRow
                        key={index}
                        sx={{
                          "&:nth-of-type(odd)": {
                            backgroundColor: "rgba(248, 249, 250, 0.7)",
                          },
                          "&:nth-of-type(even)": {
                            backgroundColor: "#ffffff",
                          },
                          "&:hover": {
                            backgroundColor: "rgba(236, 236, 236, 0.9)",
                            transition: "background-color 0.2s ease",
                            boxShadow: "inset 0 0 0 1px rgba(0,0,0,0.1)",
                          },
                          verticalAlign: "bottom",
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                          transition: "all 0.2s ease",
                        }}
                      >
                        <TableCell sx={{ padding: "8px 12px" }}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {data.name}
                          </Typography>
                          <Typography
                            sx={{ mt: 1, color: "#666", fontSize: "14px" }}
                          >
                            {data?.field_name}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ padding: "8px 12px" }}>
                          <Typography sx={{ mt: 2, fontSize: "14px" }}>
                            {data.is_amount
                              ? Utils.getCommanSepartedNumericStringWithDollar(
                                  data.value
                                )
                              : data.value}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ padding: "8px 12px" }}>
                          <Typography sx={{ mt: 2, fontSize: "14px" }}>
                            {data.is_amount
                              ? Utils.getCommanSepartedNumericStringWithDollar(
                                  data.new_value
                                )
                              : data.new_value}
                          </Typography>
                        </TableCell>
                      </MuiTableRow>
                    )
                )}
              </TableBody>
            </Table>
          </div>
        ))
      ) : (
        <div style={{
          marginTop: "1rem",
          margin: 'auto',
          width: '100%',
          height: '60vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
            <p
            style={{
              color: "#666",
              fontStyle: "italic",
              fontSize: "1rem",
            }}
          >
            No records with pending changes found.Please go back and make changes for preview.
          </p>
        </div>
        
      )}

     {jsonData?.length > 0 && <CustomButton title="Save" onClick={() => setOpenModal(true)} />}

      <CommonModal
        handleClose={() => setOpenModal(false)}
        open={openModal}
        loading={loading}
        // updateData={()=>handleProbationConfirmation()}
        updateData={loading ? () => null : () => handleSave()}
        body={`Are you sure you want to unapprove payroll data and send it for change?`}
        heading="Change Payroll"
        conformButtonText="Confirm"
        cancelButtonText="Cancel"
        onCancel={() => setOpenModal(false)} //called on confirm
      />
    </div>
  );
};

const Heading = styled.h1`
  font-size: 1.75rem;
  padding: 0.5rem 1.25rem;
  color: #2c3e50;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0;
  letter-spacing: 0.5px;
  border-bottom: 2px solid #eaeaea;
  transition: color 0.2s ease;

  &:hover {
    color: #34495e;
  }
`;

export default PayrollPreview;
