import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Typography, Box } from "@mui/material";

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      bgcolor: "#ffffff",
      padding: 3,
      background: "linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)",
      }}
    >
      <Typography
      variant="h1"
      sx={{
        color: "#ff4444",
        fontSize: "8rem",
        fontWeight: "bold",
        textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
      }}
      >
      404
      </Typography>
      <Typography
      variant="h2"
      component="h1"
      gutterBottom
      sx={{
        mt: 2,
        color: "#333333",
        textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
        fontWeight: "bold",
        letterSpacing: "2px",
      }}
      >
      Page Not Found
      </Typography>
      <Typography
      variant="h5"
      component="h2"
      align="center"
      sx={{
        mb: 4,
        color: "#666666",
        textAlign: "center",
      }}
      >
      The page you're looking for doesn't exist or has been moved.
      </Typography>
      <Button
      variant="contained"
      size="large"
      onClick={() => navigate("/")}
      sx={{
        background: "linear-gradient(45deg, #ff2222 30%, #ff6666 90%)",
        color: "white",
        padding: "12px 30px",
      }}
      >
      Back to Homepage
      </Button>
    </Box>
  );
};

export default PageNotFound;

// const PageNotFound = () => {

//   return (
//     <Container>
//       <div className="container">
//         <div className="row">
//           <div className="col-sm-12 ">
//             <div className="col-sm-10 col-sm-offset-1  text-center">
//               <ImageBackgound className="four_zero_four_bg">
//                 <Heading className="text-center ">404</Heading>
//               </ImageBackgound>

//               <Center>
//                 <SubHeading className="h2">Look like you're lost</SubHeading>
//                 <p>the page you are looking for not avaible!</p>
//                 <HomeLink to="/">Go to Home</HomeLink>
//               </Center>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Container>
//   );
// };

// export default PageNotFound;

// const Container = styled.div`
//   padding: 40px 0;
//   background: #fff;
//   font-family: "Arvo", serif;
// `;

// const ImageBackgound = styled.div`
//   background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
//   height: 400px;
//   background-position: center;
// `;

// const Center = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;
// const Heading = styled.h1`
//   font-size: 80px;
//   text-align: center;
// `;

// const SubHeading = styled.h3`
//   font-size: 40px;
// `;

// const HomeLink = styled(NavLink)`
//   width: 200px;
//   height: 40px;
//   font-weight: 600;
//   border-radius: 5px;
//   outline: none;
//   text-align: center;
//   color: #fff;
//   padding: 6px 12px;
//   margin: 1rem;
//   font-size: 1rem;
//   border: 1px solid #059bbf;
//   background: #059bbf;
//   cursor: pointer;
//   transition: 0.3s all;
//   text-decoration: none;
//   &:hover {
//     border: 1px solid #333;
//     color: #059bbf;
//     background: #fff7;
//     transition: 0.3s all;
//   }
// `;
