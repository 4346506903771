import { useEffect } from "react";
import { Outlet, createBrowserRouter, useNavigate } from "react-router-dom";

import {
  CPFCal,
  Dashboard,
  DashboardView,
  LoginScreen,
  ManageCPFTable,
  ManageCompany,
  ManageDeduction,
  EmployeeList,
  AddEmployee,
  SelfService,
  ManageSDL,
  PayslipLog,
  RegularHoursLog,
  UserProfile,
  ManageUsers,
  Payrun,
  AppSettings,
  AppSettingsCustomItems,
  AppSettingsEmployeeDetails,
  AppSettingsEmployeeFilingDetails,
  AppSettingsEmployeeNumbers,
  AppSettingsPayFrequencies,
  AppSettingsPayroll,
  CreatePasswordScreen,
  ResetPasswordScreen,
  AddCustomItems,
  AddCustomeInputFields,
  AddUsers,
  EditRoles,
  LeaveActivity,
  LeaveRecordTaken,
  LeaveEntitlementPolicy,
  LeaveAdjustments,
  LeaveTakeOns,
  Reports,
  CPFReports,
  LeaveOverview,
  TimeReports,
} from "../pages";

import PayrollManage, {
  EditBasicSalary,
  EditEmployeeInfo,
  EditRegularHour,
  EmployeePayroll,
  EndService,
  Notes,
  PayslipCalculation,
  RegularHours,
  RegularInputItems,
} from "../pages/dashboard/payroll";
import Header from "../components/header";
import { useAppSelector } from "../_app";
import {
  BulkFinalisation,
  EmployeeNotes,
  EmployeePayslipAddField,
  RegularInputItemsAddField,
} from "../components";
import { Signin } from "../pages/auth";
import GiroSettings from "../pages/appSettings/GiroSettings";
import BasicPayrollSettings from "../pages/appSettings/PayrollCalculation/BasicPayrollSettings";
import CPFPayrollSettings from "../pages/appSettings/PayrollCalculation/CPFPayrollSettings";
import PageNotFound from "../components/PageNotFound";
import AddEntitlementPolicy from "../pages/appSettings/Leaves/AddEntitlementPolicy";
import LeaveList from "../pages/appSettings/Leaves/LeaveList";
import AddLeaveType from "../pages/appSettings/Leaves/AddLeaveType";
import DetailedLeaveComponent from "../pages/appSettings/Leaves/DetailedLeaveComponent";
import { AddPenalty } from "../pages/filing/CPF";
import { EditIRAS, IRASFiling } from "../pages/filing/IRAS";
import TMS from "../pages/tms";
import { PayslipReports } from "../pages/payslip";
import { AddGroup, EditGroups, ManageGroups } from "../pages/users/groups";
import { AddHRTemplate, ManageHRTemplate } from "../pages/template/HRTemplate";
import ProtectedRoute from "./ProtectedRoute";
import Layout from "../layout";
import { GiroExport } from "../pages/giro";
import { LeaveApproval } from "../pages/approval";
import { EditFiling, ManualFiling } from "../pages/filing/Manual";
import {
  AddRequest,
  CsoDashboard,
  DashboardLayout,
  DataEntryDashboard,
  FinalizerDashboard,
  ReviewerDashboard,
} from "../pages/requestdashboard";
import EmployeePayrollList from "../pages/dashboard/manage-employee/EmployeePayrollList";
import { ViewRequest } from "../pages/requestdashboard/ViewRequest";
import {
  BasicInfo,
  LeaveExpiryReport,
  LeaveReport,
  PayslipExport,
  TransactionHistory,
} from "../pages/reports";
import { LeaveDaysReport } from "../pages/reports/LeaveDaysReport";
import ViewHRTemplate from "../pages/template/HRTemplate/ViewHRTemplate";
import { ChangePayroll, PayrollList } from "../pages/tellboss";
import {
  EmployeeUsers,
  GeneralSettings,
  LeaveSettings,
  SelfServiceHome,
} from "../pages/dashboard/self-service";
import AddCPFPolicy from "../pages/dashboard/AddCPFPolicy";
import ManageCPFLimit from "../pages/dashboard/ManageCPFLimit";
import AddCpfLimit from "../pages/dashboard/AddCPFLimit";
import EmployeePayslips from "../pages/employee-payslips/EmployeePayslips";
import EmployeeRequests from "../pages/employee-requests/EmployeeRequests";
import EditInfoUpdate from "../pages/employee-requests/EditInfoUpdate";
import EmailVerifyScreen from "../pages/auth/EmailVerifyScreen";
import AddMaxAw from "../pages/dashboard/AddMaxAw";
import LeaveAllEntitlementPolicies from "../pages/dashboard/leave/LeaveAllEntitlementPolicies";
import LeaveApprovalDetails from "../pages/approval/LeaveApprovalDetails/LeaveApprovalDetails";
import AnnualBonus from "../pages/dashboard/AnnualBonus";
import AnnualIncrement from "../pages/dashboard/AnnualIncrement";
import { CompletedDashboard } from "../pages/requestdashboard/CompletedDashboard";
import { MonthlyPayrollReport } from "../pages/reports/MonthlyPayrollReport";
import AddFiling from "../pages/filing/Manual/AddFiling";
import AnnualIncrementListing from "../pages/dashboard/AnnualIncrementListing";
import EmployeeActivity from "../pages/dashboard/payroll/EmployeeActivity";
import ManageEndService from "../pages/dashboard/payroll/ManageEndService";
import IRASFilingIR21 from "../pages/filing/IRAS/IRASFilingIR21";
import DeactivateCompany from "../pages/dashboard/DeactivateCompany";
import AnnualBonusListing from "../pages/dashboard/AnnualBonusListing";
import LoginRequestForm from "../pages/auth/LoginRequestForm";
import Probation from "../pages/dashboard/payroll/Probation";
import CustomReports from "../pages/appSettings/CustomReport";
import MonthlyReport from "../pages/reports/CustomReport/MonthlyReport";
import YTDPayrollReport from "../pages/reports/CustomReport/YTDPayrollReport";
import YTDEmployeesReport from "../pages/reports/CustomReport/YTDEmployeesReport";
import YTDCompanyReport from "../pages/reports/CustomReport/YTDCompanyReport";
import IndividualPayslipReports from "../pages/payslip/IndividualPayslilpReport";
import ManageServiceCode from "../pages/appSettings/ServiceCode/ManageServiceCode";
import AddOrEditServiceCode from "../pages/appSettings/ServiceCode/AddOrEditServiceCode";
import CustomReportsGlobal from "../pages/appSettings/CustomReportsGlobal";
import CustomerInvoice from "../pages/appSettings/CustomerInvoicing/CustomerInvoice";
import GenerateInvoice from "../pages/appSettings/CustomerInvoicing/GenerateInvoice";
import AddManageDeduction from "../pages/dashboard/AddManageDeduction";
import ReinstateEmployee from "../pages/dashboard/payroll/ReinstateEmployee";
import ManageGlobalReportNav from "../pages/appSettings/ManageGlobalReportNav";
import CustomItemsListing from "../pages/appSettings/CustomItemsListing";
import BuiltInItemsListing from "../pages/appSettings/BuiltInItemsListing";
import PayItemsListing from "../pages/appSettings/PayItemsListing";
import ManageSPC from "../pages/dashboard/ManageSPC";
import AddSPC from "../pages/dashboard/AddSPC";
import GlobalInovicing from "../pages/dashboard/GlobalInvoicesPastMonthYear";
import GlobalInvoicesCurrentMonth from "../pages/dashboard/GlobalInvoicesCurrentMonth";
import GlobalInvoicesPastMonthYear from "../pages/dashboard/GlobalInvoicesPastMonthYear";
import PayrollPreview from "../pages/tellboss/payroll/PayrollPreview";
import ProbationReport from "../pages/reports/ProbationReport/ProbationReport";
import GenerateGlobalInvoice from "../pages/dashboard/GenerateGlobalInvoice";
import AccessDenied from "../pages/auth/AccessDenied";
import TabPermission from "../pages/users/groups/TabPermission";

const PrivateRoute = ({ children }: { children: any }) => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (user.email === "") {
      navigate("/");
    }
  }, [user.email]);

  return (
    <ProtectedRoute>
      <Layout>{children}</Layout>
    </ProtectedRoute>
  );
};

const authRouter = createBrowserRouter([
  {
    path: "/",
    element: <LoginScreen />,
  },
  {
    path: "/sign-in",
    element: <Signin />,
  },
  {
    path: "/password-resets",
    element: <ResetPasswordScreen />,
  },
  {
    path: "/email-verify",
    element: <EmailVerifyScreen />,
  },
  {
    path: "/password-resets/*",
    element: <CreatePasswordScreen />,
  },
  {
    path: "/data/request-form",
    element: <LoginRequestForm />,
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    children: [
      {
        path: "user-profile",
        element: <UserProfile />,
      },
      {
        path: "access-denied",
        element: <AccessDenied />,
      },
      {
        path: "annual-bonus",
        // element: <AnnualBonus />,
        children: [
          {
            path: "",
            element: <AnnualBonusListing />
          },
          {
            path: "add",
            element: <AnnualBonus />
          },
          {
            path: "view",
            element: <AnnualBonus />
          },
          {
            path: "edit",
            element: <AnnualBonus />
          }
        ]
      },
      {
        path: "annual-increment",
        // element: (<AnnualIncrementListing />),
        children: [
          {
            path: "",
            element: <AnnualIncrementListing />
          },
          {
            path: "add",
            element: <AnnualIncrement />
          },
          {
            path: "view",
            element: <AnnualIncrement />
          },
          {
            path: "edit",
            element: <AnnualIncrement />
          }
        ]
      },
      { path: "giro-export", element: <GiroExport /> },
      {
        path: "users",
        children: [
          {
            path: "",
            element: <ManageUsers />,
          },
          {
            path: "new",
            element: <AddUsers />,
          },
          {
            path: "edit",
            element: <AddUsers />,
          },
        ],
      },
      {
        path: "manage-users",
        children: [
          {
            path: "",
            element: <ManageUsers />,
          },
          {
            path: "new",
            element: <AddUsers />,
          },
          {
            path: "edit",
            element: <AddUsers />,
          },
          {
            path: "groups",
            children: [
              {
                path: "",
                element: <ManageGroups />,
              },
              {
                path: "add",
                element: <AddGroup />,
              },
              {
                path: "edit",
                element: <AddGroup />,
              },
            ],
          },
          {
            path: "edit-groups",
            children: [
              {
                path: "",
                element: <EditGroups />,
              },
              {
                path: "tabs",
                element: <TabPermission />
              }

            ]
          },
        ],
      },
      { path: "view", element: <DashboardView /> },
      // { path: "manage-company", element: <ManageCompany /> },
      {
        path: "manage-company",
        children: [
          {
            path: "",
            element: <ManageCompany />
          },
          {
            path: "deactivate",
            element: <DeactivateCompany />
          },
        ]
      },
      {
        path: "manage-deduction",
        children: [
          {
            path: "",
            element: <ManageDeduction />
          },
          {
            path: "add",
            element: <AddManageDeduction />,
          }
        ]
      },
      { path: "manage-report", element: <CustomReports /> },
      // { path: "manage-custom-report", element: <CustomReportsGlobal /> },
      { path: "manage-sdf", element: <ManageSDL /> },
      { 
        path: "manage-global-report",
        element: <ManageGlobalReportNav />, 
        children: [
          // {
          //   path: "",
          //   element: <ManageGlobalReportNav />,
          // },
          {
            path: "manage-custom-report",
            element: <CustomReportsGlobal/>,
          },
          {
            path: "custom-items",
            element: <AppSettingsCustomItems/>,
          },
          {
            path: "built-in-items",
            element: <BuiltInItemsListing/>,
          },
        ],
        },
      { 
        path: "manage-spc", 
        children: [
          {
            path: "",
            element: <ManageSPC />
          },
          {
            path: "add",
            element: <AddSPC />
          },
          {
            path: "edit",
            element: <AddSPC />
          },
        ]
      },
      {
        path: "manage-service-code",
        children: [
          {
            path: "",
            element: <ManageServiceCode />,
          },
          {
            path: "add",
            element: <AddOrEditServiceCode />,
          },
          {
            path: "edit",
            element: <AddOrEditServiceCode />,
          },
        ],
      },
      {
        path: "manage_invoicing",
        children: [
          {
            path: "company_level_invoice",
            children: [
              {
                path: "",
                element: <CustomerInvoice />,
              },
              {
                path: "generate",
                element: <GenerateInvoice />,
              },
            ]
          },
          {
            path: "global_level_invoice",
            children: [
              {
                path: "past",
                children : [
                  {
                    path: "",
                    element: <GlobalInvoicesPastMonthYear />,
                  },
                  {
                    path: "generate",
                    element: <GenerateInvoice />
                  }
                ]
              },
              {
                path: "current",
                children : [
                  {
                    path: "",
                    element: <GlobalInvoicesCurrentMonth />,
                  },
                  {
                    path: "generate",
                    element: <GenerateInvoice />
                  }
                ]
              },
              
            ]
          },
          {
            path: "generate_global_invoice",
            element: <GenerateGlobalInvoice />,
          },
        ],
      },
      // {
      //   path: "customer-invoice",
      //   children: [
      //     {
      //       path: "",
      //       element: <CustomerInvoice />,
      //     },
      //     {
      //       path: "generate",
      //       element: <GenerateInvoice />,
      //     },
      //   ],
      // },
      {
        path: "manage-cpf-table",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <ManageCPFTable />,
          },
          {
            path: "add",
            element: <AddCPFPolicy />,
          },
        ],
      },
      {
        path: "manage-cpf-limit",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <ManageCPFLimit />,
          },
          {
            path: "add-ow",
            element: <AddCpfLimit />,
          },
          {
            path: "add-aw",
            element: <AddMaxAw />,
          },

        ],
      },

      {
        path: "manage-global-invoicing",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <GlobalInvoicesCurrentMonth />,
          },
          {
            path: "past-month",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <GlobalInvoicesPastMonthYear />,
              },
              {
                path: "generate",
                element: <GenerateInvoice />
              }

            ]
          },
          {
            path: "generate",
            element: <GenerateInvoice />
          }
        ],
      },
      { path: "payment_runs", element: <Payrun /> },
      { path: "bulk_finalisation", element: <BulkFinalisation /> },
      {
        path: "cpf-cal",
        element: <CPFCal />,
        children: [
          {
            path: "*",
            element: <CPFCal />,
          },
        ],
      },
      { path: "employee-list", element: <EmployeeList /> },
      { path: "employee-payroll", element: <EmployeePayrollList /> },
      { path: "pay-items", element: <PayItemsListing /> },
      { path: "payslip-log", element: <PayslipLog /> },
      { path: "regular-hours-log", element: <RegularHoursLog /> },
      { path: "add-employee", element: <AddEmployee /> },
      { path: "self-service", element: <SelfService /> },
      { path: "leave-overview", element: <LeaveOverview /> },
      { path: "payslip-calculation", element: <PayslipCalculation /> },
      { path: "regular-input-item", element: <RegularInputItems /> },
      { path: "notes", element: <Notes /> },
      {
        path: "notes-edit",
        element: <EmployeeNotes />,
        children: [
          {
            path: "*",
            element: <EmployeeNotes />,
          },
        ],
      },
      {
        path: "EmployeePayslipAddField",
        element: <EmployeePayslipAddField />,
        children: [
          {
            path: "*",
            element: <EmployeeNotes />,
          },
        ],
      },
      {
        path: "RegularInputItemsAddField",
        element: <RegularInputItemsAddField />,
        children: [
          {
            path: "*",
            element: <EmployeeNotes />,
          },
        ],
      },
      {
        path: "emp-activity",
        element: <EmployeeActivity />
      },
      {
        path: "reinstate-employee",
        element: <ReinstateEmployee />
      },
      {
        path: "payroll",
        element: <PayrollManage />,
        children: [
          { path: "emp", element: <EmployeePayroll /> },
          { path: "regular-hours", element: <RegularHours /> },
          { path: "regular-hour", element: <EditRegularHour /> },
          { path: "basic-info", element: <EditEmployeeInfo /> },
          { path: "end-service", element: <EndService /> },
          { path: "probation", element: <Probation /> },

          { path: "manage-end-service", element: <ManageEndService /> },
          { path: "reinstate-employee", element: <ReinstateEmployee /> },
          { path: "activity", element: <LeaveActivity /> },
          { path: "record-taken", element: <LeaveRecordTaken /> },
          {
            path: "entitlement-policies",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <LeaveEntitlementPolicy />,
              },
            ]
          },
          { path: "adjusments", element: <LeaveAdjustments /> },
          { path: "take-ons", element: <LeaveTakeOns /> },
        ],
      },
      {
        path: "payroll",
        element: <Outlet />,
        children: [
          {
            path: "entitlement-policies/edit",
            element: <LeaveAllEntitlementPolicies />,
          }
        ]
      },
      { path: "edit-basic-salary", element: <EditBasicSalary /> },
      {
        path: "app-settings",
        element: <AppSettings />,
        children: [
          {
            path: "custom-items",
            element: <AppSettingsCustomItems />,
          },
          {
            path: "employer-details",
            element: <AppSettingsEmployeeDetails />,
          },
          {
            path: "employer-filing-details",
            element: <AppSettingsEmployeeFilingDetails />,
          },
          {
            path: "employee-numbers",
            element: <AppSettingsEmployeeNumbers />,
          },
          {
            path: "pay-frequencies",
            element: <AppSettingsPayFrequencies />,
          },
          { path: "payroll", element: <AppSettingsPayroll /> },
          { path: "giro-settings", element: <GiroSettings /> },
          {
            path: "payroll-calculations",
            children: [
              {
                path: "basic",
                element: <BasicPayrollSettings />,
              },
              {
                path: "cpf",
                element: <CPFPayrollSettings />,
              },
            ],
          },
          {
            path: "leave",
            children: [
              {
                path: "",
                element: <LeaveList />,
              },
            ],
          },
          {
            path: "custom-reports",
            children: [
              {
                path: "",
                element: <CustomReports />,
              },
            ],
          },
        ],
      },
      {
        path: "app-settings",
        element: <Outlet />,
        children: [
          {
            path: "leave",
            children: [
              {
                path: "new",
                element: <AddLeaveType />,
              },
              {
                path: "edit",
                element: <AddLeaveType />,
              },
              {
                path: "view",
                element: <DetailedLeaveComponent />,
              },
              {
                path: "entitlement-policy",
                children: [
                  {
                    path: "new",
                    element: <AddEntitlementPolicy />,
                  },
                  {
                    path: "edit",
                    element: <AddEntitlementPolicy />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "add-item",
        element: <AddCustomItems />,
      },
      {
        path: "approve",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <LeaveApproval />
          },
          {
            path: "details",
            element: <LeaveApprovalDetails />
          }
        ]
      },
      {
        path: "add-item-fields",
        element: <AddCustomeInputFields />,
        children: [
          {
            path: "*",
            element: <AddCustomeInputFields />,
          },
        ],
      },
      {
        path: "filing",
        element: <Reports />,
        children: [
          {
            path: "monthly",
            children: [
              {
                path: "",
                element: <CPFReports />,
              },
              {
                path: "new",
                element: <AddPenalty />,
              },
            ],
          },
          {
            path: "iras",
            children: [
              {
                path: "",
                element: <IRASFiling />,
              },
              {
                path: "edit",
                element: <EditIRAS />,
              },
            ],
          },
          {
            path: "ir21",
            children: [
              {
                path: "",
                element: <IRASFilingIR21 />,
              },
            ],
          },
        ],
      },
      {
        path: "filing/manual",
        children: [
          {
            path: "",
            element: <ManualFiling />,
          },
          {
            path: "edit",
            element: <EditFiling />,
          },
          {
            path: "add",
            element: <AddFiling />,
          },
        ],
      },
      {
        path: "filing/manual",
        children: [
          {
            path: "",
            element: <ManualFiling />,
          },
        ],
      },
      {
        path: "tms",
        element: <TMS />,
      },
      {
        path: "payslips",
        element: <EmployeePayslips />,
      },
      {
        path: "requests",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <EmployeeRequests />,
          },
          {
            path: "leave",
            element: <LeaveRecordTaken />,
          },
          {
            path: "info",
            element: <EditInfoUpdate />,
          },
        ],
      },
      {
        path: "payslip",
        children: [
          {
            path: "current",
            element: <PayslipReports />,
          },
          {
            path: "history",
            element: <PayslipReports />,
          },
          {
            path: "individual",
            element: <IndividualPayslipReports />,
          },
        ],
      },
      {
        path: "manage-template",
        children: [
          {
            path: "hr",
            children: [
              {
                path: "",
                element: <ManageHRTemplate />,
              },
              {
                path: "add",
                element: <AddHRTemplate />,
              },
              {
                path: "view",
                element: <ViewHRTemplate />,
              },
              {
                path: "edit",
                element: <AddHRTemplate />,
              },
            ],
          },
        ],
      },
      {
        path: "request",
        element: <DashboardLayout />,
        children: [
          {
            path: "cso",
            element: <CsoDashboard />,
          },
          {
            path: "reviewer",
            element: <ReviewerDashboard />,
          },
          {
            path: "finalizer",
            element: <FinalizerDashboard />,
          },
          {
            path: "data-entry",
            element: <DataEntryDashboard />,
          },
          {
            path: "completed",
            element: <CompletedDashboard />,
          },
        ],
      },
      {
        path: "request",
        element: <Outlet />,
        children: [
          {
            path: "add",
            element: <AddRequest />,
          },
          {
            path: "view",
            element: <ViewRequest />,
          },
        ],
      },
      {
        path: "reports",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <TimeReports />,
          },
          {
            path: "leave_report",
            element: <LeaveReport />,
          },
          {
            path: "leave_days_report",
            element: <LeaveDaysReport />,
          },
          {
            path: "leave_expiry",
            element: <LeaveExpiryReport />,
          },
          {
            path: "payslip_export",
            element: <PayslipExport />,
          },
          {
            path: "employee_basic_info",
            element: <BasicInfo />,
          },
          {
            path: "transaction_history_report",
            element: <TransactionHistory />,
          },
          {
            path: "mpr_full",
            element: <MonthlyPayrollReport />,
          },
          {
            path: "mpr_gross",
            element: <MonthlyPayrollReport />,
          },
          {
            path: "mpr_drn",
            element: <MonthlyPayrollReport />,
          },
          {
            path: "balances_loans_and_savings",
            element: <div><h2>Coming Soon...</h2></div>,
          },
          {
            path: "leave_liabilities",
            element: <div><h2>Coming Soon...</h2></div>,
          },
          // {
          //   path: "variance_report",
          //   element: <LeaveExpiryReport />,
          // },
          {
            path: "monthly_report",
            element: <MonthlyReport />,
          },
          {
            path: "ytd_payroll_report",
            element: <YTDPayrollReport />,
          },
          {
            path: "ytd_by_employees_report",
            element: <YTDEmployeesReport />,
          },
          {
            path: "ytd_by_company_report",
            element: <YTDCompanyReport />,
          },
          {
            path: "probation",
            element: <ProbationReport />,
          }
        ],
      },
      {
        path: "tellboss",
        element: <Outlet />,
        children: [
          {
            path: "payroll",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <PayrollList />,
              },
              {
                path: "change",
                element: <ChangePayroll />,
              },
              {
                path: "preview",
                element: <PayrollPreview />,
              },
            ],
          },
        ],
      },
      {
        path: "self-service",
        element: <SelfServiceHome />,
        children: [
          {
            path: "employee-users",
            element: <EmployeeUsers />,
          },
          {
            path: "settings/general",
            element: <GeneralSettings />,
          },
          {
            path: "settings/leave",
            element: <LeaveSettings />,
          },
        ],
      },
      
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export { authRouter };
