import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { BsPlusCircleFill } from "react-icons/bs";
import { GrDocumentPdf } from "react-icons/gr";

import "./payroll.css";
import { PayrollService } from "../../../_service";
import {
  CommonModal,
  DateListModal,
  Loader,
  PayslipModal,
  PayslipModalPDF,
  Tooltip,
} from "../../../components";
import { Notify, Utils } from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../_app";
import { updateEmployeeIntialState, updatePayrollId } from "../../../_features";
import { updateCurrentPayslipDate } from "../../../_features/employee";
import { CustomButton } from "../../../components/customButton";
import { Constant } from "../../../config";
import { AiFillDelete } from "react-icons/ai";
import { Activity } from "../../../_features/employee";
import { EmployeeService } from "../../../_service";
import moment from "moment";
import 'moment-timezone';
import CustomTooltip from "../../../components/CustomTooltip";
import LineChartComponent from "../component/LineChartComponent";
import useFirebaseAnalytics from "../../../hooks/useFirebaseAnalytics";
import { microAgent } from "../../../newRelicConfig";
// import moment from "moment";

const EmployeePayroll = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();


  const logFirebaseEvent = useFirebaseAnalytics();

  const user = useAppSelector((state) => state.user);
  const employee = useAppSelector((state) => state.employee);
  const emp = useAppSelector((state) => state.employee.currentEmployee);
  const defaultCompnayId = useAppSelector(
    (state) => state.user.defaultCompnayId
  );
  const payrollData = useAppSelector((state) => state.employee);

  const [payrollModal, setPayrollModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false)

  const [payslipModal, setPayslipModal] = useState(false);
  const [payslipPDFModal, setPayslipPDFModal] = useState(false);
  const [sending, setSending] = useState(false);
  const [showDisabledNotification, setShowDisabledNotification] =
    useState(false);
  const [isResetModal, setIsResetModal] = useState(false);
  const [showFinalizeStatusError, setShowFinalizeStatusError] = useState(false);
  const [showNetPayNegativeError, setShowNetPayNegativeError] = useState(false);
  const [activityList, setActivityList] = useState<Activity[]>([]);
  const [payslipHistory, setPayslipHistory] = useState<any[]>([]);

  const handlePayslipInputAddClick = () => {
    navigate("/dashboard/payslip-calculation");
  };
  const handleRegularInputAddClick = () => {
    navigate("/dashboard/regular-input-item");
  };

  const handleNotesClick = () => {
    navigate("/dashboard/notes");
  };

  const getPreviewData = async () => {
    setPayslipModal(true);
  };
  const getPreviewPDFData = async () => {
    setPayslipPDFModal(true);
  };

  const get = async (reload: boolean = false) => {
    if (state?.reload || reload) {
      try {
        setSending(true);
        let url = employee.currentEmployee.id;
        url += "&company_id=" + user.defaultCompnayId;
        if (employee.payslipCurrentMonth) {
          url += "&payslip_date=" + employee.payslipCurrentMonth;
        } else if (employee?.currentEmployee?.payslip_date) {
          url += "&payslip_date=" + employee.currentEmployee.payslip_date;
        }
        const res = await PayrollService._getPayrollDashboard(url);
        if (res?.status === 200) {
          dispatch(updateEmployeeIntialState(res.data));
          // if (res?.data?.month_salary_info.total_salary === "0.00") {
          //   enablePayslip(
          //     res?.data?.employee_info?.id,
          //     res?.data?.last_date_of_current_month
          //   );
          // }
          if (!res.data.regular_working_hours) {
            Notify("Please update regular hours!");
            navigate("/dashboard/payroll/regular-hour");
          }
        }
        setSending(false);
      } catch (e: any) {
        setSending(false);
        if (e.response?.status === 401) {
          // disaptch(resetUser());
        } else {
          console.log('payslipErrorObj', e)
          if(e.response.data?.is_visible === "1") {
            Utils.showErrorMessage(e);
          } else {
            Notify(Constant.common.something_went_wrong, 0);
          }
          navigate("/dashboard/employee-payroll");
        }
      }
    }
  };
  const handleUpdateData = (date: string) => {
    dispatch(updateCurrentPayslipDate(date));
  };

  const resetPayslipData = async () => {
    const dataToReset = {
      employee: employee?.currentEmployee?.id || employee?.employee_info?.id,
      company: defaultCompnayId,
      payslip_date: employee.last_date_of_current_month,
    };
    try {
      const { status } = await PayrollService._resetPayslipData(dataToReset);
      if (status === 200) {
        Notify("Payslip reset successful", 1);
        get();
        getEmployeeActivityList();
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsResetModal(() => false);
  };

  const handleFinalizeStatus = async (status: boolean = false) => {
    let showError = true;
    setLoading(true);

    ["income", "allowance", "deductions", "reimbursement"].forEach((field) => {
      if (
        payrollData?.payslip_info?.hasOwnProperty(field) &&
       ( parseFloat(
          Utils.getPriceFromString(payrollData?.payslip_info?.[field]?.total)
        ) !== 0 && !Number.isNaN(parseFloat(
          Utils.getPriceFromString(payrollData?.payslip_info?.[field]?.total)
        )) )
      ) {
        console.log("theNetPay", parseFloat(
          Utils.getPriceFromString(payrollData?.payslip_info?.net_pay)
        ))
        showError = false;
      }
    });

    //We'll show the error only in the case of "Finalizing" not for unfinalizing
    if (status && showError) {
      // Notify(
      //   "We cannot finalise this payslip. Please add Regular Input or Payslip Input!",
      //   0
      // );
      setShowFinalizeStatusError(true);
      setLoading(false);
    }

    if(( Number.isNaN(parseFloat(
      Utils.getPriceFromString(payrollData?.payslip_info?.net_pay)
    )) ) || parseFloat(
      Utils.getPriceFromString(payrollData?.payslip_info?.net_pay)
    ) < 0){
      setShowNetPayNegativeError(true);
      showError = true;
      setLoading(false);
    }

    
    

    try {
      if (status && !showError) {
        const res = await PayrollService._getNotes({
          employee: employee?.currentEmployee?.id || employee.employee_info.id,
          company: defaultCompnayId,
          payslip_date: employee.last_date_of_current_month,
        });
        if (res?.data?.payslip_id) {
          const _res = await PayrollService._payslipFinalizeState(
            res?.data?.payslip_id,
            { finalize: status }
          );
          if (_res?.status === 200) {
            Notify(_res?.data?.message, 1);
            // Firabse log here
            console.log("Finalizing", _res?.data?.message);
            let key = "finalize";
            let data = {
              user_id: user.id,
              name: user.name,
              // device_fingerprint: "using_client_js",
              event_date_time: moment().utc().format("DD/MM/YYYY hh:mm A"),
              platform: "web",
            };
            // logFirebaseEvent(key, data);
            microAgent.addPageAction(key,data)            
            setLoading(false);
          }
          get(true);
          getEmployeeActivityList();
        }
        dispatch(updatePayrollId(res?.data?.payslip_id || ""));
      } else if (!status) {
        console.log(employee);
        const _res = await PayrollService._payslipFinalizeState(
          employee?.emp_payslip?.id,
          { finalize: false }
        );
        if (_res?.status === 200) {
          Notify(_res?.data?.message, 1);
          // firebase log here
          console.log("Unfinalizing", _res?.data?.message);
          let key = "unfinalize";
          let data = {
            user_id: user.id,
            name: user.name,
            // device_fingerprint: "using_client_js",
            event_date_time: moment().utc().format("DD/MM/YYYY hh:mm A"),
            platform: "web",
          };
          // logFirebaseEvent(key, data);
          microAgent.addPageAction(key,data)
          setLoading(false);
        }
        const res = await PayrollService._getNotes({
          employee: employee?.currentEmployee?.id || employee.employee_info.id,
          company: defaultCompnayId,
          payslip_date: employee.last_date_of_current_month,
        });
        get(true);
        getEmployeeActivityList();
        dispatch(updatePayrollId(res?.data?.payslip_id || ""));
      }
    } catch (e: any) {
      setLoading(false);
      if (e?.response?.data?.detail) {
        Notify(e?.response?.data?.detail, 0);
      }
    }
  };

  const enablePayslip = async (empId: any, date: string) => {
    try {
      const res = await PayrollService._getNotes({
        employee: empId,
        company: defaultCompnayId,
        payslip_date: date,
      });
      dispatch(updatePayrollId(res?.data?.payslip_id || ""));
    } catch (e: any) {}
  };

  const getEmployeeActivityList = async () => {
    const empId = emp.id;
    if (empId) {
        try {
            const response = await EmployeeService._getEmployeeChangeLogs(empId)
            setActivityList(response.data.data);
        } catch (error) {
            console.error('Error fetching activity list:', error);
        }
    }
};
  
 const getEmployeePayslipHistory = async () => {
    const empId = emp.id;
    if (empId) {
        try {
            const response = await EmployeeService._getEmployeePayslipHistory(user.defaultCompnayId, empId)
            console.log('history', response?.data?.data)
            setPayslipHistory(response.data.data);
        } catch (error) {
            console.error('Error fetching history:', error);
        }
    }
};

useEffect(() => {
    getEmployeeActivityList()
    .then(()=>getEmployeePayslipHistory())
}, [emp.id]);

  useEffect(() => {
    get(true);
    enablePayslip(
      employee?.currentEmployee?.id || employee?.employee_info?.id,
      employee?.payslipCurrentMonth || employee.last_date_of_current_month
    );
  }, [employee.payslipCurrentMonth]);

  return (
    <div className="employee-payroll" style={{ position: "relative" }}>
      {sending && <Loader />}
      <DateListModal
        updateData={handleUpdateData}
        data={payrollData?.payslip_month_list}
        open={payrollModal}
        handleClose={() => setPayrollModal(false)}
      />

      <PayslipModal
        data={employee?.payslip_info}
        open={payslipModal}
        handleClose={() => setPayslipModal(false)}
      />
      <PayslipModalPDF
        data={employee?.payslip_info}
        open={payslipPDFModal}
        handleClose={() => setPayslipPDFModal(false)}
      />
      <div className="wrap-box">
        <p className="text">
          <b>Pay Frequency: </b>
          {employee.employee_info?.pay_frequency?.name || ""},
          <b> Payment method: </b>
          {employee.employee_info?.payment_method?.payment_method_name || ""},
          <b> Number: </b>
          {employee.employee_info?.emp_number || ""}
          <b>, Joining Date: </b>
          {Utils._dateToShow(employee.employee_info?.date_of_appointment) || ""}
          {state?.lastDay && (
            <>
              <b>, Last day of service: </b>
              {Utils._dateToShow(state?.lastDay) || ""}
            </>
          )}
        </p>
      </div>
      <div className="card-wrapper">
        <div className="card-container">
          <div className="card-header">
            <p>Regular Inputs</p>
            {user.permission === "full" && (
              <CustomButton
                type="onbackground"
                size="small"
                width="90px"
                title="Add"
                onClick={() => {
                  !employee?.emp_payslip?.is_finalized
                    ? handleRegularInputAddClick()
                    : setShowDisabledNotification(() => true);
                }}
                icon={<BsPlusCircleFill />}
              />
            )}
          </div>
          <div className="card-body">
            {payrollData?.regular_input_data?.length > 0 ? (
              payrollData?.regular_input_data?.map((item: any) => {
                return Object.keys(item)?.filter((_) => _ !== "item_id")[0] ===
                  "Basic Salary" ? (
                  <div key={item.item_id} className="card-row-container">
                    <NavLink
                      className="card-row-link"
                      to={{
                        pathname:
                          "/dashboard/RegularInputItemsAddField/" +
                          item.item_id,
                      }}
                      state={item}
                      onClick={(e) => {
                        if (employee?.emp_payslip?.is_finalized) {
                          e.preventDefault();
                          setShowDisabledNotification(() => true);
                        }
                      }}
                      // key={item.item_id}
                    >
                      {Object.keys(item)?.filter((_) => _ !== "item_id")[0]}
                    </NavLink>
                    {item?.[
                      Object.keys(item)?.filter((_) => _ !== "item_id")[0]
                    ]?.filter((_: any) => _.field_id === "hourly_paid")[0]
                      ?.value === "True" ? (
                      <div key={item.item_id}>
                        <p
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Hourly paid
                        </p>
                        <div className="card-row">
                          <p>Hourly Rate</p>
                          <p>
                            {
                              item?.[
                                Object.keys(item)?.filter(
                                  (_) => _ !== "item_id"
                                )[0]
                              ]?.filter(
                                (_: any) => _.field_id === "hourly_rate"
                              )[0]?.value
                            }
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div key={item.item_id}>
                        <div className="card-row">
                          <p>Fixed Amount</p>
                          <p>
                            {"$ " +
                              item?.[
                                Object.keys(item)?.filter(
                                  (_) => _ !== "item_id"
                                )[0]
                              ]?.filter(
                                (_: any) => _.field_id === "fixed_amount"
                              )[0]?.value}
                          </p>
                        </div>
                        {item?.[
                          Object.keys(item)?.filter((_) => _ !== "item_id")[0]
                        ]?.filter(
                          (_: any) =>
                            _.field_id === "override_caluclated_hourly_rate"
                        )[0]?.value === "True" ? (
                          <div key="Override calculated hourly rate">
                            <p
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Override calculated hourly rate
                            </p>
                            <div className="card-row">
                              <p>Rate Override</p>
                              <p>
                                {"$ " +
                                  item?.[
                                    Object.keys(item)?.filter(
                                      (_) => _ !== "item_id"
                                    )[0]
                                  ]?.filter(
                                    (_: any) => _.field_id === "rate_override"
                                  )[0]?.value}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div key={item.item_id} className="card-row-container">
                    <NavLink
                      className="card-row-link"
                      to={{
                        pathname:
                          "/dashboard/RegularInputItemsAddField/" +
                          item.item_id,
                      }}
                      state={item}
                      key={item.item_id}
                      onClick={(e) => {
                        if (employee?.emp_payslip?.is_finalized) {
                          e.preventDefault();
                          setShowDisabledNotification(() => true);
                        }
                      }}
                    >
                      {Object.keys(item)?.filter((_) => _ !== "item_id")[0]}
                    </NavLink>
                    {item?.[
                      Object.keys(item)?.filter((_) => _ !== "item_id")[0]
                    ]?.map((_: any) => {
                      return _.value === "False" ? null : (
                        <div key={_.field_id} className="card-row">
                          <p
                            style={{
                              fontWeight:
                                _.value === "True" ? "bold" : "normal",
                            }}
                          >
                            {_.field_name}
                          </p>
                          <p>
                            {_.value === "True" || _.value === "False"
                              ? ""
                              : Utils.isNumberWithComma(_.value) && !_.value.includes("%")
                              ? "$ " + _.value
                              : _.value}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <div className="card-row-container">
                Add basic salary info by clicking on "Add" button in Regular
                Inputs.
              </div>
            )}
          </div>
        </div>
        <div className="card-container">
          <div className="card-header">
            <p>
              Payslip Inputs:{" "}
              {Utils.getFormatedDate(
                employee?.payslipCurrentMonth ||
                  payrollData?.last_date_of_current_month
              )}
            </p>
            {user.permission === "full" && (
              <CustomButton
                type="onbackground"
                size="small"
                width="90px"
                title="Add"
                onClick={() => {
                  !employee?.emp_payslip?.is_finalized
                    ? handlePayslipInputAddClick()
                    : setShowDisabledNotification(() => true);
                }}
                icon={<BsPlusCircleFill />}
              />
            )}
          </div>
          <div className="card-body">
            {payrollData?.payslip_calculation_items?.length > 0 ? (
              payrollData?.payslip_calculation_items?.map((item: any) => {
                return (
                  <div key={item.name} className="card-row-container">
                    <NavLink
                      className="card-row-link"
                      to={{
                        pathname:
                          "/dashboard/EmployeePayslipAddField/" +
                          item.code +
                          "/" +
                          item.pc_id,
                      }}
                      state={item}
                      key={item.name}
                      onClick={(e) => {
                        if (employee?.emp_payslip?.is_finalized) {
                          e.preventDefault();
                          setShowDisabledNotification(() => true);
                        }
                      }}
                    >
                      {item.name}
                    </NavLink>
                    <div>
                      {item.name === "Termination Lump Sums" ? (
                        <>
                          <div className="card-row">
                            <p
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "normal",
                              }}
                            >
                              Gratuity input
                            </p>
                            <p>
                              ${" "}
                              {
                                Object.entries(item?.attributes)
                                  .filter((_) => _[0] === "gratuity_input")
                                  .flat()[1] as string
                              }
                            </p>
                          </div>
                          <div className="card-row">
                            <p
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "normal",
                              }}
                            >
                              Notice pay input
                            </p>
                            <p>
                              ${" "}
                              {
                                Object.entries(item?.attributes)
                                  .filter((_) => _[0] === "notice_pay_input")
                                  .flat()[1] as string
                              }
                            </p>
                          </div>
                          <div className="card-row">
                            <p
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "normal",
                              }}
                            >
                              Ex gratia input
                            </p>
                            <p>
                              ${" "}
                              {
                                Object.entries(item?.attributes)
                                  .filter((_) => _[0] === "ex_gratia_input")
                                  .flat()[1] as string
                              }
                            </p>
                          </div>
                          <div className="card-row">
                            <p
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "normal",
                              }}
                            >
                              Termination others input
                            </p>
                            <p>
                              ${" "}
                              {
                                Object.entries(item?.attributes || {})
                                  ?.filter(
                                    (_) => _[0] === "termination_others_input"
                                  )
                                  .flat()[1] as string
                              }
                            </p>
                          </div>
                          <div className="card-row">
                            <p
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "normal",
                              }}
                            >
                              Compensation for Loss of Office Amount
                            </p>
                            <p>
                              ${" "}
                              {
                                Object.entries(item?.attributes || {})
                                  ?.filter(
                                    (_) =>
                                      _[0] ===
                                      "compensation_for_loss_of_office_amount"
                                  )
                                  .flat()[1] as string
                              }
                            </p>
                          </div>

                          {(Object.entries(item?.attributes || {})
                            ?.filter(
                              (_) => _[0] === "approval_obtained_from_iras"
                            )
                            .flat()[1] as boolean) ? (
                            <>
                              <div className="card-row">
                                <p
                                  style={{
                                    textTransform: "capitalize",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Approval Obtained from IRAS
                                </p>
                                <p></p>
                              </div>
                              <div className="card-row">
                                <p
                                  style={{
                                    textTransform: "capitalize",
                                    fontWeight: "normal",
                                  }}
                                >
                                  Iras approval date
                                </p>
                                <p>
                                  {
                                    Object.entries(item?.attributes || {})
                                      ?.filter(
                                        (_) => _[0] === "iras_approval_date"
                                      )
                                      .flat()[1] as string
                                  }
                                </p>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : (item?.code === "employer_loan" || item?.code === "savings") ? Object.entries(item?.attributes)?.map((_: any) => {
                        return (
                          <div key={_[0]} className="card-row">
                            <p
                              style={{
                                textTransform: "capitalize",
                                fontWeight:
                                  typeof _[1] === "boolean"
                                    ? "bold"
                                    : "normal",
                                    fontStyle:
                                    (_[0]==="closing_balance")
                                    ?"italic"
                                    :"normal" , 
                              }}
                            >
                            
                              { typeof _[1] === "boolean" ? (_[1] ? _[0]?.replaceAll("_", " ") : "") : _[0]?.replaceAll("_", " ") }
                            </p>
                      
                            <p>
                              {typeof _[1] === "boolean"
                                ? ""
                                : Utils.isNumberWithComma(_[1])
                                ? "$ " + _[1]
                                : _[1]}
                            </p>
                          </div>
                        );
                      }) : (
                        Object.entries(item?.attributes)?.map((_: any) => {
                          return (
                            <div key={_[0]} className="card-row">
                              <p
                                style={{
                                  textTransform: "capitalize",
                                  fontWeight:
                                    typeof _[1] === "boolean"
                                      ? "bold"
                                      : "normal",
                                   
                                }}
                              >
                                
                                {_[0]?.replaceAll("_", " ")}
                              </p>
                              <p>
                                {_[0] === true || _[0] === false
                                  ? ""
                                  : _[0]?.replaceAll("_", " ") ===
                                      "normal hours" ||
                                    _[0]?.replaceAll("_", " ") === "hours"
                                  ? _[1]
                                  : Utils.isNumberWithComma(_[1])
                                  ? "$ " + _[1]
                                  : _[1]}
                              </p>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="card-row-container">
                There are no payslip-specific inputs for this payslip.
              </div>
            )}
          </div>
        </div>
        <div className="card-container">
          <div className="card-header">
            <p>Payslip</p>
            <div style={{ display: "flex", gap: 20 }}>
              <CustomButton
                type="onbackground"
                size="small"
                width="120px"
                title={Utils.getFormatedDate(
                  employee?.payslipCurrentMonth ||
                    payrollData?.last_date_of_current_month
                )}
                onClick={() => setPayrollModal(true)}
              />
              <Tooltip text="Reset" del>
                <CustomButton
                  type="secondary"
                  size="small"
                  width="30px"
                  title={""}
                  onClick={() =>
                    employee?.emp_payslip?.is_finalized
                      ? setShowDisabledNotification(() => true)
                      : setIsResetModal(() => true)
                  }
                  del
                  icon={<AiFillDelete />}
                />
              </Tooltip>
            </div>
          </div>
          <div className="card-body">
            <div>
              {payrollData?.payslip_info?.income?.percentage_rate ? (
                <div className="card-row-container">
                  <div className="card-row">
                    <p>
                      Pro-Rate Percentage :{" "}
                      <span style={{ color: "#059bbf" }}>
                        {payrollData?.payslip_info?.income?.percentage_rate}
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}
              {["income", "allowance", "deductions", "reimbursement"].map(
                (field: string) =>
                  payrollData?.payslip_info?.hasOwnProperty(field) && (
                    <div className="card-row-container">
                      <div className="card-row">
                        <b>{Utils.formateStringWithUnderscore(field)}</b>
                        <b>{payrollData?.payslip_info[field]?.total}</b>
                      </div>
                      {payrollData?.payslip_info[field]?.data?.map(
                        (_item: any) => {
                          return (
                            <div key={_item.name} className="card-row">
                              <p>{_item?.name || ""}</p>
                              <p>{_item?.value || ""}</p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )
              )}
              {/* {Object.entries(payrollData?.payslip_info || {}).map(
                (item: any) => {
                  return [
                    "payslip_date",
                    "net_pay",
                    "cpf_details",
                    "benefit",
                    "employer_contribution",
                  ].includes(item[0]) ? null : (
                    <div className="card-row-container">
                      <div className="card-row">
                        <b>{Utils.formateStringWithUnderscore(item[0])}</b>
                        <b>{item[1]?.total}</b>
                      </div>
                      {item[1]?.data?.map((_item: any) => {
                        return (
                          <div key={_item.name} className="card-row">
                            <p>{_item?.name || ""}</p>
                            <p>{_item?.value || ""}</p>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              )} */}
            </div>
            <div className="card-row-container">
              <div className="card-row">
                <b>Net Pay</b>
                <b>{"$" + payrollData?.payslip_info?.net_pay}</b>
              </div>
            </div>
            <div className="card-row-container">
              <div className="card-row">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {user.permission === "full" && (
                    <CustomButton
                      title={
                        employee?.emp_payslip?.is_finalized
                          ? "Undo Finalise"
                          : "Finalise"
                      }
                      onClick={() =>
                        handleFinalizeStatus(
                          employee?.emp_payslip?.is_finalized ? false : true
                        )
                      }
                      styles={{
                        maxWidth: employee?.emp_payslip?.is_finalized
                          ? 120
                          : 70,
                      }}
                      size="small"
                      disable={loading}
                      // disable={
                      //   payrollData?.payslip_info?.net_pay === "0.00" &&
                      //   !employee?.emp_payslip?.is_finalized
                      // }
                    />
                  )}
                  {user.permission === "full" && (
                    <CustomButton
                      type="secondary"
                      title="Note"
                      width="70px"
                      onClick={handleNotesClick}
                      size="small"
                    
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <p
                    className="preview-link"
                    onClick={getPreviewData}
                    style={{ marginRight: ".5rem" }}
                  >
                    More
                  </p>
                  <p
                    className="preview-link"
                    onClick={getPreviewPDFData}
                    style={{ width: "80px" }}
                  >
                    <IconContext.Provider
                      value={{
                        color: "#000",
                        size: "16px",
                      }}
                    >
                      <GrDocumentPdf />
                    </IconContext.Provider>{" "}
                    Preview
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        handleClose={() => setShowDisabledNotification(() => false)}
        open={showDisabledNotification}
        updateData={() => setShowDisabledNotification(() => false)}
        body="Payslip is finalized, you cannot add a new entry or modify an existing entry. Please undo finalise to make new changes"
        heading="Can't Update Data"
        conformButtonText="Close"
        isCancelButton={false}
      />
      <CommonModal
        handleClose={() => setIsResetModal(() => false)}
        open={isResetModal}
        updateData={() => resetPayslipData()}
        body="Are you sure you want to reset the payslip?"
        heading="Reset Payslip"
        conformButtonText="Confirm"
      />
      <CommonModal
        handleClose={() => setShowFinalizeStatusError(() => false)}
        open={showFinalizeStatusError}
        updateData={() => setShowFinalizeStatusError(() => false)}
        body="We cannot finalise this payslip. Please add Regular Input or Payslip Input!"
        heading="Please add Regular Input or Payslip Input!"
        conformButtonText="Okay"
      />
      <CommonModal
        handleClose={() => setShowNetPayNegativeError(() => false)}
        open={showNetPayNegativeError}
        updateData={() => setShowNetPayNegativeError(() => false)}
        body="We cannot finalise this payslip. Net Pay must be Positive or Zero!"
        heading="Net Pay must be Positive or Zero!"
        conformButtonText="Okay"
        isCancelButton={false}
      />

      <div className="divider"></div>

      <div className="wrap-box">
				<p className="heading">Recent Activity</p>
        {
          activityList?.length > 0 ? (
            <><ul>
              {activityList?.slice(0, 10).map(activity => (
                // <CustomTooltip title={Utils.formatTimestamp(activity.time)} placement="top-end"></CustomTooltip>

                <li key={activity.id} style={{width: 'fit-content'}}>
                  {/* {activity.description} ({Utils.formatTimestamp(activity.time)} by {activity.full_name}) */}
                  {/* {activity.description} ({moment(activity.time).format("DD-MM-YYYY hh:mm A")} by {activity.full_name}) */}
                  {activity.description} ({moment(moment.tz(activity.time, 'Asia/Singapore')).format("DD-MM-YYYY hh:mm A")} by {activity.full_name})
                  {/* {activity.description} ({moment(moment.tz(activity.time, 'Asia/Kolkata')).format("DD-MM-YYYY hh:mm A")} by {activity.full_name}) */}
                </li>
                
              ))}
            </ul><NavLink
              className="link"
              to={{
                pathname: "/dashboard/emp-activity"
              }}
            >
                More
              </NavLink></>

          ) : (
            <p className="text">No recent activities yet.</p>
          )
        }

			</div>

      <div className="divider"></div>

			<div className="wrap-box">
				<p className="heading">Payslip History</p>

        <div style={{width: "50rem", height: "20rem", marginTop: '1rem'}}>
        <LineChartComponent data={payslipHistory} />
        </div>


			</div>
    </div>
  );
};

export default EmployeePayroll;
