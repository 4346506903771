import XLSX from "xlsx";
import { Utils } from "../../utils";

const extractDataFromCsv = (dataObj: any) => {
  const {data, periodEnding, paymentMethod} = dataObj
  const companyName = data?.[0]?.employee?.company?.name
  const csvTitle = [
    [`${companyName}`],
    ["Period Ending:", `${Utils._dateToShow(periodEnding)}`],
    ["Pay Frequency", `Monthly, ${Utils.getMonthNameWithYear(periodEnding)}`],
    ["Payment Method:", paymentMethod],
    ["Pay Point:", "Unassigned"],
  ];

  const csvTableHeaders =
    paymentMethod === "GIRO"
      ? [
          "Name",
          "Number",
          "Amount",
          "Bank",
          "Account Type",
          "Account No.",
          "Branch Code",
        ]
      : ["Name", "Number", "Amount"];

  const csvPayslipData =
    paymentMethod === "GIRO"
      ? data
          ?.sort((a: any, b: any) =>
            a.employee.user.name.localeCompare(b.employee.user.name)
          )
          ?.map((employeeData: any) => {
            return [
              employeeData?.employee?.user?.name || "",
              employeeData?.employee?.emp_number || "",
              Utils.prependDollar(employeeData?.payslip_info.net_pay || ""),
              employeeData?.employee?.bank_detail?.[0]?.bank_name || "-",
              "Savings",
              employeeData?.employee?.bank_detail?.[0]?.bank_account_number ||
                "-",
              employeeData?.employee?.bank_detail?.[0]?.bank_branch_code || "-",
            ];
          })
      : data
          ?.sort((a: any, b: any) =>
            a.employee.user.name.localeCompare(b.employee.user.name)
          )
          ?.map((employeeData: any) => {
            return [
              employeeData?.employee?.user?.name || "",
              employeeData?.employee?.emp_number || "",
              Utils.prependDollar(employeeData?.payslip_info.net_pay || ""),
            ];
          });

  const totalNetPay: any = data?.reduce(
    (accumulator: any, cash: any) =>
      parseFloat(
        Utils.isNumberWithComma(`${cash?.payslip_info?.net_pay}`)
          ? Utils.getPriceFromString(`${cash?.payslip_info?.net_pay}`)
          : "0.00"
      ) + accumulator,
    0
  );

  const csvTotalRow = ["Total", "", `${Utils.prependDollar(parseFloat(totalNetPay).toFixed(2))}`];
  const combinedData = [
    ...csvTitle,
    [],
    csvTableHeaders,
    ...csvPayslipData,
    [],
    csvTotalRow,
  ];

  return combinedData;
}

export const exportExcel = (paymentMethodArr: any, fileName: string) => {
  const workbook = XLSX.utils.book_new();

  // Add a worksheet with combined data
  paymentMethodArr.forEach((paymentMethodObj: any) => {
    if(paymentMethodObj.data && paymentMethodObj.data.length > 0) {
      const combinedData = extractDataFromCsv(paymentMethodObj)
      const combinedWorksheet = XLSX.utils.aoa_to_sheet(combinedData);
      XLSX.utils.book_append_sheet(workbook, combinedWorksheet, `Payment - ${paymentMethodObj.paymentMethod}`);
      const numCols = 8; // Get the number of columns
      combinedWorksheet["!cols"] = Array(numCols).fill({ width: 20 });
    }
  });

  XLSX.writeFile(workbook, `${fileName}.xlsx`, {
    compression: true,
  });
};
