import { useLocation, useNavigate } from "react-router-dom";
import {
  CommonModal,
  CustomButton,
  CustomDateField,
  CustomNumberField,
  CustomSelectField,
  CustomeCkeckboxField,
  Loader,
  NavigateBack,
} from "../../../components";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { Notify, Utils } from "../../../utils";
import { useEffect, useState } from "react";
import { LoaderContainer } from "../../../CommonStyles";
import { PayrollService } from "../../../_service";
import { Constant } from "../../../config";
import { TableHeader } from "../../../components/table";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableRow } from "../../payslip/PayslipStyles";
import { TableColumn } from "../../users/styles";
import { useAppSelector } from "../../../_app";
import { DashboardService } from "../../../_service/DashboardService";
import { useDispatch } from "react-redux";
import { updateEmployeeIntialState } from "../../../_features";
import { clone } from "lodash";

const ChangePayroll = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [payslipData, setPayslipData] = useState<any>([]);
  const [confirmUpdate, setConfirmUpdate] = useState<boolean>(false);
  const [payslipInputDataToChange, setPayslipInputDataToChange] = useState<any>(
    []
  );
  const [regularInputDataToChange, setRegularInputDataToChange] = useState<any>(
    []
  );
  const [requestType, setRequestType] = useState<any>({
    CP : "Change Pay Item"
  });
  const [selectedType, setSelectedType] = useState<string>("CP");
  const [showEndService, setShowEndService] = useState<boolean>(false);
  const [reason, setReason] = useState("");
  const [lastDayOfService, setLastDayOfService] = useState();
  const [requireFormIr21, setRequireFormIr21] = useState<boolean>(false);
  const [reasons, setReasons] = useState([]);

  // const [payrollPreviewData, setPayrollPreviewData] = useState<any>(JSON.parse(localStorage.getItem('payrollPreview') || '{}'));
  const [payrollPreviewData, setPayrollPreviewData] = useState<any>({});

  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);
  const employeeId = useAppSelector((state) => state.employee.employee_info.id);

  const tableHeaders = [
    {
      name: "Category",
      width: "30%",
    },
    {
      name: "Old Value",
      width: "30%",
    },
    {
      name: "New Value",
      width: "30%",
    },
  ];

  const getPayslipData = async () => {
    try {
      const url = `${state?.payrollItem?.employee?.id}&company_id=${state?.payrollItem?.company}&payslip_date=${state?.payrollItem?.payslip_date}`;
      const { status, data } = await PayrollService._getPayrollDashboard(url);

      if (status === 200) {
        dispatch(updateEmployeeIntialState(data));
        setPayslipData(() => data);

        // To populate the data from local storage if it exists
        if(state?.hasPendingChanges) {
          let storedData = JSON.parse(localStorage.getItem('payrollPreview') || '{}')
          let regularInputDataFromLocalStorage: any = JSON.parse(storedData[state?.empId]?.regularInputDataToChange || '[]');
          let payslipInputDataFromLocalStorage: any = JSON.parse(storedData[state?.empId]?.payslipInputDataToChange || '[]');
          
          setPayslipInputDataToChange(() => [ ...payslipInputDataFromLocalStorage]);
          setRegularInputDataToChange(() => [ ...regularInputDataFromLocalStorage]);
        
        } else {
          const payslipInputData = data?.payslip_calculation_items?.map(
            (data: any) => ({
              code: data?.code,
              name: data?.name,
              value:
                data?.attributes?.quantity?.replace(/,/g, "") ||
                data?.attributes?.amount?.replace(/,/g, "") ||
                "0.00",
              new_value:
                data?.attributes?.quantity?.replace(/,/g, "") ||
                data?.attributes?.amount?.replace(/,/g, "") ||
                "0.00",
              field_code: data?.attributes?.quantity ? "quantity" : "amount",
              field_name: data?.attributes?.quantity ? "Quantity" : "Amount",
              is_amount: !data?.attributes?.quantity,
            })
          );
          let regularInputData: any = [];
          data?.regular_input_data?.map((regularInputDataBe: any) => {
            const keyToSearch = Object.keys(regularInputDataBe)[1];
            const regularInputDataUpdate = regularInputDataBe[keyToSearch]
              .filter(
                (inputData: any) =>
                  inputData.value !== "False" &&
                  inputData.value !== "" &&
                  inputData.value !== "0.00"
              )
              .map((inputData: any) => ({
                code: inputData?.item_id,
                field_code: inputData?.field_id,
                name: `${inputData?.item_name}`,
                value: inputData.value?.replace(/,/g, ""),
                new_value: inputData.value?.replace(/,/g, ""),
                field_name: inputData?.field_name,
                is_amount: true,
              }));
            regularInputData = [...regularInputData, ...regularInputDataUpdate];
          });
          setPayslipInputDataToChange(() => payslipInputData);
          setRegularInputDataToChange(() => regularInputData);
        }

        setIsLoading(() => false);
      }

      
    } catch (error:any) {
      Utils.showErrorMessage(error);
      setIsLoading(() => false);
    }
  };

  const handlePayslipInputChange = (code: any, value: any) => {
    const index = payslipInputDataToChange.findIndex(
      (dataCh: any) => dataCh.code === code
    );

    const updatedData = [...payslipInputDataToChange];
    updatedData[index] = { ...updatedData[index], new_value: value };
    setPayslipInputDataToChange(updatedData);
  };

  const handleRegularInputChange = (code: any, value: any) => {
    const index = regularInputDataToChange.findIndex(
      (dataCh: any) => dataCh.field_code === code
    );
    const updatedData = [...regularInputDataToChange];
    updatedData[index] = { ...updatedData[index], new_value: value };
    setRegularInputDataToChange(updatedData);
  };

  const getRequestTypes = async () => {
    try {
      const { data, status } =
        await DashboardService._getSelectedRequestTypes();

      if (status === 200) {
        setRequestType(() => data.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const getReasons = async () => {
    try {
      const { data } = await PayrollService._getEndServiceReasons();
      setReasons(() => data.data);
    } catch (e: any) {
      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  const updatePreviewData = (data: any, empId: any) => {
    let updatedData = clone(payrollPreviewData);
    updatedData[empId] = data;
    setPayrollPreviewData(updatedData);
  }

  console.log('thePayrollPreviewData', payrollPreviewData)
  console.log("locationData", state);

  const sendForChange = async () => {
    const formData = new FormData();
    let changesObj = {};
    if (selectedType === "ES") {
      const request_json = [
        {
          name: "Company Name",
          value: user.defaultCompany,
        },
        {
          name: "Employee Name",
          value: state?.payrollItem?.employee?.name,
        },
        {
          name: "Employee Number",
          value: state?.payrollItem?.employee?.emp_number,
        },
        {
          name: "Last Day of Service",
          value: Utils._dateToShow(lastDayOfService),
        },
        {
          name: "Reason for termination",
          value: `${
            (
              reasons[
                reasons.findIndex((re: any) => re.id === parseInt(reason))
              ] as any
            ).reason
          }`,
        },
        {
          name: "Requires form Ir21",
          value: requireFormIr21 ? "Yes" : "No",
        },
      ];
      // const request_json = {
      //   "company_name": user.defaultCompany,
      //   "employee_name": state?.payrollItem?.employee?.name,
      //   "employee_number": state?.payrollItem?.employee?.emp_number,
      //   "last_day_of_service": Utils._dateToShow(lastDayOfService),
      //   "reason_for_termination": `${(reasons[reasons.findIndex((re: any) => re.id === parseInt(reason))] as any).reason}`,
      //   "requires_for_ir21": requireFormIr21? "Yes": "No"
      // }

      formData.append("company", user.defaultCompnayId);
      formData.append("request_type", "ES");
      formData.append("name", user.name);
      formData.append("email", user.email);
      formData.append("json_data", JSON.stringify(request_json));

      changesObj = request_json;
    } else {
      const request_json = [
        ...regularInputDataToChange,
        ...payslipInputDataToChange,
      ];

      const request_json_final = request_json.filter(
        (data: any) => data.new_value !== data.value
      );

      const request_json_with_employee = [
        {
          employee_name: state?.payrollItem?.employee?.name,
          employee_number: state?.payrollItem?.employee?.emp_number,
          company_name: user.defaultCompany,
          payslip_date: state?.payrollItem?.payslip_date
        },
        ...request_json_final,
      ];
      formData.append("company", user.defaultCompnayId);
      formData.append("request_type", "CP");
      formData.append("name", user.name);
      formData.append("email", user.email);
      formData.append("json_data", JSON.stringify(request_json_with_employee));
      formData.append("regularInputDataToChange", JSON.stringify(regularInputDataToChange));
      formData.append("payslipInputDataToChange", JSON.stringify(payslipInputDataToChange));  


      changesObj = request_json_with_employee;
      console.log("final json", request_json_final)
    }

    // console.log("payroll payload", Object.fromEntries(formData.entries()));

    console.log("theChangesObj",selectedType, changesObj)


    let payload = Object.fromEntries(formData.entries())

    console.log('thePayload', payload,  employeeId)
    updatePreviewData(payload, employeeId)

    

    // try {
    //   const { status } = await DashboardService._submitRequest(payload);

    //   if (status === 201) {
    //     Notify("Change Request Submitted", 1);
    //     navigate("/dashboard/tellboss/payroll", { state: { change: "Yes" } });
    //   }
    // } catch (error:any) {
    //   Utils.showErrorMessage(error)
    // }

    setConfirmUpdate(() => false);
    navigate(-1);
  };

  const handleUpdate = () => {
    console.log('here we are')
    sendForChange()
  }

  useEffect(() => {
    if (selectedType === "ES") {
      if (!reasons.length) {
        getReasons();
      }
      setShowEndService(() => true);
    } else {
      setShowEndService(() => false);
    }
  }, [selectedType]);

  useEffect(() => {
    setIsLoading(() => true);
    getPayslipData();
    // getRequestTypes();
  }, []);

  useEffect(() => {
    if(Object.keys(payrollPreviewData).length > 0){
      localStorage.setItem('payrollPreview', JSON.stringify(payrollPreviewData));
    }
}, [payrollPreviewData]);

useEffect(() => {
  const storedData = JSON.parse(localStorage.getItem('payrollPreview') || '{}');
  console.log("useEffect" + 'storedData', storedData)
  if (Object.keys(storedData).length > 0) {
      setPayrollPreviewData(storedData);
  }
}, []);

console.log("payslipInputDataToChange", payslipInputDataToChange);
console.log("regularInputDataToChange", regularInputDataToChange);


  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <div>
          <NavigateBack />
          <H3Heading>
            Change Payslip - {state?.payrollItem?.employee?.name || ""} -{" "}
            {Utils._dateToShow(state?.payrollItem?.payslip_date)}
          </H3Heading>
          <div
            style={{
              display: "flex",
              width: "60%",
              margin: "20px 0",
              gap: 105,
            }}
          >
            <p style={{ marginTop: 17 }}>Request Type</p>
            <CustomSelectField
              title=""
              value={selectedType}
              onChangeOption={(val) => setSelectedType(val)}
              option={Object?.keys(requestType)?.map((key: any) => ({
                value: key,
                key: requestType[key],
              }))}
              require
              width="200px"
            />
          </div>
          {showEndService ? (
            <div>
              <CustomDateField
                label="Last Day of Service"
                date={lastDayOfService}
                setDate={setLastDayOfService}
                require={true}
                labelWidth="13.5%"
              />
              <div
                style={{
                  display: "flex",
                  width: "60%",
                  margin: "20px 0",
                  gap: 30,
                }}
              >
                <p style={{ marginTop: 17, color: "#495057" }}>
                  Reason for termination
                </p>
                <CustomSelectField
                  title=""
                  value={reason}
                  placeholder="Select Reason"
                  option={reasons?.map((item: any) => ({
                    key: item.reason,
                    value: item.id,
                  }))}
                  onChangeOption={setReason}
                  require={true}
                  width="250px"
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <CustomeCkeckboxField
                  title="Requires Form IR21 (exclude from IR8A e-submission file)"
                  value={requireFormIr21}
                  onChange={() => setRequireFormIr21((p) => !p)}
                />
              </div>
            </div>
          ) : (
            <div>
              <IRASTable style={{ width: "60%" }}>
                <TableHeader headerColumns={tableHeaders} variant="filled" />
                <TableRow>
                  <TableColumn style={{ padding: "5px 10px" }}>
                    <h4 style={{ padding: "5px 0" }}>Regular Inputs</h4>
                  </TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                </TableRow>
                {regularInputDataToChange?.map((data: any) => (
                  <TableRow>
                    <TableColumn style={{ padding: "5px 10px" }}>
                      <p>
                        <strong>{data?.name}</strong>
                      </p>
                      <p style={{ marginTop: 10 }}>{data?.field_name}</p>
                    </TableColumn>
                    <TableColumn style={{ padding: "5px 10px" }}>
                      <CustomNumberField
                        value={data?.value}
                        onChangeText={() => {}}
                        disabled
                        marginTop="20px"
                      />
                    </TableColumn>
                    <TableColumn style={{ padding: "5px 10px" }}>
                      <CustomNumberField
                        title=""
                        value={data?.new_value}
                        onChangeText={(value) =>
                          handleRegularInputChange(data.field_code, value)
                        }
                        marginTop="20px"
                      />
                    </TableColumn>
                  </TableRow>
                ))}
                {payslipData?.payslip_calculation_items?.length > 0 && (
                  <TableRow>
                    <TableColumn style={{ padding: "5px 10px" }}>
                      <h4 style={{ padding: "5px 0" }}>Payslip Inputs</h4>
                    </TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                  </TableRow>
                )}

                {payslipInputDataToChange?.map((data: any) => (
                  <TableRow>
                    <TableColumn style={{ padding: "5px 10px" }}>
                      <p>
                        <strong>{data?.name}</strong>
                      </p>
                      <p style={{ marginTop: 10 }}>{data?.field_name}</p>
                    </TableColumn>
                    <TableColumn style={{ padding: "5px 10px" }}>
                      <CustomNumberField
                        title=""
                        value={data?.value}
                        onChangeText={() => {}}
                        disabled
                        marginTop="20px"
                      />
                    </TableColumn>
                    <TableColumn style={{ padding: "5px 10px" }}>
                      <CustomNumberField
                        title=""
                        value={data?.new_value}
                        onChangeText={(value) =>
                          handlePayslipInputChange(data.code, value)
                        }
                        marginTop="20px"
                      />
                    </TableColumn>
                  </TableRow>
                ))}
              </IRASTable>
            </div>
          )}
          <CustomButton
            title="Update"
            onClick={(selectedType === "ES" && (!reason || !lastDayOfService)) ? ()=>Notify(`${!reason ? "Please select a reason" : "Please add last date of service"}`,0) : () => setConfirmUpdate(() => true)}
            styles={{ marginTop: 20 }}
            // disable={selectedType === "ES" && !reason}
          />
        </div>
      )}
      <CommonModal
        handleClose={() => setConfirmUpdate(() => false)}
        open={confirmUpdate}
        updateData={handleUpdate}
        body={
          selectedType === "ES"
            ? `Are you sure you want to send the end service data`
            : `Are you sure you want to change the data?`
        }
        heading={
          selectedType === "ES" ? `End Service Request` : `Change Payslip Data`
        }
        conformButtonText="Confirm"
        cancelButtonText="Cancel"
      />
    </div>
  );
};

export default ChangePayroll;
